import axios from "axios";
import { getUserWallet, awaitTx, initializeLucid, depositTx } from "./lucid";
import { API_URL, API_URL_MAIN } from "../constants";
import { store } from "index";
import { createNotification } from "store/actions/uiActions";

export const withdraw = async (
  requestedAmount,
  poolId,
  stakingWallet,
  isPreProd
) => {
  console.log("stakingWallet", stakingWallet);
  try {
    const feeTransactionHash = await depositWithdrawFee(
      stakingWallet,
      isPreProd
    );
    console.log("feeTransactionHash", feeTransactionHash);

    if (feeTransactionHash) {
      await executeWithdrawRequest(
        requestedAmount,
        poolId,
        feeTransactionHash,
        isPreProd
      );
    }
  } catch (error) {
    console.log("withdraw error", error, error?.message);
  }
};

export const depositWithdrawFee = async (stakingWallet, isPreProd) => {
  try {
    const walletName = store.getState().user.adaWallet;

    const lucid = await initializeLucid(
      await window.cardano[walletName].enable(),
      isPreProd
    );

    const utxos = await lucid.wallet.getUtxos();

    let tx;

    const depositObject = {
      lovelace: 2000000,
    };
    tx = await depositTx(depositObject, stakingWallet, lucid, utxos);

    const signedTx = await tx.sign().complete();

    const txHash = await signedTx.submit();

    await awaitTx(txHash, isPreProd);

    return txHash;
  } catch (error) {
    if (
      error?.info === "user declixned tx" ||
      error?.info === "user declined to sign tx"
    ) {
      store.dispatch(
        createNotification("error", `You need to sign the message`, 6000)
      );
      return;
    }
    store.dispatch(createNotification("error", JSON.stringify(error), 6000));
  }
};

export const depositAdaAmount = async (stakingWallet, amount, isPreProd) => {
  try {
    const walletName = store.getState().user.adaWallet;

    const lucid = await initializeLucid(
      await window.cardano[walletName].enable(),
      isPreProd
    );

    const utxos = await lucid.wallet.getUtxos();

    let tx;

    const depositObject = {
      lovelace: amount * 1000000,
    };
    tx = await depositTx(depositObject, stakingWallet, lucid, utxos);

    const signedTx = await tx.sign().complete();

    const txHash = await signedTx.submit();

    await awaitTx(txHash, isPreProd);

    store.dispatch(
      createNotification(
        "success",
        `Your deposit was successful! It will be shortly updated in the UI`,
        3000
      )
    );

    return txHash;
  } catch (error) {
    if (
      error?.info === "user declixned tx" ||
      error?.info === "user declined to sign tx"
    ) {
      store.dispatch(
        createNotification("error", `You need to sign the message`, 6000)
      );
      return;
    }
    store.dispatch(createNotification("error", JSON.stringify(error), 6000));
  }
};

const executeWithdrawRequest = async (
  requestedAmount,
  poolId,
  feeTransactionHash,
  isPreProd
) => {
  try {
    const userWallet = await getUserWallet(isPreProd);
    console.log("executeWithdrawRequest");
    await axios.post(
      `${isPreProd ? API_URL : API_URL_MAIN}pool/withdraw/${poolId}`,
      {
        userWallet,
        feeTransactionHash,
        requestedAmount,
      }
    );

    console.log("executeWithdrawRequest2222");

    store.dispatch(
      createNotification(
        "success",
        "Your withdraw will be executed shortly",
        4000
      )
    );
  } catch (error) {
    if (
      error?.response?.data?.message ===
      "Requested withdraw amount is higher than available amount"
    ) {
      store.dispatch(
        createNotification(
          "error",
          `You requested more tokens than you staked`,
          4000
        )
      );
    }
    store.dispatch(createNotification("error", JSON.stringify(error), 4000));
  }
};
