/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { updateNotifications } from "../../store/actions/uiActions";
import closeIcon from "../../assets/close-icon.svg";

const Notifications = ({
  notificationsList,
  position,
  updateNotifications,
  currentTx,
}) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (notificationsList.length > 0) {
        deleteToast(notificationsList[0].id);
      }
    }, notificationsList[0]?.deleteNotificationInMs);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsList]);

  const deleteToast = (id) => {
    const updatedOfNotificationsList = notificationsList.filter(
      (notification) => notification.id !== id
    );

    updateNotifications(updatedOfNotificationsList);
  };

  return (
    <>
      <div className={`notification-container ${position}`}>
        {notificationsList.map((toast, i) => (
          <div
            key={i}
            className={`notification ${toast.type} toast ${position}`}
            onClick={() => deleteToast(toast.id)}
          >
            <div>
              <p className="notification-title">{toast.title}</p>
              <p className="notification-message">{toast.description}</p>
              {currentTx && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={currentTx}
                  className="notification-message"
                >
                  tx (can take multiple seconds until tx appears)
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationsList: state.ui.notificationsList,
    currentTx: state.ui.currentTx,
  };
};

export default connect(mapStateToProps, { updateNotifications })(Notifications);
