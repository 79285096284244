import React from "react";
import { Column, Row, Text, Button } from "../../index";
import closeIcon from "../../../assets/notifications/x.png";
import { connect } from "react-redux";
import Loader from "react-js-loader";

const UnstakingModal = ({
  closeModal,
  claimOrUnstake,
  claim,
  unstake,
  loading,
}) => {
  const isClaimRewardsEnabled = claimOrUnstake === "claim";
  return (
    <Column className="unstaking-modal">
      <Row className="unstaking-modal__header">
        <Text
          text={isClaimRewardsEnabled ? "Claim rewards" : "Unstake tokens"}
          className="unstaking-modal__title"
        />
        <img
          alt="Close icon"
          onClick={closeModal}
          className="fas fa-times unstaking-modal__close"
          src={closeIcon}
        />
      </Row>
      <Text
        className="unstaking-modal__text"
        text={
          isClaimRewardsEnabled
            ? "Do you want to claim your rewards?"
            : "Do you want to unstake all tokens?"
        }
      />
      {loading ? (
        <div style={{ width: "30%" }}>
          <Loader
            type="spinner-default"
            title=""
            bgColor={"#d02315"}
            size={50}
          />
        </div>
      ) : (
        <Row className="unstaking-modal__buttons">
          <Button text="Cancel" onClick={closeModal} />
          <Button
            text={isClaimRewardsEnabled ? "Claim" : "Unstake"}
            onClick={isClaimRewardsEnabled ? () => claim() : () => unstake()}
            className="red-button"
          />
        </Row>
      )}
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ui.loading,
  };
};

export default connect(mapStateToProps)(UnstakingModal);
