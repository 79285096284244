import axios from "axios";
import { getLucid } from "./lucid";
import {
  API_URL,
  API_URL_MAIN,
  BF_TEST_API_KEY,
  BF_MAIN_API_KEY,
  BLOCKFROST_URL_MAIN,
  BLOCKFROST_URL_TEST,
} from "../constants";
import {
  setUsersToken,
  setNftAssets,
  setUserCredit,
  setUserClaimedRewards
} from "store/actions/userActions";
import { store } from "index";
import { nftPools } from "../pools";
import { nftPoolsBeta } from "../pools-beta";
import { NETWORK } from "../constants";

// const tokenAddresses = {};

// if (NETWORK == 'cardano') {
//   nftPools.forEach((pool) => {
//     if (pool.type === "token") {
//       tokenAddresses[pool.name] = pool.stakingUnit;
//     }
//   });
// } else {
//   nftPoolsBeta.forEach((pool) => {
//     if (pool.type === "token") {
//       tokenAddresses[pool.name] = pool.stakingUnit;
//     }
//   });
// }

// tokenAddresses.adaName =  "lovelace";

export const addWallets = async (userWallet, poolsData, isPreProd) => {
  try {
    const lucid = await getLucid(isPreProd);
    const utxos = await lucid.wallet.getUtxos();
    const mainWallet = await lucid.wallet.address();
    const stakeKey = await lucid.wallet.rewardAddress();

    const balances = {};

    utxos.forEach((u) => {
      Object.keys(u.assets).forEach((asset) => {
        if (balances[asset]) {
          balances[asset] += u.assets[asset];
        } else {
          balances[asset] = u.assets[asset];
        }
      });
    });

    const usersTotalAmounts = {};

    poolsData.forEach((pool) => {
      const tokenName = pool.name.toUpperCase();
      const tokenAddress = pool.stakingUnit;
      usersTotalAmounts[tokenAddress] = balances[tokenAddress] || 0;
      if (tokenName == "TANG")
        usersTotalAmounts["TANG"] = balances[tokenAddress] || 0;
    });

    usersTotalAmounts["ADA"] = balances["lovelace"] / 1000000n;

    fetchUserCredit(isPreProd, mainWallet);
    fetchUserClaimedRewards(isPreProd, mainWallet);

    store.dispatch(setUsersToken(usersTotalAmounts, balances));

    await axios.post(
      `${isPreProd ? API_URL : API_URL_MAIN}pool/addUserWallet`,
      {
        mainWallet,
        stakeKey,
      }
    );
  } catch (error) {
    console.log(error, error?.message);
  }
};

export const fetchUserCredit = async (isPreProd, userWallet) => {
 try {
   var res = await axios.post(
     `${isPreProd ? API_URL : API_URL_MAIN}pool/get-user-credit`,
     {
       userWallet,
     }
   );
 
   store.dispatch(setUserCredit(res.data.credit));
 } catch (error) {
  
 }
};

export const fetchUserClaimedRewards = async (isPreProd, userWallet) => {
 try {
   var res = await axios.post(
     `${isPreProd ? API_URL : API_URL_MAIN}pool/getUserClaimedRewards`,
     {
       userWallet,
     }
   );
 
   store.dispatch(setUserClaimedRewards(res.data.rewardsUnits));
 } catch (error) {
  
 }
};

export const getWalletAssets = async (
  walletAddress,
  setNftsData,
  isPrepord
) => {
  try {
    const response = await axios.get(
      `${
        isPrepord ? BLOCKFROST_URL_TEST : BLOCKFROST_URL_MAIN
      }/addresses/${walletAddress}/utxos`,
      {
        headers: {
          project_id: isPrepord ? BF_TEST_API_KEY : BF_MAIN_API_KEY,
        },
      }
    );

    const utxos = response.data;

    const nftAssets = [];
    for (const utxo of utxos) {
      for (const asset of utxo.amount) {
        // asset.quantity === '1' &&
        if (asset.unit !== "lovelace") {
          const assetResponse = await axios.get(
            `${isPrepord ? BLOCKFROST_URL_TEST : BLOCKFROST_URL_MAIN}/assets/${
              asset.unit
            }`,
            {
              headers: {
                project_id: isPrepord ? BF_TEST_API_KEY : BF_MAIN_API_KEY,
              },
            }
          );
          console.log("here", assetResponse.data);
          const nftName = assetResponse.data.name;

          if (
            assetResponse.data.onchain_metadata &&
            assetResponse.data.onchain_metadata.image
          ) {
            //assetResponse.data.onchain_metadata.collection){
            nftAssets.push({
              policy_id: assetResponse.data.policy_id,
              fingerprint: assetResponse.data.fingerprint,
              name: assetResponse.data.onchain_metadata.character,
              collection:
                assetResponse.data.onchain_metadata.collection ||
                assetResponse.data.onchain_metadata.ticker,
              edition:
                assetResponse.data.onchain_metadata.edition ||
                assetResponse.data.onchain_metadata.description,
              unit: asset.unit,
              quantity: asset.quantity,
              txHash: utxo.tx_hash,
              tokenName: assetResponse.data.onchain_metadata.name,
              outputIndex: utxo.output_index,
              image: assetResponse.data.onchain_metadata.image.replace(
                "ipfs://",
                "https://ipfs.io/ipfs/"
              ),
            });
          }
        }
      }
    }

    setNftsData(nftAssets.reverse());
    store.dispatch(setNftAssets(nftAssets.reverse()));

    // return nftAssets;

    console.log("NFT assets:", nftAssets);

    // Handle and display the wallet assets in your ReactJS application
  } catch (error) {
    console.error("Error retrieving wallet assets:", error);
  }
};
