/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { createBrowserHistory } from "history";
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Row,
  Column,
  Input,
  StakingModal,
  TransactionsModal,
  InfoSection,
  Header,
  UnstakingModal,
  H1,
} from "../../components";

import {
  createNotification,
  startAction,
  stopAction,
} from "store/actions/uiActions";
import { v4 as uuidv4 } from "uuid";
import {
  setPoolsStakedTokens,
  setUsersRewards,
  setUsersStakedTokens,
} from "store/actions/poolActions";
import LoadLogo from "assets/tang_logo_animated.svg";
import cardSelected from "../../assets/card_selected.svg"
import listUnselected from "../../assets/list_unselected.svg"
import cardUnselected from "../../assets/card_unselected.svg"
import listSelected from "../../assets/list_selected.svg"
import Loader from "react-js-loader";
import { mockNftInfo } from "../../utils/mockInfo";
import NftItem from "./components/NftItem";
import { NftTopics } from "./components";
import { connect } from "react-redux";
import { useWindowSize } from "utils/windowSizeHook";
import searchIcon from "../../assets/search.svg";
import { Modal } from "../../components/Modals/Modal/Modal";
import { getPools, getNftPools } from "utils/ada/getPoolData";
import { addWallets, getWalletAssets } from 'utils/ada/addWallets';
import NftCardItem from "./components/NftCardItem";
import { evaluate } from 'mathjs';
import gridSelected from "../../assets/3grid_selected.svg"
import gridUnselected from "../../assets/3grid_unselected.svg"


const Farms = ({ adaWallet,
  tangentAmount,
  setUsersStakedTokens,
  setUsersRewards,
  stakedTokens,
  setPoolsStakedTokens,
  tangPrice,
  ethPrice,
  signerAddress,
  createNotification,
  nftAssets,
  assetsIds,
  poolsData,
  network }) => {

  const windowSize = useWindowSize();

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState();
  const [modalEarn, setModalEarn] = useState();
  const [tokenInfo, setTokenInfo] = useState();
  const [allTokenInfo, setAllTokenInfo] = useState();
  const [modalStakingUnit, setModalStakingUnit] = useState();
  const [modalStakingWallet, setModalStakingWallet] = useState();
  const [modalDistributionWallet, setModalDistributionWallet] = useState();
  const [stakingPoolId, setStakingPoolId] = useState();
  const [amountOfStakedTokens, setAmountOfStakedTokens] = useState(1);
  const [setIsLoading, setSetIsLoading] = useState(false);
  const [isCardViewMode, setIsCardViewMode] = useState(true);
  const [tokenDecimal, setTokenDecimal] = useState();

  // const [open, setOpen] = useState(false);
  // const [modalType, setModalType] = useState("");
  // const [modalTitle, setModalTitle] = useState("");
  // const [amountOfStakedTokens, setAmountOfStakedTokens] = useState("");
  const [modalProps, setModalProps] = useState([]);
  const [currentLpNftFarmContractName, setCurrentLpNftFarmContractName] = useState("LpUniswapTokenFarmContract");
  const [nftInfo, setNftInfo] = useState([]);
  const [viewType, setViewType] = useState('card');
  // const [isLoading, setIsLoading] = useState(false);

  const [nftsData, setNftsData] = useState(null);

  const onOpenModal = (
    name,
    title,
    earn,
    stakingUnit,
    stakingWallet,
    stakedTokens,
    poolId,
    distributionWallet,
    setIsLoading,
    decimal
  ) => {
    setModalType(name);
    setOpen(true);
    setModalTitle(title);
    setModalEarn(earn);
    setSetIsLoading(() => setIsLoading);
    setModalStakingUnit(stakingUnit);
    setModalStakingWallet(stakingWallet);
    setAmountOfStakedTokens(stakedTokens);
    setStakingPoolId(poolId);
    setModalDistributionWallet(distributionWallet)
    setTokenDecimal(decimal);

    // "staking",
    // title,
    // earn,
    // stakingUnit,
    // stakingBalanceName,
    // rewardsBalanceName
  };

  const handleViewChange = (newViewType) => {
    setIsCardViewMode(newViewType == "card")
    setViewType(newViewType);
  };

  const navigateToCreatePool = () => {
    // history.push('/create-pool');
    createNotification(
      "success",
      "Pool Creation Page Will Be Ready Soon ",
      3000
    );
  };


  useEffect(() => {
    if (adaWallet) {
      setUsersRewards(network == "pre");
      setUsersStakedTokens(network == "pre");
      addWallets(adaWallet, poolsData, network == "pre");
    }
    const interval = setInterval(() => {
      if (adaWallet) {
        setUsersRewards(network == "pre");
        setUsersStakedTokens(network == "pre");
        addWallets(adaWallet, poolsData, network == "pre");
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [adaWallet, setUsersStakedTokens, setUsersRewards]);


  useEffect(() => {
    setPoolsStakedTokens(network == "pre")
    const interval = setInterval(() => {
      setPoolsStakedTokens(network == "pre");
    }, 10000);
    return () => clearInterval(interval);
  }, [setPoolsStakedTokens]);

  useEffect(() => {
    getNftPools(assetsIds, setAllTokenInfo, setNftInfo, network == "pre")
    // setAllTokenInfo(mockNftInfo)
    // setNftInfo(mockNftInfo)
    console.log(mockNftInfo);
  }, []);

  const onCloseModal = () => {
    setOpen(false);
  };

  const onSearchItem = (val) => {
    const result = allTokenInfo.filter((item) =>
      item && item.title && item.title.toLowerCase().includes(val.toLowerCase()) ||
      item && item.name && item.name.toLowerCase().includes(val.toLowerCase())
    );
    setNftInfo(result);
  };



  const refresh = async () => {
    setIsLoading(true)
    try {
      if (signerAddress) {
        setUsersRewards();
        setUsersStakedTokens();
        setPoolsStakedTokens();
        addWallets(adaWallet);
        getWalletAssets(signerAddress, setNftsData, network == "pre");
        getNftPools(Object.keys(assetsIds), setAllTokenInfo, setNftInfo)
      }
    } catch (error) {

    } finally {
      setTimeout(async () => setIsLoading(false), 10000);

    }

  }

  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseModal}
        modalType={modalType}
        modalTitle={modalTitle}
        modalEarn={modalEarn}
        modalStakingUnit={modalStakingUnit}
        modalStakingWallet={modalStakingWallet}
        modalDistributionWallet={modalDistributionWallet}
        amountOfStakedTokens={amountOfStakedTokens}
        poolId={stakingPoolId}
        isLoading={setIsLoading}
        tokenDecimal={tokenDecimal}
      />
            {/* <div className="fab" 
            onClick={navigateToCreatePool}
            >

<i className="fa fa-plus"></i>
Create Pool
</div> */}
      <div className="farms">
        <Row className="farms__menu">
          <Column className="farms__menu-left" style={{width: viewType === "3grid" || windowSize[0] < 600 ? "100%" : "70%"}}>
            {/* {windowSize[0] < 900 ? ( */}
            <Row className="farms__menu-left__search-collectible">
              <H1
                text="NFT Staking"
                className="farms__menu-left__search-collectible__title"
              />

            </Row>
            {/* ) : null} */}

            {windowSize[0] < 900 ? (
              <Button
                className="detail-button"
                style={{
                  background: "rgba(255, 255, 255, 0.0001)",
                  border: "1px solid rgba(255, 255, 255, 0.4)",
                }}
                onClick={() => onOpenModal("info")}
                text={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    Details
                  </div>
                }
              />
            ) : null}
            {/* <div className="oval"></div> */}
            <Row style={{ position: "relative", justifyContent: "start" }}>
              <input
                placeholder="Search"
                className="search"
                type="text"
                onChange={(evt) => onSearchItem(evt.target.value)}
              />
              {/* {windowSize[0] > 900 ? (

                // <img
                //   onClick={() => onSearchItem()}
                //   className="search__icon"
                //   src={searchIcon}
                //   alt="Search"
                // />
              ) : null} */}

              {windowSize[0] > 900 ? (

                  <Row>
                  <img
                    onClick={() => handleViewChange('table')}
                    className="view__icon"
                    style={{ cursor: 'pointer', width: 40 }}
                    src={viewType == "table" ?  listSelected: listUnselected}
                    alt="Search"
                  />
                  <img
                    onClick={() => handleViewChange('card')}
                    className="view__icon2"
                    style={{ cursor: 'pointer', width: 40 }}
                    src={viewType == "card" ? cardSelected : cardUnselected}
                    alt="Search"
                  />
                  <img
                    onClick={() => handleViewChange('3grid')}
                    className="view__icon3"
                    style={{ cursor: 'pointer', width: 40 }}
                    src={viewType == "3grid" ? gridSelected : gridUnselected}
                    alt="Search"
                  />
                  </Row>
              ) : null}

            </Row>
            {/* {windowSize[0] < 900 ? null : <NftTopics />} */}
            <Row style={{ paddingBottom: "1rem" }}>
              {/* <p style={{ color: "#a3a8ac", fontSize: "12px" }}>
                  Stake your operative artwork NFTs to earn rewards
                </p> */}
              {/* {isLoading ? (
                  <Loader
                    type="spinner-default"
                    title=""
                    bgColor={"#d02315"}
                    size={50}
                  />
                ) :
                  <Button
                    onClick={refresh}
                    text="Refresh"
                    className="claim-button"
                    style={{
                      fontSize: "15px",
                      width: "100px",
                      height: "35px",
                    }}
                  />} */}
            </Row>
           

              {nftInfo.length == 0 ?
                <Box className="box-big" style={{ backgroundColor: "transparent", marginTop: 30, textAlign: "center" }}>
                  <img className="loadLogo" src={LoadLogo} alt="Loading" style={{    display: "inline"}} />

                </Box>
                : (
                  <>
                    {viewType === 'table' && (
                      <>
                        {windowSize[0] < 900 ? null : <NftTopics style={{paddingBottom: 20}}/>}

                        <Box className="box-big">

                          {nftInfo.map((item, index) => (

                            <NftItem
                              icon={item.icon}
                              poolId={item.id}
                              stakingWallet={item.stakingWallet}
                              stakingUnit={item.stakingUnit}
                              openModal={onOpenModal}
                              distributionWallet={item.distributionWallet}
                              earn={item.reward}
                              key={item.id}
                              APY={item.APY}
                              // APY={evaluate(item.apyFormula, { tokens_staked: item.staked })}
                              tokenContract={item.tokenContract}
                              decimal={item.stakingUnitDecimals}
                              poolRewards={item.rewards}
                              getToken={item.getToken}
                              // APY={0}
                              title={item.name}
                              farm={item.farm}
                              apyBalanceName={item.apyBalanceName}
                              farmContractName={item.farmContractName}
                              tokenContractName={item.tokenContractName}
                              stakingBalanceName={item.stakingBalanceName}
                              rewardsBalanceName={item.rewardsBalanceName}
                              userTokenBalanceName={item.userTokenBalanceName}
                              minutesUntilPoolIsClosing={item.minutesUntilPoolIsClosing}
                              closesAt={item.closesAt}
                              windowSize={windowSize}
                              contractAddress={item.contractAddress}
                              id={item.id}
                              edition={item.edition}
                              rightHash={item.rightHash}
                              hash={item.hash}
                              image={item.image}
                              rewards={item.rewards}
                              nftAssets={item.userNfts}
                              balanceName={item.id} // id is the right NFT id
                              currentLpNftFarmContractName={currentLpNftFarmContractName}
                              style={{
                                ...(mockNftInfo.length - 1 === index && {
                                  borderBottom: "none",
                                }),
                              }}
                            />
                          ))}
                        </Box>
                      </>
                    )}

                    {['card', '3grid'].includes(viewType) && (
                      <>
                        <div className={`pool-container${viewType == "3grid" ? "-3" : ""}`}  style={  windowSize[0] < 600 ? {width: "100%", display: "block"} : null}>
                          {nftInfo?.map((item, index) => (
                            <NftCardItem
                              key={item.id}
                              image={item.icon}
                              title={item.name}
                              poolId={item.id}
                              closesAt={item.closesAt}
                              stakingWallet={item.stakingWallet}
                              stakingUnit={item.stakingUnit}
                              openModal={onOpenModal}
                              distributionWallet={item.distributionWallet}
                              earn={item.reward}
                              poolRewards={item.rewards}
                              rewards={item.rewards}
                              APY={item.APY}
                              // APY={evaluate(item.apyFormula, { tokens_staked: item.staked })}
                              tokenContract={item.tokenContract}
                              getToken={item.getToken}
                              stakedTokens={item.staked}
                              farm={item.farm}
                              decimal={0}
                              apyBalanceName={item.apyBalanceName}
                            />
                          ))}
                        </div>
                      </>
                    )}

                  </>
                )

              }
          </Column>

          {windowSize[0] < 900 || viewType === '3grid' ? null : <InfoSection />}
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    signerAddress: state.user.signerAddress,
    adaWallet: state.user.adaWallet,
    stakedTokens: state.pool.stakedTokens,
    nftAssets: state.user.nftAssets,
    tangPrice: state.user.tangPrice,
    ethPrice: state.user.ethPrice,
    assetsIds: state.user.assetsIds,
    network: state.network.network,
    poolsData: state.pool.poolsData,
  };
};

export default connect(mapStateToProps, {
  createNotification,
  stopAction,
  startAction,
  setPoolsStakedTokens,
  setUsersRewards,
  setUsersStakedTokens,
})(Farms);
