import React from "react";
import { Row, Text } from "../../../components";

const OrderTopics = () => {
  return (
    <Row className="liqudity-topic">
      <div className="liqudity-topic__big-space">
        <Text
          text="Asset"
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="liqudity-topic__small-space"     style={{ width: '20%'}}>
        <Text
          text="Action"
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="liqudity-topic__small-space" style={{ width: '20%'}}>
        <Text
          text="Amount"
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="liqudity-topic__small-space" style={{ width: '15%'}}>
        <Text
          text="Status"
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="liqudity-topic__small-space" style={{width: "10%"}}>
        <Text
          text=""
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
    </Row>
  );
};

export default OrderTopics;
