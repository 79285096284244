import React, { useState, useEffect }  from "react";
import { Row, Column, Button, Text, CountUpNumber } from "../../../components";
import { takeRewards } from "utils/ada/takeRewards";
import Loader from "react-js-loader";
import moment from 'moment';
import exportIcon from "../../../assets/export.png"


import { connect } from "react-redux";

const ExpandedNftItem = (props) => {
  let {
    rewards,
    stake,
    claim,
    unstake,
    edition,
    name,
    isMobileViewEnabled,
    earn,
    stakedTokens,
    staked,
    poolId,
    closesAt,
    minutesUntilPoolIsClosing,
    createNotification,
    setUsersRewards,
    distributionWallet,
    network,
    tokenContract, 
    getToken,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [endTime, setEndTime] = useState();
  const [stakeIsLoading, setStakeIsLoading] = useState(false);
  const [unstakeIsLoading, setUnstakeIsLoading] = useState(false);

  const totalStakedTokensOfPool = stakedTokens ? stakedTokens[poolId] : 0;
  console.log(totalStakedTokensOfPool)

  const userShareOfPool =totalStakedTokensOfPool > 0 ?  (staked * 100) / totalStakedTokensOfPool : 0;
console.log(userShareOfPool)
  const claiming = async () => {
    // createNotification("error", "NFT farm will be open soon", 4000)
    // return;
    // return;
    let hasRewardsToClaim = false;
    rewards?.map((reward) => {
      for (var key in reward) {
        if (reward[key] > 0) {
          hasRewardsToClaim = true;
        }
      }
      return reward;
    });
    if (!hasRewardsToClaim) {
      createNotification(
        "error",
        "You do not have any rewards in this pool",
        4000
      );
      return;
    }
    try {
      setIsLoading(true);
      await takeRewards(poolId, distributionWallet, network == "pre");
      setTimeout(async () => setUsersRewards(), 1000);
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    var endTime = closesAt;
    // setEndTime(moment().add('minutes', minutesUntilPoolIsClosing).format('YYYY-MM-DD mm:ss'));
    setEndTime(moment(closesAt).format('YYYY-MM-DD'));
  }, []);

  return (
    <Column className="expanded-liquidity-item">
      <Column className="expanded-liquidity-item__section1">
        <Column className="expanded-nft-item__info-area">
          <Row className="expanded-liquidity-item__rowSpace">
            <Text
              style={{
                color: "#a3a8ac",
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
            >
              Policy ID
            </Text>
            <div className="dashline"></div>
            <Row
              className="expanded-liquidity-item__poolText"
              style={{ fontSize: "12px" }}
            >
              <Column>
              {isMobileViewEnabled ? (
              
                <Text style={{ width: '10%' }}>{`f4de5c68c6cab541f24eb245d...`}</Text>
              ): (
                <Text style={{ width: '10%' }}>{`f4de5c68c6cab541f24eb245dc067d56e8999ffcd1ebe2cdd328d8a5`}</Text>

              )}
                {/* <Text style={{ width: 240 }}>{` ${name} - ${edition}`}</Text> */}
              </Column>
            </Row>
          </Row>
          <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "20px",
              whiteSpace: "nowrap",
            }}
          >
            Total Staked 
          </Text>
          <div className="dashline" ></div>
          <Row>
            <Row style={{ marginLeft: 5, marginRight: -13}} className="expanded-liquidity-item__first-rewardText">
             
              <Text
                className="expanded-liquidity-item__rewardText__unitText"
                style={{ fontSize: "13px", width: 40  }}
              >
         {totalStakedTokensOfPool} NFT
              </Text>
            </Row>
          </Row>
        </Row>

          {isMobileViewEnabled ? (
          <Row className="expanded-liquidity-item__rowSpace">
          <Text
              style={{
                color: "#a3a8ac",
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
            >
                Rewards
              </Text>
              <div className="dashline"></div>
              <Row>
                {rewards.map((item, index) => {
                 
                    return (
                      <Row
                        className={`${
                          index === 0
                            ? "expanded-liquidity-item__first-rewardText"
                            : "expanded-liquidity-item__rewardText"
                        }`}
                      >
                        <Text>{item.amount} </Text>
                        <Text
                          className="expanded-liquidity-item__rewardText__unitText"
                          style={{ fontSize: "13px", marginLeft: 5, marginRight: -10 }}
                        >
                                   {item.rewardUnit.name}
                        </Text>
                      </Row>
                    );
                  
                })}
              </Row>
            </Row>
          ) : (
            <Row className="expanded-liquidity-item__rowSpace">
              <Text style={{ color: "#a3a8ac", marginRight: "20px" }}>
                Rewards
              </Text>
              <div className="dashline"></div>
              <Row>
                {rewards.map((item, index) => {
                 
                    return (
                      <Row
                        className={`${
                          index === 0
                            ? "expanded-liquidity-item__first-rewardText"
                            : "expanded-liquidity-item__rewardText"
                        }`}
                        Key={index}
                      >
                        <Text>{item.amount} </Text>
                        <Text
                          className="expanded-liquidity-item__rewardText__unitText"
                          style={{ fontSize: "13px", marginLeft: 5, marginRight: -10}}
                        >
                         {item.rewardUnit.name}
                        </Text>
                      </Row>
                    );
                })}
              </Row>
            </Row>
          )}
          <Row className="expanded-liquidity-item__rowSpace">
            <Text
              style={{
                color: "#a3a8ac",
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
            >
              Pool Ownership 
            </Text>
            <div className="dashline"></div>
            <Row
              className="expanded-liquidity-item__poolText"
              style={{ fontSize: "13px" }}
            >
              <Column className="expanded-liquidity-item__percentage-container">
                <CountUpNumber
                  className="expanded-liquidity-item__percentage"
                  startValue={0}
                  end={userShareOfPool}
                  suffix={"%"}
                  decimals={2}
                />
                {!isMobileViewEnabled ? (
                  <div className="expanded-liquidity-item__percentage__bubble">
                    Pool Ownership 
                  </div>
                ) : null}
              </Column>
            </Row>
          </Row>

          <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "10px",
              whiteSpace: "nowrap",
            }}
          >
            Pool Fee
          </Text>
          <div className="dashline"></div>
          <Row
            className="expanded-liquidity-item__poolText"
            style={{ fontSize: "13px" }}
          >
            <Column className="expanded-liquidity-item__percentage-container">
              <CountUpNumber
                className="expanded-liquidity-item__percentage"
                startValue={0}
                end={1.0}
                suffix={"%"}
                decimals={2}
              />
              {!isMobileViewEnabled ? (
                <div className="expanded-liquidity-item__percentage__bubble">
                  Pool Fee
                </div>
              ) : null}
            </Column>
          </Row>
        </Row>

          <Row className="expanded-liquidity-item__rowSpace">
            <Text
              style={{
                color: "#a3a8ac",
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
            >
              Pool End Date
            </Text>
            <div className="dashline" style={{width: '75%'}}></div>
            {!isMobileViewEnabled ? (
                    <Row
                    className="expanded-liquidity-item__poolText"
                    style={{ fontSize: "13px", width: 100 }}
                  >
                    <Column>
                      <Text>{endTime}</Text>
                    </Column>
                  </Row>
              ) : 
              
              <Row
              className="expanded-liquidity-item__poolText"
              style={{ fontSize: "13px", width: 200 }}
            >
              <Column>
                <Text>{endTime}</Text>
              </Column>
            </Row>
              }

          </Row>
        </Column>


      </Column>
      <Column>
        {!isMobileViewEnabled ? (
          <Column className="expanded-liquidity-item__buttonGroup">
            <Row style={{ justifyContent: "space-between" }}>
            <Row>
              {stakeIsLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <Button
                // href="#"
                onClick={async () => {
                if (minutesUntilPoolIsClosing === 0) {
                  createNotification("error", "Pool is already closed", 4000);
                } else {
                  createNotification("error", "Staking Will Be available soon", 4000);                  
                  return
                  await stake(setStakeIsLoading);
                }
              }}
                className="stake-button">
                Stake
               </Button>
              )}
                 {unstakeIsLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <Button
                  // href="#"
                  onClick={ () => {
                    
                    createNotification("error", "Unstaking Will Be available soon", 4000);                  
                    // unstake(setUnstakeIsLoading)
                  }}
                  className="stake-button"
                >
                  Unstake
                  </Button>
                  
              )}
               
              </Row>
              
              {isLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) :
              <Button
                onClick={ () => {
                  createNotification("error", "Claim Will Be available soon", 4000)             
                  return;
                  claiming();
                }
                }
                text="Claim"
                className="claim-button"
                style={{
                  fontSize: "15px",
                  width: "100px",
                  height: "35px",
                }}
              />}
            </Row>
            <Row style={{ justifyContent: "space-between", marginTop: "2rem" }}>
              <Row>
                <a
                  className="expanded-liquidity-item__link"
                  href="https://cardanoscan.io/address/0164745d3fd727c76a3b21106a9952d537c28186d8d5317e3059aba42e32b42f4335fa5b82e921d4b0e16dc9f0041d1f69e3d4c35651045827"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Farm Contract
                </a>
                <img
                      src={exportIcon}
                      alt="NFT"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px"
                      }}/>
                <a
                  className="expanded-liquidity-item__link"
                  href="https://www.jpg.store/collection/cardanaires "
                  target="_blank"
                  rel="noreferrer"
                >
                  Buy NFT
                </a>
                <img
                      src={exportIcon}
                      alt="NFT"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px"
                      }}/>
              </Row>
              <Row>
              <a
            className="expanded-liquidity-item__link"
            href="https://pool.pm/policy/f4de5c68c6cab541f24eb245dc067d56e8999ffcd1ebe2cdd328d8a5"
            target="_blank"
            rel="noreferrer"
          >
            View Policy ID 
          </a>
          <img
                      src={exportIcon}
                      alt="NFT"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px"
                      }}/>
                <a
            className="expanded-liquidity-item__link"
            href="https://cardanaires.com"
            style={{marginRight: 10}}
            target="_blank"
            rel="noreferrer"
          >
            Publisher Website
          </a>
          <img
                      src={exportIcon}
                      alt="NFT"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px"
                      }}/>
              </Row>
            </Row>
          </Column>
        ) : (
          <Column style={{ marginTop: "1rem" }}>
           <Row className="expanded-liquidity-item__staking">
            {stakeIsLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <a
                // href="/"
                onClick={async () => {
                  if (minutesUntilPoolIsClosing === 0) {
                    createNotification("error", "Pool is already closed", 4000);
                  } else {
                    await stake(setStakeIsLoading);
                  }
                }}
                className="expanded-liquidity-item__staking__buttons__a"
              >
                Stake
              </a>
              )}
            
            </Row>

            <Row className="expanded-liquidity-item__staking">
            {unstakeIsLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <a
                // href="/"
                onClick={ () => {unstake(setUnstakeIsLoading)}}
                className="expanded-liquidity-item__staking__buttons__a"
              >
                Unstake
              </a>
              )}
           
            </Row>
            {isLoading ? (
              <Loader
                type="spinner-default"
                title=""
                bgColor={"#d02315"}
                size={50}
              />
            ) : (
              <Button
                onClick={claiming}
                text="Claim"
                className="claim-button"
                style={{
                  fontSize: "15px !important",
                  width: "100%",            
                  marginTop:'1rem'
                }}
              />
            )}
          </Column>
        )}
        </Column>
      {isMobileViewEnabled ? (
        <Column>
          <a
            className="expanded-liquidity-item__link"
            href={tokenContract}
            target="_blank"
            rel="noreferrer"
          >
            View Farm Contract
          </a>
          <a
            className="expanded-liquidity-item__link"
            href={getToken}
            target="_blank"
            rel="noreferrer"
          >
            Buy NFT
          </a>
          <a
            className="expanded-liquidity-item__link"
            href="https://pool.pm/policy/f4de5c68c6cab541f24eb245dc067d56e8999ffcd1ebe2cdd328d8a5"
            target="_blank"
            rel="noreferrer"
          >
            View Policy ID 
          </a>
          <a
            className="expanded-liquidity-item__link"
            href="https://cardanaires.com"
            target="_blank"
            rel="noreferrer"
          >
            Publisher Website
          </a>
        </Column>
      ) : null}
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
    network: state.network.network,
  };
};

export default connect(mapStateToProps)(ExpandedNftItem);
