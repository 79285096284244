
export const priceOfOneTangentTokenInUsd = 0.00210374;

export const adaSaleAddress =
  "addr_test1qzyanem94tz8dgmpjdtuxx6eurulvenn9cx8xke52ajvs3g8zs9mvd8mchdhh7353epzp6rceahmq9mx4u2k2k9ps9esgumqv7";


// *** New constants ***
// Secrets
export const BLOCKFROST_API_KEY =
  process.env.REACT_APP_IS_TESTNET === "true"
    ? "preprodq78pAcjYnXq4e71HzOrla8ulVf6rkE4K"
    : "mainnetzlLTysAcRwAtzsmzuIPUTAmR8LRGNDzG";
// Network
export const BLOCKFROST_NETWORK =
  process.env.REACT_APP_IS_TESTNET === "true" ? "Preprod" : "Mainnet";

export const BF_TEST_API_KEY = "preprodq78pAcjYnXq4e71HzOrla8ulVf6rkE4K";
export const  BF_MAIN_API_KEY =  "mainnetzlLTysAcRwAtzsmzuIPUTAmR8LRGNDzG";


// Urls
export const BLOCKFROST_URL =
  process.env.REACT_APP_IS_TESTNET === "true"
    ? "https://cardano-preprod.blockfrost.io/api/v0"
    : "https://cardano-mainnet.blockfrost.io/api/v0";
// Urls

export const BLOCKFROST_URL_MAIN = "https://cardano-mainnet.blockfrost.io/api/v0";
export const BLOCKFROST_URL_TEST ="https://cardano-preprod.blockfrost.io/api/v0";


export const API_URL = process.env.REACT_APP_API_URL;

export const API_URL_MAIN = process.env.REACT_APP_API_URL_MAINNET;


export const NETWORK =
  process.env.REACT_APP_IS_BETA === "true" ? "pre" : "cardano";


export const STATE = process.env.REACT_APP_POOL_FLAG === "true" ? "production" : "staging";
