export const getNetworkCurrency = (network) => {
  if (network) {
    return network === "ethereum" ? "USDC" : "ADA";
  }
  return "USDC";
};

export const getNetwork = (network) => {
  if (network) {
    return network === "ethereum" ? "ETH Mainnet" : "Cardano Mainnet";
  }
  return "ETH Mainnet";
};
