import React, { useEffect, useState } from "react";
import { Box, Row, Column, Header, H1, H2, Text } from "../../components";
import { useWeb3React } from "@web3-react/core";
import Button from "../../components/Button/Button";
import { createNotification } from "store/actions/uiActions";
import { OrderTopics, OrderItem, DropdownMenu } from "./components";
import { connect } from "react-redux";
import { useWindowSize } from "utils/windowSizeHook";
import searchIcon from "../../assets/search.svg";
import { addWallets } from "utils/ada/addWallets";
import LoaderLogo from "assets/tang_logo_animated.svg";
import LoadLogo from "../../assets/empty.png";
import connectIcon from "../../assets/wallet/connect.svg";
import disconnectIcon from "../../assets/wallet/disconnect.svg";
import { getOrders } from "utils/ada/getPoolData";
import {
  setWalletModal,
  startAction,
  stopAction,
} from "../../store/actions/uiActions";
import { disconnectUserAccount } from "../../store/actions/userActions";


const Orders = ({
  adaWallet,
  setUsersStakedTokens,
  setUsersRewards,
  setPoolsStakedTokens,
  signerAddress,
  network,
  setWalletModal, disconnectUserAccount

}) => {
  const windowSize = useWindowSize();
  const [tokenInfo, setTokenInfo] = useState();
  const [allTokensInfo, setAllTokenInfo] = useState();
  const { deactivate } = useWeb3React();
  const [connectBtnIcon, setConnectBtnIcon] = useState(connectIcon);
  const actionArr = ["All", "Stake", "Unstake", "Claim"];
  const statusArr = ["All", "Open", "Completed", "Pending", "Failed"];

  useEffect(() => {
    if (signerAddress) { 
      getOrders( network == "pre", setTokenInfo, setAllTokenInfo)
    }
  }, [signerAddress]);
 
  // React.useCallback(() => {
  //   if (signerAddress) { 
  //     getOrders( network == "pre", setTokenInfo)
  //   }
  // });


  const onSearchItem = (val) => {
    const result = allTokensInfo.filter(
      (item) =>
        item &&
        item.asset &&
        item.asset.toLowerCase().includes(val.toLowerCase())
    );
    setTokenInfo(result);
  };

  const onActionChanged = (val) => {
    const index = val / 10;
    let sortAction = actionArr[index - 1];
    if(index == 1) { setTokenInfo(allTokensInfo); return;}
    const result = allTokensInfo.filter(
      (item) =>
        item &&
        item.action &&
        item.action.toLowerCase() == sortAction.toLowerCase()
    );
    setTokenInfo(result);
  };

  const onStatusChanged = (val) => {
    const index = val / 10;
    let statusAction = statusArr[index - 1];
    if(index == 1){ setTokenInfo(allTokensInfo);return;}
    const result = allTokensInfo.filter(
      (item) =>
        item &&
        item.status &&
        item.status.toLowerCase().includes(statusAction.toLowerCase())
    );
    setTokenInfo(result);
  };

  return (
    <div>
      <div className="my-nfts-page">
      {!signerAddress ?
          <>
          <Column className="my-orders">

            <div className="emptyPageContainer">
              <img className="emptyImg" src={LoadLogo} alt="Loading" />
              <p className="emptyText">
                No data available, please connect wallet
              </p>
              <button
                className="connect-button-mynft"
                onClick={() => {
                  if (!signerAddress) {
                    setWalletModal(true);
                  } else {
                    createNotification(
                      "success",
                      "Wallet Disconnected Successfully",
                      4000
                    );
                    deactivate();
                    disconnectUserAccount();
                  }
                }}
                onMouseOver={() => {
                  setConnectBtnIcon(disconnectIcon);
                }}
                onMouseOut={() => {
                  setConnectBtnIcon(connectBtnIcon);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    color: "#fff",
                  }}
                >
                  {signerAddress ? "Disconnect" : "Connect Wallet"}
                </div>
              </button>
            </div>
          </Column>
          </>
          :
          <Column className="my-orders">
          
            <div className="orders">
              <Row>
                <H2 text="Orders" className="nft-heading" />
              </Row>
              <Row className="orders-menu">
                <Box className="box-big" style={{ width: "100%" }}>
                  <Row className="sort-menu">
                    <Row>
                      <Text text={tokenInfo ? tokenInfo.length : 0} style={{ color: "white" }} />
                      <Text
                        text="orders listed"
                        style={{ paddingLeft: "4px" }}
                      />
                    </Row>
                    <Row>
                      <Row>
                        <Text text="Action:" style={{ paddingRight: "1rem" }} />
                        <DropdownMenu
                          options={actionArr}
                          onOptionChanged={onActionChanged}
                        />
                      </Row>
                      <Row className="sort-menu__dropdown">
                        <Text text="Status:" style={{ paddingRight: "1rem" }} />
                        <DropdownMenu
                          options={statusArr}
                          onOptionChanged={onStatusChanged}
                        />
                      </Row>
                    </Row>
                  </Row>
                  <Row
                    style={{
                      position: "relative",
                      justifyContent: "start",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <input
                      placeholder="Search by token"
                      className="search"
                      type="text"
                      onChange={(evt) => onSearchItem(evt.target.value)}
                    />
                    <img
                      onClick={() => onSearchItem()}
                      className="search__icon"
                      src={searchIcon}
                      alt="Search"
                    />
                  </Row>
                  {windowSize[0] < 900 ? null : <OrderTopics />}
                  {
                  tokenInfo?.length == 0 ? (
            <>
             
             <Row             
             style={{
                      position: "relative",
                      justifyContent: "center",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                    }}>
                <span className="noOrder">No orders found</span>
             </Row>

            </>
          ) : (!tokenInfo ?
            <Box className="box-big" style={{backgroundColor: "transparent", marginTop: 30, textAlign: "center"}}>
            <img className="loadLogo" src={LoaderLogo} alt="Loading" />
          </Box> :
            
            tokenInfo?.map((item, index) => (
                    <OrderItem
                      data={item}
                      windowSize={windowSize}
                      key={index}
                      style={{
                        ...(tokenInfo.length - 1 === index && {
                          borderBottom: "none",
                        }),
                      }}
                    />
                  )))}
                </Box>
              </Row>
            </div>
          
        </Column>
      
      }


      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    signerAddress: state.user.signerAddress,
    network: state.network.network,

  };
};

export default connect(mapStateToProps, {
  createNotification,
  setWalletModal,
  startAction,
  stopAction,
  disconnectUserAccount,
})(Orders);
