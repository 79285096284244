// import React, { useState } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { Row, Column, H1, H2, H3 } from "../../components";
import { Box, Text, CountUpNumber } from "../../components/index";
import lockIcon from "../../assets/infoSection/lock-info-section.png";
import magicIcon from "../../assets/magic.png";
import Loader from "react-js-loader";
import {
  createNotification
} from "../../store/actions/uiActions";
import filterIcon from "../../assets/filter.png";
import checkIcon from "../../assets/check.png";
import closeIcon from "../../assets/close-icon.svg";
import {
  API_URL,
  API_URL_MAIN,
} from "../../utils/constants";
import dragIcon from "../../assets/drag.png";
import { useWindowSize } from "utils/windowSizeHook";
import LoadLogo from "assets/tang_logo_animated.svg";
import emptyLogo from "../../assets/empty.png";
import { ImageItem } from "./ImageItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Stepper from "react-stepper-horizontal";
import connectIcon from "../../assets/wallet/connect.svg";
import disconnectIcon from "../../assets/wallet/disconnect.svg";
import React, { useState, useRef, useEffect } from "react";
import Button from "../../components/Button/Button";
import DatePicker from 'react-datepicker';
import { differenceInCalendarDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { getPools } from "utils/ada/getPoolData";
import {
  setWalletModal,
  startAction,
  stopAction,
} from "../../store/actions/uiActions";
import { disconnectUserAccount } from "../../store/actions/userActions";
import ReactImageMagnify from "react-image-magnify";

const PoolCreation = ({
  adaWallet,
  signerAddress,
  setWalletModal,
  startAction,
  network,
  stopAction,
  disconnectUserAccount,
  createNotification
}) => {
  getPools(null, null, null, network == "pre");
  const [latestPrompts, setLatestPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mintButtonTitle, setMintButtonTitle] = useState("Finalize Mint");
  const [selectedImages, setSelectedImages] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const handleCheckboxChange = (imageSrc) => {
    if (selectedImages.includes(imageSrc)) {
      setActiveStep(selectedImages.length == 1 ? 0 : 1);
      setSelectedImages(selectedImages.filter((img) => img !== imageSrc));
    } else {
      setSelectedImages([...selectedImages, imageSrc]);
      setActiveStep(1);
    }
  };

  const handleClick = () => {
    setIsLoading(true);
    setMintButtonTitle("Minting...")
    setTimeout(() => {
      setMintButtonTitle("Mint Completed")
      //setActiveStep(3)
    }, 5000);
  };

  const handleOpen = () => {
    // setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
  };



  console.log(latestPrompts);
  useEffect(() => {
    // Fetch the API data when the latestPrompts array changes
    const fetchData = async () => {
      if (signerAddress) {
        try {
          const response = await axios.post(
            "https://staking-beta-tangent-2188646282df.herokuapp.com/pool/latest-prompts",
            {
              userWallet: signerAddress,
            }
          );

          // Handle the response here (e.g., set state or perform other actions)
          // You can access the API data using response.data
          setLatestPrompts(response.data.prompts.reverse());
        } catch (error) {
          // Handle any errors that occur during the process
          console.error("Error:", error);
        }
      }
    };

    fetchData(); // Call the fetchData function when latestPrompts changes
  }, [signerAddress]); // Include latestPrompts as a dependency

  console.log("here", latestPrompts);

  const [connectBtnIcon, setConnectBtnIcon] = useState(connectIcon);
  const [ratio, setRatio] = useState(1);
  const [count, setCount] = useState(4);
  const [progress, setProgress] = useState(0);
  const [startImagination, setStartImagination] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [finalResult, setFinalResult] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const [imageStyle, setImageStyle] = useState("");
  const [promptText, setPromptText] = useState("");

  const [selectedRatioButton, setSelectedRatioButton] = useState(0);

  const handleRatioButtonClick = (index) => {
    setSelectedRatioButton(index);
  };

  const buttonsData = [
    { label: "512 x 512", index: 0 },
    { label: "768 x 768", index: 5 },
    { label: "512 x 1024", index: 1 },
    { label: "768 x 1024", index: 2 },
    // { label: "1024 x 768", index: 3 },
    // { label: "1024 x 1024", index: 4 },
  ];

  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const dropZoneRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  // Function to handle generating the image
  const generateImage = async (prompt) => {
    console.log("Selected Ratio:", ratio);
    console.log("Selected Count:", count);
    console.log("Selected Image Style:", imageStyle);
    console.log("Prompt Text:", promptText);

    setPrompt(prompt);
    try {
      // Step 1: Send a POST request to generate the image
      const response = await axios.post(
        "https://staking-beta-tangent-2188646282df.herokuapp.com/pool/imagine",
        {
          userWallet: signerAddress,
          ratio,
          count,
          imageStyle,
          promptText,
          prompt,
        }
      );

      console.log(response.data.result.messageId);
      const progressKey = response.data.result.messageId; // Assuming progressKey is in the response
      console.log(progressKey);

      // Step 2: Poll the GET API until progressKey reaches 100
      let progress = 0;
      while (progress < 100) {
        const progressResponse = await axios.post(
          `https://staking-beta-tangent-2188646282df.herokuapp.com/pool/progress-imagine/${progressKey}`,
          {
            userWallet: signerAddress,
          }
        );
        progress = progressResponse.data.result.progress;
        setStartImagination(true);
        setProgress(progress);
        console.log("Progress:", progress);

        // Add some delay before making the next GET request to avoid overloading the server
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay
      }

      // Step 3: Once progress reaches 100, you can fetch the final image or result
      const finalResponse = await axios.post(
        `https://staking-beta-tangent-2188646282df.herokuapp.com/pool/progress-imagine/${progressKey}?state=final`,
        {
          userWallet: signerAddress,
        }
      );

      const finalImage = finalResponse.data.result.response.imageUrls;
      console.log("Final Image:", finalResponse.data.result.response.imageUrls);

      // setFinalResult(finalImage);
      setLatestPrompts([
        { prompt: prompt, images: finalImage },
        ...latestPrompts,
      ]);
      setStartImagination(false);
      setInputValue("");
      // setActiveStep(1);

      console.log("Final Image:", finalImage);

      // You can perform further actions with the final image here
    } catch (error) {
      console.error("Error:", error);
      // Handle any errors that occur during the process
    }
  };

  function Button({ label, selected, onClick }) {
    return (
      <div
        className={`button-ratio ${selected ? "selected-ratio" : ""}`}
        onClick={onClick}
      >
        <div className={`circle ${selected ? "selected-r" : ""}`}>
          {selected && (
            <img
              className="info-section__header__icon"
              src={checkIcon}
              alt="Lock icon"
              style={{
                color: "white",
                marginRight: 1.5,
                height: 11,
                width: 8,
              }}
            />
          )}
        </div>
        {label}
      </div>
    );
  }

  const [inputValue, setInputValue] = useState("");
  
  const [typeOfPool, setTypeOfPool] = useState("");
  const [poolName, setPoolName] = useState("");
  const [stakingUnitPolicyID, setStakingUnitPolicyID] = useState("");
  const [stakingUnitAssetName, setStakingUnitAssetName] = useState("");
  const [stakingUnitDecimals, setStakingUnitDecimals] = useState("");
  const [rewardUnitPolicyID, setRewardUnitPolicyID] = useState("");
  const [rewardUnitAssetName, setRewardUnitAssetName] = useState("");
  const [rewardUnitDecimals, setRewardUnitDecimals] = useState("");
  const [poolStartDate, setPoolStartDate] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [poolEndDate, setPoolEndDate] = useState("");
  const [poolDuration, setPoolDuration] = useState("");
  const [poolTotalReward, setPoolTotalReward] = useState("");
  const [rewardPerEpoch, setRewardPerEpoch] = useState("");
  const [projectName, setProjectName] = useState("");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [telegram, setTelegram] = useState("");

  const [isToggleOn, setIsToggleOn] = useState(true);

  useEffect(() => {
    if (poolStartDate && poolEndDate) {
      const durationDays = differenceInCalendarDays(poolEndDate, poolStartDate);
      // Assuming duration is in days; adjust the logic if needed
      setPoolDuration(durationDays > 0 ? `${durationDays} days` : '');
    }
  }, [poolStartDate, poolEndDate]);

  const toggleButtonClick = () => {
    setIsToggleOn(!isToggleOn);
  };

  function formatEmailContent() {
    return `<p>
      Type of Pool: ${typeOfPool}<br />
      Pool Name: ${poolName}<br />
      Staking Unit Policy ID: ${stakingUnitPolicyID}<br />
      Staking Unit Asset Name: ${stakingUnitAssetName}<br />
      Staking Unit Decimals: ${stakingUnitDecimals}<br />
      Reward Unit Policy ID: ${rewardUnitPolicyID}<br />
      Reward Unit Asset Name: ${rewardUnitAssetName}<br />
      Reward Unit Decimals: ${rewardUnitDecimals}<br />
      Pool Start Date: ${poolStartDate}<br />
      Pool End Date: ${poolEndDate}<br />
      Pool Duration: ${poolDuration}<br />
      Pool Total Reward: ${poolTotalReward}<br />
      Reward per Epoch: ${rewardPerEpoch}<br />
      Project Name: ${projectName}<br />
      Twitter: ${twitter}<br />
      Discord: ${discord}<br />
      Telegram: ${telegram}<br />
      Owner Email: ${ownerEmail}<br />
      Wallet Address: ${signerAddress}
      </p>`;
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!typeOfPool || !poolName || !stakingUnitPolicyID || !stakingUnitAssetName || !stakingUnitDecimals || !rewardUnitPolicyID || !rewardUnitAssetName || !rewardUnitDecimals
      || !poolStartDate || !poolEndDate || !poolTotalReward || !rewardPerEpoch || !ownerEmail){
      createNotification(
        "error",
        "Please fill mendatory fields",
        2000
      );
      return;
    }

   setActiveStep(1)
   return;

  
  };

  const resetEverything = () => {
    
    setActiveStep(0)
    setTypeOfPool("")
   setPoolName("")
   setStakingUnitPolicyID("");
 setStakingUnitAssetName("");
 setStakingUnitDecimals("");
setRewardUnitPolicyID("");
 setRewardUnitAssetName("");
setRewardUnitDecimals("");
setPoolStartDate("");
 setOwnerEmail("");
 setPoolEndDate("");
 setPoolDuration("");
 setPoolTotalReward("");
 setRewardPerEpoch("");
 setProjectName("");
 setTwitter("");
 setDiscord("");
 setTelegram("");
  }

  const toggleButtonStyle = {
    background: isToggleOn
      ? "linear-gradient(147deg, rgb(255 159 189) 0%, rgb(248, 52, 114) 100%) 0% 0% no-repeat padding-box padding-box"
      : "#ccc",
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const numberArray = (count) => Array.from({ length: count + 1 }, (_, index) => index);

  const handleButtonClick = () => {
    // Handle button click here, e.g., perform an action with inputValue
    // alert(`You entered: ${inputValue}`);
    generateImage(inputValue);
  };

  const windowSize = useWindowSize();

  const onSearchItem = (val) => {};

  const [selectedButton, setSelectedButton] = useState(4);
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleGroupButtonClick = (number) => {
    setSelectedButton(number);
  };

  const [sliderValue, setSliderValue] = useState(20);

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  

  const gradientLine = {
    background: `linear-gradient(to right, #f36b96 ${sliderValue}%, rgb(255 255 255 / 12%) ${sliderValue}%)`,
  };

  const renderButtons = () => {
    const buttons = [];

    for (let i = 1; i <= 8; i++) {
      const isSelected = selectedButton === i;
      const buttonClassName = isSelected
        ? "circle-button selected"
        : "circle-button";

      buttons.push(
        <button
          key={i}
          className={buttonClassName}
          onClick={() => handleGroupButtonClick(i)}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div>
      <Row style={{ paddingTop: 100 }}>
        <Stepper
          style={{ marginTop: 10 }}
          size={55}
          circleFontSize={18}
          // defaultOpacity=".5"
          defaultColor="#2A3740"
          activeBorderColor="#F83472"
          activeColor="#F83472"
          activeTitleColor="#ffffff"
          completeTitleColor="#757575"
          completeColor="#F83472"
          completeBorderColor="#2A3740"
          completeBarColor="#F83472"
          steps={[
            { title: "Add Information" },
            { title: "Confirm Pool" },
            { title: "Submit" }
          ]}
          activeStep={activeStep}
        />
      </Row>

      {!signerAddress ? (
        <>
          <Column className="my-orders">
            <div className="emptyPageContainer">
              <img className="emptyImg" src={emptyLogo} alt="Loading" />
              <p className="emptyText">Please Connect Your Wallet</p>
              <button
                className="connect-button-mynft"
                onClick={() => {
                  if (!signerAddress) {
                    setWalletModal(true);
                  } else {
                    // createNotification(
                    //   "success",
                    //   "Wallet Disconnected Successfully",
                    //   4000
                    // );
                    // deactivate();
                    disconnectUserAccount();
                  }
                }}
                onMouseOver={() => {
                  setConnectBtnIcon(disconnectIcon);
                }}
                onMouseOut={() => {
                  setConnectBtnIcon(connectBtnIcon);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    color: "#fff",
                  }}
                >
                  {signerAddress ? "Disconnect" : "Connect Wallet"}
                </div>
              </button>
            </div>
          </Column>
        </>
      ) :
        <div className="farms-create">
          <Row className="farms-create__menu">

          <Box style={{width: "100%", background: "#2b374024", boxShadow: 'none'}}>

            {activeStep == 2 ? <Box className="wallet-modal">
        {/* {loading ? (
          <div style={{ width: "20%" }}>
            <Loader loadState={loading} />
          </div>
        ) : ( */}
          <>
       
                <Row
                  className="wallet-modal__header"
                  style={{ display: "block" }}
                >
                  {/* <img
                    onClick={() => setWalletModal(false)}
                    className="wallet-modal__close"
                    src={closeIcon}
                    alt="close-icon"
                  /> */}

                  <Column>
                    <Text
                      style={{
                        "text-align": "center",
                        color: "#FFF",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 27,
                        "font-style": "normal",
                        "font-weight": 600,
                        "line-height": "normal",
                        marginTop: 10,
                        marginBottom: 8,
                      }}
                    >
                    Submitted Successfully
                    </Text>
                 
                  </Column>
                </Row>
                <Column center className="wallet-column">
                  <Text style={{marginTop: 20, color: "white",
    fontSize: 15,
    textAlign: "center"}}>Thank you for submitting your pool creation request.<br/>
    Our team will contact you shortly.</Text>
                 

                 
                  <Row
                    onClick={() => {
                     resetEverything()
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "10px",
                      width: "95%",
                      padding: 7,
                      "text-align": "center",
                      display: "block",
                      borderRadius: 4,
                    
                      backgroundColor: "#f83472",
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 15,
                        "font-style": "normal",
                        "font-weight": 500,
                        "line-height": "normal",
                      }}
                    >
                      Ok!
                    </Text>
                  </Row>
             
                </Column>
              </>
           
     
      </Box> : <></>}

            {activeStep == 1 ? 
            
            <div className="preview-container">
            <h2>Preview of Pool Creation</h2>
            <div className="preview-section">
              <p><strong>Type of Pool:</strong> {typeOfPool || 'Not specified'}</p>
              <p><strong>Pool Name:</strong> {poolName || 'Not specified'}</p>
              <p><strong>Project Name:</strong> {projectName || 'Not specified'}</p>
              <p><strong>Owner Email:</strong> {ownerEmail || 'Not specified'}</p>
              <p><strong>Staking Unit Policy ID:</strong> {stakingUnitPolicyID || 'Not specified'}</p>
              <p><strong>Staking Unit Asset Name:</strong> {stakingUnitAssetName || 'Not specified'}</p>
              <p><strong>Staking Unit Decimals:</strong> {stakingUnitDecimals || 'Not specified'}</p>
              <p><strong>Reward Unit Policy ID:</strong> {rewardUnitPolicyID || 'Not specified'}</p>
              <p><strong>Reward Unit Asset Name:</strong> {rewardUnitAssetName || 'Not specified'}</p>
              <p><strong>Reward Unit Decimals:</strong> {rewardUnitDecimals || 'Not specified'}</p>
              <p><strong>Pool Start Date and Time:</strong> {poolStartDate.toString() || 'Not specified'}</p>
              <p><strong>Pool End Date and Time:</strong> {poolEndDate.toString() || 'Not specified'}</p>
              <p><strong>Pool Duration:</strong> {poolDuration || 'Not specified'}</p>
              <p><strong>Pool Total Reward:</strong> {poolTotalReward || 'Not specified'}</p>
              <p><strong>Reward per Epoch:</strong> {rewardPerEpoch || 'Not specified'}</p>
              <p><strong>Twitter Link:</strong> {twitter || 'Not specified'}</p>
              <p><strong>Discord Link:</strong> {discord || 'Not specified'}</p>
              <p><strong>Telegram Link:</strong> {telegram || 'Not specified'}</p>
            </div>
            <Row>
            <button className="preview-button"
            style={{border: "1.5px solid #ff4081", background: "none"}}
             onClick={async () => {
              setActiveStep(0)
            }}>
              Back
            </button>
<div style={{width: "100%"}}></div>


{
isLoading ? 
<Loader
type="spinner-default"
title=""
bgColor={"#d02315"}
size={50}
/> :  <button className="preview-button" onClick={async () => {
            setIsLoading(true);
             try {
                let emailcontent =  formatEmailContent();
 
                let res = await axios.post(
                  `${network == "pre" ? API_URL : API_URL_MAIN}pool/create-pool`,
                  {
                    emailcontent
                  }
                ); 
                createNotification(
                 "success",
                 "Your Pool Creation submitted successfully",
                 2000
               );

               setActiveStep(2)
               
             } catch (error) {
              
             }
              setIsLoading(false);
            }}>
           
              Submit
            </button>
}

            </Row>
           
          </div>
          :  <></>}

          {activeStep == 0 ? 
     
            <Column className="farms-create" sty>
              {/* {windowSize[0] <= 1200 ? ( */}
              <Row className="farms-create__menu-left__search-collectible">
                <H1
                  text=""
                  className="farms-create__menu-left__search-collectible__title"
                />
              </Row>
              {/* ) : null} */}

              {windowSize[0] < 900 ? (
                <Button
                  className="detail-button"
                  style={{
                    background: "rgba(255, 255, 255, 0.0001)",
                    border: "1px solid rgba(255, 255, 255, 0.4)",
                  }}
                  //onClick={() => onOpenModal("info")}
                  text={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                      }}
                    >
                      Details
                    </div>
                  }
                />
              ) : null}
              {
               <form onSubmit={handleSubmit}>
                <H2
                  text="Pool Info"
                  className="farms-create__menu-left__search-collectible__title"
                  style={{marginBottom: 10, fontSize: 23}}
                />

                <Row>
                <div className="input-group" style={{width: "100%", marginRight: 10}}>
                <select           
                    value={typeOfPool} 
                    onChange={(e) => setTypeOfPool(e.target.value)}
                    className="custom-select"
                >
                    <option value="" disabled hidden>Select Type of Pool *</option>
                    <option style={{fontFamily: 'poppins-regular,sans-serif'}} value="Liquidity Pool">Token Farming Pool</option>
                    <option style={{fontFamily: 'poppins-regular,sans-serif'}} value="Liquidity Token">NFT Staking Pool</option>
                </select>
            </div>

                    <div
                  className={`input-container-create`}
                >
                  <input
                    type="text"
                    placeholder="Enter Pool Name*"
                    value={poolName}
                    onChange={(event) => {
                      setPoolName(event.target.value);
                    }}
                    className="rounded-input"
                  />
                             
                </div>
                </Row>
                <Row>
                <div
                  className={`input-container-create`}
                  style={{width: "100%", marginRight: 10}}
                >
                  <input
                    type="text"
                    placeholder="Enter Project Name *"
                    value={projectName}
                    onChange={(event) => {
                      setProjectName(event.target.value)
                    }}
                    className="rounded-input"
                  />
                             
                </div>

                <div
                  className={`input-container-create`}
                >
                  <input
                    type="text"
                    placeholder="Enter Owner Email*"
                    value={ownerEmail}
                    onChange={(event) => {
                      setOwnerEmail(event.target.value)
                    }}
                    className="rounded-input"
                  />
                             
                </div>
                </Row>

                <H2
                  text="Unit Info"
                  className="farms-create__menu-left__search-collectible__title"
                  style={{marginBottom: 10, fontSize: 23}}
                />


<Row>
                <div
                  className={`input-container-create`}
                  style={{width: "100%", marginRight: 10}}
                >
                  <input
                    type="text"
                    placeholder="Enter Staking Unit Policy ID*"
                    value={stakingUnitPolicyID}
                    onChange={(event) => {
                      setStakingUnitPolicyID(event.target.value);
                    }}
                    className="rounded-input"
                  />
                             
                </div>

                <div
                  className={`input-container-create`}
                  style={{width: "100%", marginRight: 10}}
                >
                  <input
                    type="text"
                    placeholder="Enter Staking Unit Asset Name*"
                    value={stakingUnitAssetName}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      // Allow only hexadecimal characters (0-9, A-F, a-f)
                      if (/^[0-9A-Fa-f]*$/.test(newValue)) {
                          setStakingUnitAssetName(newValue);
                      }
                    }}
                    className="rounded-input"
                  />
                             
                </div>

                <div
                  className={`input-container-create`}
                  style={{width: "60%" }}
                >
                  <input
                    type="text"
                    placeholder="Enter Staking Unit Decimals*"
                    value={stakingUnitDecimals}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      if (/^[0-9]*$/.test(newValue)) {
                          setStakingUnitDecimals(newValue);
                      }
                    }}
                    className="rounded-input"
                  />
                             
                </div>
                </Row>

                <H2
                  text="Reward Info"
                  className="farms-create__menu-left__search-collectible__title"
                  style={{marginBottom: 10, fontSize: 23}}
                />
<Row>
                <div
                  className={`input-container-create`}
                  style={{width: "100%", marginRight: 10}}
                >
                  <input
                    type="text"
                    placeholder="Enter Reward Unit Policy ID*"
                    value={rewardUnitPolicyID}
                    onChange={(event) => {
                      setRewardUnitPolicyID(event.target.value);
                    }}
                    className="rounded-input"
                  />
                             
                </div>


                <div
                  className={`input-container-create`}
                  style={{width: "100%", marginRight: 10}}
                >
                  <input
                    type="text"
                    placeholder="Enter Reward Unit Asset Name*"
                    value={rewardUnitAssetName}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      // Allow only hexadecimal characters (0-9, A-F, a-f)
                      if (/^[0-9A-Fa-f]*$/.test(newValue)) {
                          setRewardUnitAssetName(newValue);
                      }
                    }}
                    className="rounded-input"
                  />
                             
                </div>

                <div
                  className={`input-container-create`}
                  style={{width: "60%"}}
                >
                  <input
                    type="text"
                    placeholder="Enter Reward Unit Decimals*"
                    value={rewardUnitDecimals}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      if (/^[0-9]*$/.test(newValue)) {
                          setRewardUnitDecimals(newValue);
                      }
                    }}
                    className="rounded-input"
                  />
                             
                </div>

                </Row>

                <H2
                  text="Timing Info"
                  className="farms-create__menu-left__search-collectible__title"
                  style={{marginBottom: 10, fontSize: 23}}
                />

<Row>
                <div className="input-group"     style={{width: "100%", marginRight: 10}} >
        <DatePicker
           
          selected={poolStartDate}
          onChange={(date) => setPoolStartDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="time"
          className="custom-date-picker"
          placeholderText="Select start date and time*"
        />
      </div>

      <div className="input-group"     style={{width: "100%", marginRight: 10}}>
        <DatePicker
          selected={poolEndDate}
          onChange={(date) => setPoolEndDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="time"
          className="custom-date-picker"
          placeholderText="Select end date and time*"
          minDate={poolStartDate}
        />
      </div>


      <div
                  className={`input-container-create`}
                  style={{width: "60%"}}
                >
                  <input
                    type="text"
                    disabled={true}
                    placeholder="Pool Duration"
                    value={poolDuration}
                    // onChange={(event) => {
                    //   setRewardUnitPolicyID(event.target.value);
                    // }}
                    className="rounded-input"
                  />
                             
                </div>


                </Row>


                <H2
                  text="Reward Info"
                  className="farms-create__menu-left__search-collectible__title"
                  style={{marginBottom: 10, fontSize: 23}}
                />

<Row>
                <div
                  className={`input-container-create`}
                  style={{width: "100%", marginRight: 10}}
                >
                  <input
                    type="text"
                    placeholder="Enter Pool Total reward*"
                    value={poolTotalReward}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      if (/^[0-9]*$/.test(newValue)) {
                          setPoolTotalReward(newValue);
                      }
                    }}
                    className="rounded-input"
                  />
                             
                </div>

                <div
                  className={`input-container-create`}
                  style={{width: "100%"}}
                >
                  <input
                    type="text"
                    placeholder="Enter Reward per epoch*"
                    value={rewardPerEpoch}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      if (/^[0-9]*$/.test(newValue)) {
                          setRewardPerEpoch(newValue);
                      }
                    }}
                    className="rounded-input"
                  />
                             
                </div>

                </Row>


              
                <H2
                  text="Other"
                  className="farms-create__menu-left__search-collectible__title"
                  style={{marginBottom: 10, fontSize: 23}}
                />


<Row>

                <div
                  className={`input-container-create`}
                  style={{width: "100%", marginRight: 10}}
                >
                  <input
                    type="text"
                    placeholder="Enter Twitter Link"
                    value={twitter}
                    onChange={(event) => {
                      setTwitter(event.target.value)
                    }}
                    className="rounded-input"
                  />
                             
                </div>
                <div
                  className={`input-container-create`}
                  style={{width: "100%", marginRight: 10}}
                >
                  <input
                    type="text"
                    placeholder="Enter Discord Link"
                    value={discord}
                    onChange={(event) => {
                      setDiscord(event.target.value)
                    }}
                    className="rounded-input"
                  />
                             
                </div>
                <div
                  className={`input-container-create`}
                >
                  <input
                    type="text"
                    placeholder="Enter Telegram Link"
                    value={telegram}
                    onChange={(event) => {
                      setTelegram(event.target.value)
                    }}
                    className="rounded-input"
                  />
                             
                </div>

                </Row>

                
                <div
                style={{marginTop: 10}}
       
                >
                  
                  {/* <button   className="rounded-button" type="submit">Submit</button> */}
                </div>


                <button
                className="connect-button-mynft"
                style={{   
                  marginTop: 40,
                  width: "100%"
                }}
                type="submit"
                //onClick={handleSubmit}

                //disabled={nftDes == "" || nftName == ""}
                // onMouseOver={() => {
                //   setConnectBtnIcon(disconnectIcon);
                // }}
                // onMouseOut={() => {
                //   setConnectBtnIcon(connectBtnIcon);
                // }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    color: "#fff",
                  }}
                >
               Continue
                </div>
              </button>
                </form>
            
                
              }

             
            </Column>
 : <></>}

            </Box>

            {/* {windowSize[0] < 900 ? null : (
              <Column
                className="info-section-ai sticky-column"
                style={{ marginTop: "2.5 rem" }}
              >
                {windowSize[0] < 900 ? (
                  <Text
                    text="Details"
                    className="info-section-ai__detail"
                    style={{ fontSize: "30px" }}
                  />
                ) : null}
                <Box
                  className="info-section-ai__info-box-locked"
                  style={{
                    marginBottom: "1rem",
                    paddingTop: 30,
                    display: "table",
                    overflow: "hidden",
                  }}
                  backgroundColor="#2b3740"
                >
                  
                
                </Box>
              </Column>
            )} */}
          </Row>
        </div>
}
      
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    signerAddress: state.user.signerAddress,
    network: state.network.network,
  };
};

export default connect(mapStateToProps, {
  setWalletModal,
  startAction,
  stopAction,
  createNotification,
  disconnectUserAccount,
})(PoolCreation);


