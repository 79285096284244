/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */

import React, { useState } from "react";
import { Row, Text, Column, CountUpNumber } from "../../../components";
import { connect } from "react-redux";
import tangLogo from "../../../assets/tokenStaking/tang.svg";
import goldieLogo from "../../../assets/tokenStaking/goldie.png";
import ratDaoLogo from "../../../assets/tokenStaking/ratdao.png";
import arrowDown from "../../../assets/arrow-down.svg";
import arrowUp from "../../../assets/arrow-up.svg";
import arrowDownHover from "../../../assets/arrow-down-hover.svg";
import arrowUpHover from "../../../assets/arrow-up-hover.svg";
import ExpandedNftItem from "./ExpandedNftItem";
import { createNotification } from "store/actions/uiActions";

// take id of farm => based on id show image
const NftItem = (props) => {
  const [itemIsExpanded, setItemIsexpanded] = useState(false);
  const [arrowImg, setArrowImg] = useState(arrowDown);
  const {
    decimal,
    icon,
    tokenContract, 
    getToken,
    title,
    APY,
    image,
    style,
    windowSize,
    id,
    hash,
    openModal,
    edition,
    adaWallet,
    nftAssets,
    rightHash,
    userUsesSearchFunctionality,
    contractAddress,
    currentLpNftFarmContractName,
    amountOfStakedNftIdLpUniswap,
    amountOfStakedNftIdLpSakeswap,
    stakingUnit,
    farmContractName,
    farm,
    earn,
    stakingWallet,
    poolId,
    userStakedTokens,
    userRewards,
    poolRewards,
    createNotification,
    minutesUntilPoolIsClosing,
    closesAt,
    tangentAmount,
    assetsIds,
    distributionWallet,
    stakedTokens
  } = props;

  // const isUnstakingAvailable = false;
  const delimiter = "The Cardanaires";
const parts = title.split(delimiter);
const delimiterEdition = " ";

const separateText = (text, delimiter) => {
  const words = text.split(delimiter);
  const firstPart = words.slice(0, -1).join(delimiter);
  const editionPart = words[words.length - 1];
  return [firstPart, editionPart];
};

const [firstPart, editionPart] = separateText(parts[1], delimiterEdition);


  const isMobileViewEnabled = windowSize[0] < 900;

  const staked = userStakedTokens ? userStakedTokens[poolId] : 0;

  const userNfts = assetsIds ?  Object.keys(assetsIds).filter(
    (assets) => {
      return stakingUnit && stakingUnit.includes(assets)
    } 
  ) : [];

  const rewards = userRewards
  ? [{ [earn]: userRewards[poolId] }]
  :[ {"TANG":0}];

  const expand = () => {
    setItemIsexpanded(!itemIsExpanded);
    if (itemIsExpanded) {
      setArrowImg(arrowDown);
    } else {
      setArrowImg(arrowUp);
    }
  };

  const onArrowHover = () => {
    if (itemIsExpanded) {
      setArrowImg(arrowUpHover);
    } else {
      setArrowImg(arrowDownHover);
    }
  };

  const onArrowOut = () => {
    if (itemIsExpanded) {
      setArrowImg(arrowUp);
    } else {
      setArrowImg(arrowDown);
    }
  };
  

  const currentAmountOfStakedNftId =
    currentLpNftFarmContractName === "LpUniswapTokenFarmContract"
      ? amountOfStakedNftIdLpUniswap
      : amountOfStakedNftIdLpSakeswap;

  return (
    <Column>
      <Row
        className="nft-item"
        style={{
          ...style,
          ...(itemIsExpanded && { borderBottom: "none", marginBottom: 0 }),
        }}
        onClick={expand}
      >
        <Row className="nft-item__big-space">
          <img
            src={icon}
            alt="NFT"
            className="nft-item__image"
            style={{
              width: "124px",
              marginRight: "1.8rem",
            }}
          />
          <div>
          <Text text={`${delimiter}`} className="nft-item__title" />
          <Text text={firstPart} className="nft-item__title" />
          <Text text={editionPart} className="nft-item__title" />
          </div>
        </Row>
        
        {isMobileViewEnabled ? (
          <Row style={{ width: "100%" }}>
            <div className="nft-item__small-space">
              <Text text="Yield" className="liquidity-item__value" />
            </div>
            <div className="liquidity-item__dashline" />
            <div className="nft-item__small-space">
              <Text
                text={APY + "%"}
                className="liquidity-item__value"
                style={{ textAlign: "right" }}
              />
            </div>
          </Row>
        ) : (
          <div className="nft-item__small-space">
            <CountUpNumber
              className="liquidity-item__apy"
              startValue={APY}
              end={APY}
              decimals={0}
              suffix={"%"}
            />
          </div>
        )}
        {isMobileViewEnabled ? (
          <Row style={{ width: "100%" }}>
            <Row style={{ width: "100%" }}>
              <div className="nft-item__small-space">
                <Text text="Staked" className="liquidity-item__value" />
              </div>
              <div className="liquidity-item__dashline" />
              <div className="nft-item__small-space">
                <Text
                  text={`${staked}%`}
                  className="liquidity-item__value"
                  style={{ textAlign: "right" }}
                />
              </div>
            </Row>
          </Row>
        ) : (
          <Column className="nft-item__small-space">
            <CountUpNumber
              className="liquidity-item__value"
              startValue={staked}
              end={staked}
              decimals={0}
            />
          </Column>
        )}
        {isMobileViewEnabled ? (
          <Row className="liquidity-item__big-space">
            <Row style={{ width: "100%" }}>
              <div className="nft-item__small-space">
                <Text text="Earn" className="liquidity-item__value" />
              </div>
              <div className="liquidity-item__dashline" />
              <div className="nft-item__small-space">
                <Text
                  text="TANG"
                  className="liquidity-item__value"
                  style={{ textAlign: "right", whiteSpace: "nowrap" }}
                />
              </div>
            </Row>
          </Row>
        ) : (
          <div className="nft-item__small-space">
            <Text text="TANG" className="liquidity-item__value" />
          </div>
        )}

        <Row className="nft-item__small-space">
          <img
            onClick={expand}
            className="liquidity-item__expand"
            src={arrowImg}
            onMouseOver={() => onArrowHover()}
            onMouseOut={() => onArrowOut()}
            alt="Arrow down"
          />
        </Row>
      </Row>
      {itemIsExpanded && (
        <ExpandedNftItem
          edition={parts[1]}
          name={delimiter}
          APY={APY}
          pool={0}
          currentLpNftFarmContractName={currentLpNftFarmContractName}
          rewards={userRewards[poolId] ?? poolRewards}
          minutesUntilPoolIsClosing={minutesUntilPoolIsClosing}
          closesAt={closesAt}
          tokenContract={tokenContract}
          getToken={getToken}
          staked={staked}
          poolId={poolId}
          stakedTokens={stakedTokens}
          farmContractName={farmContractName}
          distributionWallet={distributionWallet}
          createNotification={createNotification}
          stake={(setIsLoading) => {
            // createNotification("error", "NFT farm will be open soon", 4000);
            // return;
            if (adaWallet) {
              openModal(
                "nftStaking",
                `${title} NFT`,
                'NFT',
                userNfts.map((item) => item).join(","),
                stakingWallet,
                userNfts.length,
                poolId,
                distributionWallet,
                setIsLoading
              );
            } else {
              createNotification("error", "Please connect your wallet", 4000);
            }
            // createNotification(
            //   "error",
            //   "NFT staking is currently not supported",
            //   4000
            // );
          }}
          unstake={(setIsLoading) => {
            // createNotification("error", "NFT farm will be open soon", 4000);
            // return;
            if (adaWallet) {
              openModal(
                "nftUnstaking",
                `${title} NFT`,
                'NFT',
                'stakingUnit',
                stakingWallet,
                staked,
                poolId,
                distributionWallet,
                setIsLoading
              );
            } else {
              createNotification("error", "Please connect your wallet", 4000);
            }  
          }}
          hash={hash}
          claim={() => {
            //createNotification("error", "NFT farm will be open soon", 4000);
            return;
          }}
          isMobileViewEnabled={isMobileViewEnabled}
        />
      )}
    </Column>
  );
};


const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    userStakedTokens: state.pool.userStakedTokens,
    stakedTokens: state.pool.stakedTokens,
    assetsIds: state.user.assetsIds,
    userRewards: state.pool.userRewards,
    tangentAmount: state.user.tangentAmount,
  };
};

export default connect(mapStateToProps, { createNotification })(NftItem);
