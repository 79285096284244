import React from "react";

const WalletModalButton = ({ onClick, style, text, children, isSelected }) => {
  return (
    <div
      onClick={onClick}
      className={`wallet-button ${isSelected ? "selected" : ""}`}
      style={style}
    >
      {children ? children : text}
    </div>
  );
};

export default WalletModalButton;
