import React, { useEffect, useState } from "react";
import { Box, Row, Column, InfoSection, Header, H1 } from "../../components";
import Button from "../../components/Button/Button";
import {
  createNotification,
  startAction,
  stopAction,
  
} from "store/actions/uiActions";
import { mockTokenInfo } from "../../utils/mockInfo";
import { connect } from "react-redux";
import { useWindowSize } from "utils/windowSizeHook";
import searchIcon from "../../assets/search.svg";
import cardSelected from "../../assets/card_selected.svg"
import gridSelected from "../../assets/3grid_selected.svg"
import gridUnselected from "../../assets/3grid_unselected.svg"
import listUnselected from "../../assets/list_unselected.svg"
import cardUnselected from "../../assets/card_unselected.svg"
import LoadLogo from "assets/tang_logo_animated.svg";
import listSelected from "../../assets/list_selected.svg"
import { Modal } from "../../components/Modals/Modal/Modal";
import Swap from '@dexhunterio/swaps'

import {
  setPoolsStakedTokens,
  setUsersRewards,
  setUsersStakedTokens,
} from "store/actions/poolActions";
import { getPools } from "utils/ada/getPoolData";
import { addWallets } from "utils/ada/addWallets";
import { evaluate } from 'mathjs';
import '@dexhunterio/swaps/lib/assets/style.css'

const SwapPage = ({
  adaWallet,
  tangentAmount,
  setUsersStakedTokens,
  setUsersRewards,
  stakedTokens,
  setPoolsStakedTokens,
  network,
  poolsData,
}) => {
    const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState();
  const [modalEarn, setModalEarn] = useState();
  const [tokenInfo, setTokenInfo] = useState();
  const [allTokenInfo, setAllTokenInfo] = useState();
  const [modalStakingUnit, setModalStakingUnit] = useState();
  const [modalStakingWallet, setModalStakingWallet] = useState();
  const [modalDistributionWallet, setModalDistributionWallet] = useState();
  const [stakingPoolId, setStakingPoolId] = useState();
  const [tokenDecimal, setTokenDecimal] = useState();
  const [amountOfStakedTokens, setAmountOfStakedTokens] = useState(1);
  const [setIsLoading, setSetIsLoading] = useState(false);
  const [isCardViewMode, setIsCardViewMode] = useState(true);

  const onOpenModal = (
    name,
    title,
    earn,
    stakingUnit,
    stakingWallet,
    stakedTokens,
    poolId,
    distributionWallet,
    setIsLoading,
    decimal
  ) => {
    setModalType(name);
    setOpen(true);
    setModalTitle(title);
    setModalEarn(earn);
    setSetIsLoading(() => setIsLoading);
    setModalStakingUnit(stakingUnit);
    setModalStakingWallet(stakingWallet);
    setAmountOfStakedTokens(stakedTokens);
    setStakingPoolId(poolId);
    setModalDistributionWallet(distributionWallet);
    setTokenDecimal(decimal);

    // "staking",
    // title,
    // earn,
    // stakingUnit,
    // stakingBalanceName,
    // rewardsBalanceName
  };

  const mobileStyle = {width: "100%", display: "block"};

  const [viewType, setViewType] = useState('card'); // Initial view type

  const handleViewChange = (newViewType) => {
    setIsCardViewMode(newViewType == "card")
    setViewType(newViewType);
  };

  useEffect(() => {
    if (adaWallet) {
      setUsersRewards(network == "pre");
      setUsersStakedTokens(network == "pre");
      addWallets(adaWallet, poolsData, network == "pre");
    }
    const interval = setInterval(() => {
      if (adaWallet) {
        setUsersRewards(network == "pre");
        setUsersStakedTokens(network == "pre");
        addWallets(adaWallet, poolsData, network == "pre");
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [adaWallet, setUsersStakedTokens, setUsersRewards]);

  useEffect(() => {
    setPoolsStakedTokens(network == "pre");
    const interval = setInterval(() => {
      setPoolsStakedTokens(network == "pre");
    }, 10000);
    return () => clearInterval(interval);
  }, [setPoolsStakedTokens]);

  useEffect(() => {
    getPools(mockTokenInfo, setAllTokenInfo, setTokenInfo, network == "pre");
    console.log(mockTokenInfo);
  }, []);

  const onCloseModal = () => {
    setOpen(false);
  };

  const [imageClass, setImageClass] = useState('pool-container');

  const handleImageClick = () => {
    // Add 'new-class' to the image when it's clicked
    setImageClass(`pool-container-3`);
};
  const onSearchItem = (val) => {
    if (val == "") {
      setTokenInfo(allTokenInfo);
      return;
    }
    const result = allTokenInfo.filter((item) => {
      return (
        item && item.name && item.name.toLowerCase().includes(val.toLowerCase())
      );
    });
    setTokenInfo(result);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseModal}
        modalType={modalType}
        modalTitle={modalTitle}
        modalEarn={modalEarn}
        modalStakingUnit={modalStakingUnit}
        modalStakingWallet={modalStakingWallet}
        modalDistributionWallet={modalDistributionWallet}
        amountOfStakedTokens={amountOfStakedTokens}
        poolId={stakingPoolId}
        isLoading={setIsLoading}
        tokenDecimal={tokenDecimal}
      />
      <div className="farms" style={{    textAlign: "center"}}>

     
      
        <Row className="farms__menu" style={{    alignItems: "normal"}}>
          <Column className="farms__menu-left" style={{width: viewType === "3grid" || windowSize[0] < 600 ? "100%" : "70%"}}>
            

<div style={{marginTop: "8%"}}>
<Swap

      orderTypes={["SWAP","LIMIT"]}
      colors={{"background":"#202434","containers":"#303444","subText":"#808484","mainText":"#FFFFFF","buttonText":"#FFFFFF","accent":"#F83472"}}
      theme="dark"
      selectedWallet={adaWallet}
      
      width="450"
      partnerCode="tangent26164647231713964347364756d6539736e386b666d6e65733037727a766a34356c3464706d6a7a76756b6d373330706733757079786a3768777078753574676874307a71757561707867756771707a68686733656a39786b6b396b663061727071393267366134da39a3ee5e6b4b0d3255bfef95601890afd80709"
      partnerName="Tangent2"
    />
</div>
         

       

          
            


          </Column>

          {windowSize[0] < 900 || viewType === '3grid' ? null : <InfoSection />}
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    tangentAmount: state.user.tangentAmount,
    stakedTokens: state.pool.stakedTokens,
    network: state.network.network,
    poolsData: state.pool.poolsData,
  };
};

export default connect(mapStateToProps, {
  createNotification,
  stopAction,
  startAction,
  setPoolsStakedTokens,
  setUsersRewards,
  setUsersStakedTokens,
})(SwapPage);


