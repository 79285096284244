import React, { useEffect, useState } from "react";
import { Box, Row, Column, InfoSection, Header, H1 } from "../../components";
import Button from "../../components/Button/Button";
import {
  createNotification,
  startAction,
  stopAction,
} from "store/actions/uiActions";
import { Link, useHistory } from "react-router-dom";
import { mockTokenInfo } from "../../utils/mockInfo";
import { LiquidityTopics, LiquidityItem } from "./components";
import { connect } from "react-redux";
import { useWindowSize } from "utils/windowSizeHook";
import searchIcon from "../../assets/search.svg";
import cardSelected from "../../assets/card_selected.svg"
import gridSelected from "../../assets/3grid_selected.svg"
import gridUnselected from "../../assets/3grid_unselected.svg"
import listUnselected from "../../assets/list_unselected.svg"
import cardUnselected from "../../assets/card_unselected.svg"
import LoadLogo from "assets/tang_logo_animated.svg";
import listSelected from "../../assets/list_selected.svg"
import LiquidityCardItem from "./components/LiquidityCardItem"
import { Modal } from "../../components/Modals/Modal/Modal";
import {
  setPoolsStakedTokens,
  setUsersRewards,
  setUsersStakedTokens,
} from "store/actions/poolActions";
import { getPools } from "utils/ada/getPoolData";
import { addWallets } from "utils/ada/addWallets";
import { evaluate } from 'mathjs';


const Farms = ({
  adaWallet,
  tangentAmount,
  setUsersStakedTokens,
  setUsersRewards,
  stakedTokens,
  setPoolsStakedTokens,
  network,
  poolsData,
  createNotification

}) => {
  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState();
  const [modalEarn, setModalEarn] = useState();
  const [tokenInfo, setTokenInfo] = useState();
  const [allTokenInfo, setAllTokenInfo] = useState();
  const [modalStakingUnit, setModalStakingUnit] = useState();
  const [modalStakingWallet, setModalStakingWallet] = useState();
  const [modalDistributionWallet, setModalDistributionWallet] = useState();
  const [stakingPoolId, setStakingPoolId] = useState();
  const [tokenDecimal, setTokenDecimal] = useState();
  const [amountOfStakedTokens, setAmountOfStakedTokens] = useState(1);
  const [setIsLoading, setSetIsLoading] = useState(false);
  const [isCardViewMode, setIsCardViewMode] = useState(true);

  const onOpenModal = (
    name,
    title,
    earn,
    stakingUnit,
    stakingWallet,
    stakedTokens,
    poolId,
    distributionWallet,
    setIsLoading,
    decimal
  ) => {
    setModalType(name);
    setOpen(true);
    setModalTitle(title);
    setModalEarn(earn);
    setSetIsLoading(() => setIsLoading);
    setModalStakingUnit(stakingUnit);
    setModalStakingWallet(stakingWallet);
    setAmountOfStakedTokens(stakedTokens);
    setStakingPoolId(poolId);
    setModalDistributionWallet(distributionWallet);
    setTokenDecimal(decimal);

    // "staking",
    // title,
    // earn,
    // stakingUnit,
    // stakingBalanceName,
    // rewardsBalanceName
  };

  const history = useHistory();

  const mobileStyle = {width: "100%", display: "block"};

  const [viewType, setViewType] = useState('card'); // Initial view type

  const handleViewChange = (newViewType) => {
    setIsCardViewMode(newViewType == "card")
    setViewType(newViewType);
  };

  const navigateToCreatePool = () => {
    history.push('/create-pool');
    // createNotification(
    //   "success",
    //   "Pool Creation Page is comming soon! ",
    //   3000
    // );
  };


  useEffect(() => {
    if (adaWallet) {
      setUsersRewards(network == "pre");
      setUsersStakedTokens(network == "pre");
      addWallets(adaWallet, poolsData, network == "pre");
    }
    const interval = setInterval(() => {
      if (adaWallet) {
        setUsersRewards(network == "pre");
        setUsersStakedTokens(network == "pre");
        addWallets(adaWallet, poolsData, network == "pre");
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [adaWallet, setUsersStakedTokens, setUsersRewards]);

  useEffect(() => {
    setPoolsStakedTokens(network == "pre");
    const interval = setInterval(() => {
      setPoolsStakedTokens(network == "pre");
    }, 10000);
    return () => clearInterval(interval);
  }, [setPoolsStakedTokens]);

  useEffect(() => {
    getPools(mockTokenInfo, setAllTokenInfo, setTokenInfo, network == "pre");
    console.log(mockTokenInfo);
  }, []);

  const onCloseModal = () => {
    setOpen(false);
  };

const [imageClass, setImageClass] = useState('pool-container');

  const handleImageClick = () => {
    // Add 'new-class' to the image when it's clicked
    setImageClass(`pool-container-3`);
};
  const onSearchItem = (val) => {
    if (val == "") {
      setTokenInfo(allTokenInfo);
      return;
    }
    const result = allTokenInfo.filter((item) => {
      return (
        item && item.name && item.name.toLowerCase().includes(val.toLowerCase())
      );
    });
    setTokenInfo(result);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseModal}
        modalType={modalType}
        modalTitle={modalTitle}
        modalEarn={modalEarn}
        modalStakingUnit={modalStakingUnit}
        modalStakingWallet={modalStakingWallet}
        modalDistributionWallet={modalDistributionWallet}
        amountOfStakedTokens={amountOfStakedTokens}
        poolId={stakingPoolId}
        isLoading={setIsLoading}
        tokenDecimal={tokenDecimal}
      />
      <div className="farms">
      <div className="fab" onClick={navigateToCreatePool}>
    <i className="fa fa-plus"></i>
    <span className="fab-text">Create Pool</span> {/* Text to show on hover */}
</div>

        <Row className="farms__menu" style={{    alignItems: "normal"}}>
          <Column className="farms__menu-left" style={{width: viewType === "3grid" || windowSize[0] < 600 ? "100%" : "70%"}}>
            {/* {windowSize[0] <= 1200 ? ( */}
            <Row className="farms__menu-left__search-collectible">
              <H1
                text="Token Farms"
                className="farms__menu-left__search-collectible__title"
              />
            </Row>
            {/* ) : null} */}

            {windowSize[0] < 900 ? (
              <Button
                className="detail-button"
                style={{
                  background: "rgba(255, 255, 255, 0.0001)",
                  border: "1px solid rgba(255, 255, 255, 0.4)",
                }}
                onClick={() => onOpenModal("info")}
                text={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    Details
                  </div>
                }
              />
            ) : null}
            <Row style={{ position: "relative", justifyContent: "start" }}>
              <input
                placeholder="Search"
                className="search"
                type="text"
                onChange={(evt) => onSearchItem(evt.target.value)}
              />
              {!windowSize[0] > 900 ? (
                <img
                  onClick={() => onSearchItem()}
                  className="search__icon"
                  src={searchIcon}
                  alt="Search"
                />

              ) : null}

              {windowSize[0] > 900 ? (

                  <Row >
                  <img
                    onClick={() => handleViewChange('table')}
                    className="view__icon"
                    style={{ cursor: 'pointer', width: 40 }}
                    src={viewType == "table" ?  listSelected: listUnselected}
                    alt="Search"
                  />
                  <img
                    onClick={() => handleViewChange('card')}
                    className="view__icon2"
                    style={{ cursor: 'pointer', width: 40 }}
                    src={viewType == "card" ? cardSelected : cardUnselected}
                    alt="Search"
                  />
                  <img
                    onClick={() => handleViewChange('3grid')}
                    className="view__icon3"
                    style={{ cursor: 'pointer', width: 40 }}
                    src={viewType == "3grid" ? gridSelected : gridUnselected}
                    alt="Search"
                  />

                  </Row>
              ) : null}

            </Row>

            {/* <button onClick={() => handleViewChange('together')}>Together View</button>
      <button onClick={() => handleViewChange('tswo')}>Tswo View</button> */}

            {!tokenInfo ? (
              // <div>
              //   <div className="shimmer-row">
              //     <div className="shimmer-line"></div>
              //     <div className="shimmer-line"></div>
              //   </div>
              //   <div className="shimmer-row">
              //     <div className="shimmer-line"></div>
              //     <div className="shimmer-line"></div>
              //   </div>
              // </div>
              <Box className="box-big" style={{ backgroundColor: "transparent", marginTop: 30, textAlign: "center" }}>
                <img className="loadLogo" src={LoadLogo} alt="Loading" style={{    display: "inline"}} />
              </Box>
            ) : (
              <>
                {viewType === 'table' && (
                  <>
                    {windowSize[0] < 900 ? null : <LiquidityTopics />}

                    <Box className="box-big">

                      {tokenInfo?.map((item, index) => (
                        <LiquidityItem
                          icon={item.icon}
                          poolId={item.id}
                          stakingWallet={item.stakingWallet}
                          stakingUnit={item.stakingUnit}
                          openModal={onOpenModal}
                          distributionWallet={item.distributionWallet}
                          earn={item.reward}
                          key={item.id}
                          poolRewards={item.rewards}
                          // APY={item.APY}
                          APY={evaluate(item.apyFormula, { tokens_staked: item.staked })}
                          title={item.name}
                          tokenContract={item.tokenContract}
                          getToken={item.getToken}
                          stakedTokens={item.staked}
                          farm={item.farm}
                          decimal={item.stakingUnitDecimals}
                          apyBalanceName={item.apyBalanceName}
                          farmContractName={item.farmContractName}
                          tokenContractName={item.tokenContractName}
                          stakingBalanceName={item.stakingBalanceName}
                          rewardsBalanceName={item.rewardsBalanceName}
                          userTokenBalanceName={item.userTokenBalanceName}
                          minutesUntilPoolIsClosing={item.minutesUntilPoolIsClosing}
                          closesAt={item.closesAt}
                          windowSize={windowSize}
                          style={{
                            ...(tokenInfo.length - 1 === index && {
                              borderBottom: "none",
                            }),
                          }}
                        />
                      ))}
                    </Box>
                  </>
                )}

{['card', '3grid'].includes(viewType) && (
                  <>
                    <div className={`pool-container${viewType == "3grid" ? "-3" : ""}`} style={  windowSize[0] < 600 ? mobileStyle : null}>
                      {tokenInfo?.map((item, index) => (
                        <LiquidityCardItem
                          key={item.id}
                          image={item.icon}
                          title={item.name}
                          poolId={item.id}
                          closesAt={item.closesAt}
                          stakingWallet={item.stakingWallet}
                          stakingUnit={item.stakingUnit}
                          openModal={onOpenModal}
                          distributionWallet={item.distributionWallet}
                          earn={item.reward}
                          poolRewards={item.rewards}
                          rewards={item.rewards}
                          APY={evaluate(item.apyFormula, { tokens_staked: item.staked })}
                          // APY={item.APY}
                          tokenContract={item.tokenContract}
                          getToken={item.getToken}
                          stakedTokens={item.staked}
                          farm={item.farm}
                          decimal={item.stakingUnitDecimals}
                          apyBalanceName={item.apyBalanceName}
                        />
                      ))}
                    </div>
                  </>
                )}

              </>
            )}


          </Column>

          {windowSize[0] < 900 || viewType === '3grid' ? null : <InfoSection />}
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    tangentAmount: state.user.tangentAmount,
    stakedTokens: state.pool.stakedTokens,
    network: state.network.network,
    poolsData: state.pool.poolsData,
  };
};

export default connect(mapStateToProps, {
  createNotification,
  stopAction,
  startAction,
  setPoolsStakedTokens,
  setUsersRewards,
  setUsersStakedTokens,
})(Farms);


