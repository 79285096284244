import React, { useEffect, useState } from "react";
import { Row, Box, Column, WalletModalButton } from "../../index";
import { connect } from "react-redux";
import closeIcon from "../../../assets/wallets/x.png";
import logo from "../../../assets/logo/logo.svg";
import exportIcon from "../../../assets/wallets/export.svg";
import infoIcon from "../../../assets/wallets/info.svg";

import {
  createNotification,
  setError,
  setWalletModal,
  startAction,
  stopAction,
} from "../../../store/actions/uiActions";
import namiIcon from "../../../assets/wallets/nami.svg";
import flintIcon from "../../../assets/wallets/flint.svg";
import NufiIcon from "../../../assets/wallets/nufi_trans.png";
import eternlIcon from "../../../assets/wallets/eternl_trans.png";
import Button from "../../Button/Button";
import { Loader } from "../../../components";
import { Modal as ReactResponsiveModal } from "react-responsive-modal";
import { setAdaUser } from "../../../store/actions/userActions";
import {
  checkIfWalletEnabled,
  enableWallet,
  pollWallets,
} from "../../../utils/ada/connectors";
import { getUserWallet } from "utils/ada/lucid";
import { addWallets } from "utils/ada/addWallets";
import { Text, LazyImage } from "components";

const EthWalletModal = ({
  setWalletModal,
  createNotification,
  setAdaUser,
  startAction,
  stopAction,
  loading,
  network,
  walletModal,
  poolsData,
}) => {
  const [availableAdaWallets, setAvailableAdaWallets] = useState([]);
  const [enabledWallets, setEnabledWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState();
  const [checkboxIsSelected, setCheckboxIsSelected] = useState(false);
  const [checkboxHasError, setCheckboxHasError] = useState(false);

  const walletIcons = {
    nami: namiIcon,
    nufi: NufiIcon,
    eternl: eternlIcon,
    flint: flintIcon,
    // Add more wallet icons as needed
  };

  useEffect(() => {
    pollWallets(0, setAvailableAdaWallets);
    const wallets = [];
    for (const key in window.cardano) {
      if (window.cardano[key].enable && wallets.indexOf(key) === -1) {
        wallets.push(key);
      }
    }
    console.log(wallets);

    const allWallets = ["nami", "nufi", "eternl", "flint"];
    //setEnabledWallets(allWallets);
    const filteredWallets = wallets.filter((wallet) =>
      allWallets.includes(wallet)
    );
    console.log(filteredWallets);
    setEnabledWallets(filteredWallets);
  }, []);

  console.log(enabledWallets);

  const connectAdaWallet = async (walletKey) => {
    if (!availableAdaWallets.includes(walletKey)) {
      createNotification(
        "error",
        `We couldn't find this wallet in your current browser`,
        4000
      );
      return;
    }

    try {
      startAction();
      const walletProvider = await enableWallet(walletKey);

      if (walletProvider && (await checkIfWalletEnabled(walletKey))) {
        setAdaUser(null, null, walletKey);
        const signerAddress = await getUserWallet(network === "pre");
       // await addWallets(signerAddress, poolsData, network === "pre");
        setAdaUser(signerAddress, null, walletKey);
        setWalletModal(false);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      stopAction();
    }
  };

  return (
    <ReactResponsiveModal
      center
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
      }}
      open={walletModal}
      onClose={() => setWalletModal(false)}
      //closeIcon={closeIcon}
    >
      <Box className="wallet-modal">
        {/* {loading ? (
          <div style={{ width: "20%" }}>
            <Loader loadState={loading} />
          </div>
        ) : ( */}
          <>
            {enabledWallets.length > 0 ? (
              <>
                <Row
                  className="wallet-modal__header"
                  style={{ display: "block" }}
                >
                  {/* <img
                    onClick={() => setWalletModal(false)}
                    className="wallet-modal__close"
                    src={closeIcon}
                    alt="close-icon"
                  /> */}

                  <Column>
                    <Text
                      style={{
                        "text-align": "center",
                        color: "#FFF",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 27,
                        "font-style": "normal",
                        "font-weight": 600,
                        "line-height": "normal",
                        marginTop: 10,
                        marginBottom: 8,
                      }}
                    >
                     Available Wallets
                    </Text>
                    {/* <Text
                      style={{
                        "text-align": "center",
                        color: "#f9f9f977",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 14,
                        "font-style": "normal",
                        "font-weight": 400,
                        "line-height": "normal",
                        marginBottom: 20,
                      }}
                    >
                     Select a wallet below to connect
                    </Text> */}
                  </Column>
                </Row>
                <Column center className="wallet-column">
                  <div class="grid-container">
                    {enabledWallets.map((walletName) => (
                      <div style={{ aspectRatio: 1 }}>
                        <WalletModalButton
                          key={walletName}
                          isSelected={selectedWallet == walletName}
                          style={{
                            padding: 30,
                            paddingLeft: 45,
                            paddingRight: 45,
                          }}
                          onClick={() => setSelectedWallet(walletName)} //connectAdaWallet(walletName)}
                          className="grid-item"
                        >
                          <Column>
                            <LazyImage
                              className="wallet-img"
                              src={walletIcons[walletName]}
                              alt={`${walletName} connect`}
                            />
                            <Text
                              style={{
                                marginTop: 3,
                                color: "#fff",
                                "text-transform": "capitalize",
                              }}
                            >
                              {walletName}
                            </Text>
                          </Column>
                        </WalletModalButton>
                      </div>
                    ))}
                  </div>
                  {/* <Row
                   
                    style={{
                      minWidth: 500,
                      borderRadius: 3,
                      backgroundColor: "#ffffff0f",
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 12,
                      paddingBottom: 10,
                      border: checkboxHasError ? "1.5px solid #f83472" : "",
                      width: "90%",
                    }}
                  >
                    <label
                      class="checkbox-container"
                     
                    >
                     
                      <input type="checkbox" name='thing1'  checked={checkboxIsSelected}
          onChange={() => {
            setCheckboxIsSelected(!checkboxIsSelected);
          }}  id="thing1"/>

                      <span class="checkmark" style={{ top: 8 }}></span>
                      <p
                        style={{
                          "font-size": 14,
                          color: "#fff",
                          fontWeight: 200,
                          paddingTop: 5,
                        }}
                      >
                        I agree to the
                        <span style={{ color: "#f83472" }}> Disclaimer</span>,
                        <span style={{ color: "#f83472" }}> Terms </span>
                        and
                        <span style={{ color: "#f83472" }}>
                          {" "}
                          Privacy Policy{" "}
                        </span>
                      </p>
                    </label>
                  </Row>
                  <Row
                    style={{ marginTop: "15px", width: "90%", minWidth: 500 }}
                  >
                    <a
                      className="expanded-liquidity-item__link"
                      href={""}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "#A3A8AC",
                        "text-decoration": "auto",
                        fontSize: 14,
                      }}
                    >
                      Learn More about Wallets
                    </a>
                    <img
                      src={exportIcon}
                      alt="Info"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <a
                      className="expanded-liquidity-item__link"
                      href={""}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "#A3A8AC",
                        "text-decoration": "auto",
                        marginLeft: 20,
                        fontSize: 14,
                      }}
                    >
                      Can’t find your wallet?
                    </a>
                    <img
                      src={exportIcon}
                      alt="Info"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Row> */}

                  <Row
                    style={{
                      marginTop: "5px",
                      width: "90%",
                      minWidth: 435,
                      opacity: selectedWallet == "eternl" ? 1 : 0,
                    }}
                  >
                    <img
                      src={infoIcon}
                      alt="Info"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: 10,
                      }}
                    />
                    <Text
                      style={{
                        color: "#A3A8AC",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 13,
                        "font-style": "normal",
                        "font-weight": 500,
                        "line-height": "normal",
                      }}
                    >
                      You must to turn on Single Address mode in settings
                    </Text>
                  </Row>
                  <Row
                    onClick={() => {
                      //setCheckboxHasError(!checkboxIsSelected);
                      if (selectedWallet == null) {
                        createNotification(
                          "error",
                          `Select a wallet first`,
                          2000
                        );
                        return;
                      }

                      connectAdaWallet(selectedWallet);
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "10px",
                      width: "95%",
                      padding: 7,
                      "text-align": "center",
                      display: "block",
                      borderRadius: 4,
                      opacity:
                        selectedWallet != null ? 1 : 0.5,
                      backgroundColor: "#f83472",
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 15,
                        "font-style": "normal",
                        "font-weight": 500,
                        "line-height": "normal",
                      }}
                    >
                      Connect Wallet
                    </Text>
                  </Row>
                  {/* <Text style={{ color: "white", fontSize: 14, marginTop: 20 }}>
                    *Must turn on Single Address mode in settings
                  </Text> */}
                </Column>
              </>
            ) : (
              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={logo} style={{ width: 125 }} alt="logo" />
                </Row>
                <Row className="wallet-modal__nowallet">
                  <Text
                    style={{
                      color: "white",
                      fontSize: 18,
                      padding: 25,
                      marginTop: 20,
                      textAign: "center",
                    }}
                  >
                    We’re sorry, but it appears that you don’t have any
                    supported wallets on your browser at the moment.
                  </Text>
                </Row>
              </>
            )}
          </>
        {/* )} */}
      </Box>
    </ReactResponsiveModal>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ui.loading,
    network: state.network.network,
    poolsData: state.pool.poolsData,
  };
};

export default connect(mapStateToProps, {
  setError,
  setWalletModal,
  createNotification,
  setAdaUser,
  startAction,
  stopAction,
})(EthWalletModal);
