import { Column, Text, H1, H3, Button, Row } from "components";
import { useState } from "react";
import { connect } from "react-redux";
import { createNotification } from "store/actions/uiActions";
import "./launchpad-terms.scss";

const Terms = ({ closeModal, forth, back, createNotification }) => {
  const [accepted, setAccepted] = useState(false);

  const onCheckboxChange = (event) => {
    if (event.target.id === "accepted") {
      setAccepted(event.target.checked);
    }
  };

  return (
    <Column className="launchpad-terms-modal">
      <Column className="launchpad-terms-text">
        <p style={{ fontSize: "15px", color: "#a3a8ac" }}>REEDEM</p>
        <H1 text="Terms of service agreement" />
        <H3
          text="1. The Sale of Alcoholic Beverages"
          className="launchpad-terms-subtitle"
        />
        <Text className="launchpad-terms-detail">
          The Company does not sell alcohol to persons under the age of 21. By 
          using this site you are representing that you are over the age of 21. 
          The Company makes every effort to ensure that alcoholic beverages are 
          not delivered to anyone who is under the age of 21. By using this site 
          you are representing that the person receiving a shipment of alcoholic 
          beverages from the Company is over the age of 21.
        </Text>
        <H3
          text="2. Eligible Countries for Direct Shipment"
          className="launchpad-terms-subtitle"
        />
        <Text className="launchpad-terms-detail">

          This list is current as of November, 2021. This list may change at any time,
          so please make sure that your country is eligible for shipment before redeeming. 
          It may be possible for other arrangements to occur. Please see point 5 below. 
          Shipping time could range and be around one month for receiving, based on your location.
          <br/>
          <br/>
          If your country is not listed below, please contact our service email to enquire before redeeming.
          <br/>
          <br/>
            -Argentina <br/>
            -Australia <br/>
            -Austria   <br/>
            -Bahamas   <br/>
            -Belgium   <br/>
            -Bermuda   <br/>
            -Brazil    <br/>
            -British Virgin Islands <br/>
            -Canada (Alberta, Ontario, British Columbia and Quebec only)   <br/>
            -Cayman Islands <br/>
            -Chile  <br/>
            -Costa Rica <br/>
            -Czech Republic <br/>
            -Denmark  <br/>
            -Dominican Republic <br/>
            -El Salvador  <br/>
            -Fiji <br/>
            -Finland  <br/>
            -France <br/>
            -Germany  <br/>
            -Gibraltar  <br/>        
            -Greece <br/>
            -Greenland  <br/>
            -Guatemala  <br/>
            -Hong Kong  <br/>
            -Hungary  <br/>
            -India  <br/>
            -Israel <br/>
            -Italy  <br/> 
            -Japan  <br/>
            -Liechtenstein  <br/>
            -Luxembourg <br/>
            -Macau  <br/>
            -Malta  <br/>
            -Martinique <br/>
            -Mexico <br/>
            -Monaco <br/> 
            -Montserrat <br/>
            -New Zealand  <br/>
            -Norway <br/>
            -Panama <br/>
            -Paraguay <br/>
            -Philippines  <br/>
            -Puerto Rico  <br/>
            -Saint Kitts and Nevis  <br/>
            -Saint Lucia  <br/>
            -Singapore  <br/>
            -Slovakia <br/>
            -Slovenia <br/>
            -South Africa <br/>
            -South Korea  <br/>
            -Spain  <br/>
            -Sweden <br/>
            -Switzerland  <br/>
            -The Netherlands  <br/>
            -Thailand <br/>
            -United Kingdom of Great Britain  <br/>
            -United States of America <br/>
            -Uruguay  <br/>
            -Vanuatu  <br/>
        </Text>

        <H3 text="3. Use of Site" />
        <Text className="launchpad-terms-detail">
          You may not distribute, retransmit, republish, reuse, re-post, or use 
          the contents of this website for public or commercial purposes,
          without the Company’s prior written permission. You may not alter or
          interfere with the content or functioning of the Website, or “mirror” 
          any content contained on this Website on any other server. 
          The materials on this Website are the intellectual property of Tangent and 
          any unauthorized use may violate copyright laws, trademark laws, the laws 
          of privacy and publicity, and communications regulations and statutes.
          <br />
          <br/>
          You are responsible for your use of the Website, and for any use of 
          the Website made using your account. Our goal is to create a positive, 
          rewarding, and safe experience in connection with our Website. To promote this goal, 
          we prohibit certain kinds of conduct that may be harmful to other users or to the Company.
          When you use the Website, you may not:
          <br />
          <br/>
          • Intentionally or unintentionally violate any law or regulation;
          <br />
          <br/>
          • violate, infringe, or misappropriate other people’s intellectual property, privacy,
            publicity, or other legal rights;
            <br />
            <br/>
          • post, share, send, or otherwise use the website to do anything that is illegal,
            abusive, harassing, harmful to reputation, pornographic, indecent, profane, 
            obscene, hateful, racist, or otherwise objectionable;
            <br />
            <br/>
          • transmit any viruses or other computer instructions or technological means
            whose purpose is to disrupt, damage, or interfere with the use
            of computers or related systems;
            <br />
            <br/>

          • use automated methods to use the Website nor use any means to scrape or
            crawl any Web pages or content contained in the Website;
            <br />
            <br/>

          • attempt to circumvent any technological measure implemented by the 
            Company or any of the Company’s providers or any other third party
            (including another user) to protect the Website;
            <br />
            <br/>

          • attempt to decipher, decompile, disassemble, or reverse 
            engineer any of the software used to provide the Website; or
            <br />
            <br/>

          • advocate, encourage, or assist any third party in
            doing any of the foregoing.      
        </Text>
        <H3 text="4. Receiving Merchandise and Passage of Title" />
        <Text className="launchpad-terms-detail">
          Laws regarding the sale and transportation of alcoholic beverages
          are complex and constantly changing. The politics, policies and 
          procedures regarding such sales and transportation are 
          different in every state and country.
          <br />
          <br/>
          By utilizing this service from TANGENT you are representing
          that you are acting in compliance with your local and state 
          laws regarding the purchase, transportation and delivery of 
          alcoholic beverages. You further represent that you have
          obtained any required permission, paid any required fees, 
          are working through properly licensed intermediaries where 
          required, are legally entitled to take possession of the 
          alcoholic beverages, are legally entitled to take 
          quantities ordered and that you and the person accepting
          delivery of the alcoholic beverage products are at least 21 years of age.
          <br/>
          <br/>
          There are no refunds once token redemption has been activated. 
          If your product has arrived damaged or goes missing, 
          please contact our service email for assistance.
          </Text>
          <br/>
          <br/>
          <H3 text="5. Receiving product from TANGENT" />
          <Text className="launchpad-terms-detail">
          There are three ways to receive your product from TANGENT:
          <br/>
          1. Your product may be shipped to you if your state or 
             country allows for it.        
          <br/>          
          2. You may arrange for third party acquisition through a 
             wine/alcohol vendor or courier.
          <br/>    
          3. You may store your product with TANGENT for 1 year in our cellars
            in California as you arrange for shipment/receiving. After 1 year
            to the day of redemption, the bottle will be forfeited.
          </Text>
          <br/>
          <br/>
          <H3 text="6. Disclaimers of Warranties" />
          <Text className="launchpad-terms-detail">
          
          THE MATERIAL CONTAINED IN THIS WEBSITE HAS BEEN CHECKED
          FOR ACCURACY; HOWEVER, THERE MAY BE INADVERTENT TECHNICAL 
          OR FACTUAL INACCURACIES AND TYPOGRAPHICAL ERRORS. THE COMPANY 
          EXPRESSLY DISCLAIMS ALL LIABILITY FOR ERRORS OR OMISSIONS IN, 
          OR THE MISUSE OR MISINTERPRETATION OF, ANY INFORMATION CONTAINED
          ON THIS WEBSITE. INFORMATION PUBLISHED ON THIS WEBSITE
          MAY REFER TO PRODUCTS, PROGRAMS OR SERVICES THAT ARE NOT
          AVAILABLE IN YOUR STATE OR COUNTRY. WITHOUT LIMITING THE 
          FOREGOING, THE WEBSITE IS PROVIDED “AS IS” AND 
          WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OF IMPLIED, 
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF 
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
         
          </Text>
          <br/>
          <br/>
          <H3 text="7. Copyrights, Trademarks and other Proprietary Rights" />
          <Text className="launchpad-terms-detail">
          
          The contents of this Website, such as trademarks, designs,
          text, logos, and icons, or other intellectual property 
          are owned by and proprietary to the Company. You may not display,
          reproduce, distribute, modify, transmit, or otherwise use 
          the contents of the Website in any way for any public or 
          commercial purposes, without the Company’s written permission.
          You are authorized to use this material only 
          for personal, non-commercial purposes.
         
          </Text>
          <br/>
          <br/>
          <H3 text="8. Links" />
          <Text className="launchpad-terms-detail">
          
          As a convenience to you, our Website may display
          links to other sites which are not maintained by the Company.
          The Company is not responsible or liable for the content 
          or operation of third-party sites, and the presence of 
          such links does not imply that the Company is 
          affiliated with such sites or third parties. We try to 
          include only links to those sites which are in good 
          taste and safe for our visitors.
         
          </Text>
          <br/>
          <br/>
          <H3 text="9. Governing Law" />
          <Text className="launchpad-terms-detail">
          
          This Agreement will be governed by and 
          interpreted in accordance with the laws of the State 
          of California without regard to any conflict of 
          laws principles.
         
          </Text>
          <br/>
          <br/>
          <H3 text="10. Terms of Service Agreement" />
          <Text className="launchpad-terms-detail">
         
          All TANGENT redeemers must sign the Terms of
          Service agreement to proceed with the redemption process.
         
          </Text>
          <br/>
          <br/>
    
      </Column>

      <Row
        style={{
          margin: "1.5rem",
        }}
      >
        <label className="checkbox-container">
          <input type="checkbox" id="accepted" onChange={onCheckboxChange} />
          <span className="checkmark"></span>
        </label>
        {/* <input
          className="checkbox-input"
          type="checkbox"
          id="accepted"
          onChange={onCheckboxChange}
          style={{
            marginRight: "1rem",
            backgroundColor: "transparent",
          }}
        /> */}
        <Text className="agree-text" text="I agree" />
      </Row>
      {accepted ? (
        <Button
          className="buy-button"
          onClick={() => {
            forth();
          }}
        >
          Continue
        </Button>
      ) : (
        <Button className="buy-button__disable">Continue</Button>
      )}
    </Column>
  );
};

export default connect(null, { createNotification })(Terms);

// 4. Receiving Merchandise and Passage of Title
// Laws regarding the sale and transportation of alcoholic beverages are complex and constantly changing. The politics, policies and procedures regarding such sales and transportation are different in every state and country.

// By utilizing this service from TANGENT you are representing that you are acting in compliance with your local and state laws regarding the purchase, transportation and delivery of alcoholic beverages. You further represent that you have obtained any required permission, paid any required fees, are working through properly licensed intermediaries where required, are legally entitled to take possession of the alcoholic beverages, are legally entitled to take quantities ordered and that you and the person accepting delivery of the alcoholic beverage products are at least 21 years of age.

// There are no refunds once token redemption has been activated. If your product has arrived damaged or goes missing, please contact our service email for assistance.

// 5. Receiving product from TANGENT
// There are three ways to receive your product from TANGENT:
// 1. Your product may be shipped to you if your state or country allows for it.
// 2. You may arrange for third party acquisition through a wine/alcohol vendor or courier.
// 3. You may store your product with TANGENT for 1 year in our cellars in California as you arrange for shipment/receiving. After 1 year to the day of redemption, the bottle will be forfeited.

// 6. Disclaimers of Warranties
// THE MATERIAL CONTAINED IN THIS WEBSITE HAS BEEN CHECKED FOR ACCURACY; HOWEVER, THERE MAY BE INADVERTENT TECHNICAL OR FACTUAL INACCURACIES AND TYPOGRAPHICAL ERRORS. THE COMPANY EXPRESSLY DISCLAIMS ALL LIABILITY FOR ERRORS OR OMISSIONS IN, OR THE MISUSE OR MISINTERPRETATION OF, ANY INFORMATION CONTAINED ON THIS WEBSITE. INFORMATION PUBLISHED ON THIS WEBSITE MAY REFER TO PRODUCTS, PROGRAMS OR SERVICES THAT ARE NOT AVAILABLE IN YOUR STATE OR COUNTRY. WITHOUT LIMITING THE FOREGOING, THE WEBSITE IS PROVIDED “AS IS” AND WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OF IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.

// 7. Copyrights, Trademarks and other Proprietary Rights
// The contents of this Website, such as trademarks, designs, text, logos, and icons, or other intellectual property are owned by and proprietary to the Company. You may not display, reproduce, distribute, modify, transmit, or otherwise use the contents of the Website in any way for any public or commercial purposes, without the Company’s written permission. You are authorized to use this material only for personal, non-commercial purposes.

// 8. Links
// As a convenience to you, our Website may display links to other sites which are not maintained by the Company. The Company is not responsible or liable for the content or operation of third-party sites, and the presence of such links does not imply that the Company is affiliated with such sites or third parties. We try to include only links to those sites which are in good taste and safe for our visitors.

// 9. Governing Law
// This Agreement will be governed by and interpreted in accordance with the laws of the State of California without regard to any conflict of laws principles.

// 10. Terms of Service Agreement
// All TANGENT redeemers must sign the Terms of Service agreement to proceed with the redemption process.
