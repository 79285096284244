import * as types from "../actionTypes/userActionTypes";

const initialState = {
  signerAddress: false,
  tokenBalance: false,
  ethBalance: false,
  transactions: false,
  ethSentTotal: false,
  nltReceivedTotal: false,
  ethPrice: false,
  closingTime: false,
  currentTime: false,
  ethRaised: false,
  adaRaised: 0,
  assetsIds: null,
  tangPrice: 0,
  cPiggyPrice: 0,
  adaWallet: false,
  tangentPurchased: 0,
  adaAmount: 0,
  tangentAmount: 0,
  userCredit: 0,
  elvAmount: 0,
  elvADAAmount: 0,
  bukeleAmount: 0,
  tangentADAAmount: 0,
  totalTangRewards: 0,
  totalELVRewards: 0,
  totalcPiggyRewards: 0,
  totalBukeleRewards: 0,
  userTokenAmount: null,
  claimedRewards: null,
  totalStaked: 0,
  nftAssets: null,
  orders: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ADA_USER:
      return {
        ...state,
        signerAddress: action.payload.signerAddress,
        tokenBalance: action.payload.tokenBalance,
        ethBalance: action.payload.adaBalance,
        closingTime: action.payload.closingTime,
        adaWallet: action.payload.adaWallet,
      };

    case types.SET_NFT_ASSETS:
      return {
        ...state,
        nftAssets: action.payload,
      };
    case types.SET_CPIGGY_PRICE:
      return {
        ...state,
        cPiggyPrice: action.payload,
      };
    case types.SET_ADA_RAISED:
      return {
        ...state,
        adaRaised: Number(state.adaRaised) + Number(action.payload.adaRaised),
        tangentPurchased:
          state.tangentPurchased + Number(action.payload.adaRaised),
      };
    case types.SET_USERS_CLAIMED_REWARDS:
      return {
        ...state,
        claimedRewards: action.payload,
      };

    case types.SET_ETH_RAISED:
      return {
        ...state,
        usdcRaised: action.payload.usdcRaised,
        tangentPurchased:
          state.tangentPurchased + Number(action.payload.tangentPurchased),
        adaRaised:
          Number(state.adaRaised) + Number(action.payload.adaValueOfUsdcRaised),
      };

    case types.SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload.transactions,
        ethSentTotal: action.payload.ethSentTotal,
        nltReceivedTotal: action.payload.nltReceivedTotal,
      };

    case types.SET_ETH_PRICE:
      return {
        ...state,
        ethPrice: action.payload,
      };

    case types.SET_TANG_PRICE:
      return {
        ...state,
        tangPrice: action.payload,
      };

    case types.UNSET_USER:
      return {
        ...state,
        signerAddress: false,
        tokenBalance: false,
        ethBalance: false,
        transactions: false,
        ethSentTotal: false,
        nltReceivedTotal: false,
        closingTime: false,
        currentTime: false,
      };

    case types.SET_DISCONNECTED_USER:
      return {
        ...state,
        ethRaised: action.payload.balances.weiRaised,
        closingTime: action.payload.closingTime,
      };

    case types.SET_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.payload,
      };

    case types.SET_USERS_CREDIT:
      return {
        ...state,
        userCredit: action.payload,
      };

    case types.SET_USERS_TOKEN:
      return {
        ...state,
        userTokenAmount: action.payload.userAmounts,
        assetsIds: action.payload.assetsIds,
      };

    case types.SET_USERS_TOTAL_REWARDS:
      return {
        ...state,
        totalTangRewards: action.payload.totalTangRewards,
        totalElvRewards: action.payload.totalElvRewards,
        totalcPiggyRewards: action.payload.totalcPiggyRewards,
        totalBukeleRewards: action.payload.totalBukeleRewards,
      };

    case types.SET_TOTAL_STAKED:
      return {
        ...state,
        totalStaked: action.payload,
      };

    default:
      return state;
  }
};
