import * as actionTypes from "../actionTypes/userActionTypes";
import axios from "axios";

export const loadAdaPrice = () => async (dispatch) => {
  try {
    const ethPriceResponse = await axios.get(
      `https://pro-api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=USD,EUR,GBP&x_cg_pro_api_key=CG-3a52etupLhiEC1vGv94wyBnH`
    );

    if (ethPriceResponse.data?.cardano) {
      dispatch({
        type: actionTypes.SET_ETH_PRICE,
        payload: ethPriceResponse.data?.cardano,
      });
    }
  } catch (error) {
    debugger;
    return;
  }
};
export const loadTangPrice = () => async (dispatch) => {
  try {
    const ethPriceResponse = await axios.get(
      `https://pro-api.coingecko.com/api/v3/simple/price?ids=tangent&vs_currencies=USD&x_cg_pro_api_key=CG-3a52etupLhiEC1vGv94wyBnH`
    );

    if (ethPriceResponse.data?.tangent) {
      dispatch({
        type: actionTypes.SET_TANG_PRICE,
        payload: ethPriceResponse.data?.tangent.usd,
      });
    }
  } catch (error) {
    debugger;
    return;
  }
};
export const loadcPiggyPrice = () => async (dispatch) => {
  try {
    const ethPriceResponse = await axios.get(
      `https://pro-api.coingecko.com/api/v3/simple/price?ids=cpiggy-bank-token&vs_currencies=USD&x_cg_pro_api_key=CG-3a52etupLhiEC1vGv94wyBnH`
    );

    if (ethPriceResponse.data["cpiggy-bank-token"]) {
      dispatch({
        type: actionTypes.SET_CPIGGY_PRICE,
        payload: ethPriceResponse.data["cpiggy-bank-token"].usd,
      });
    }
  } catch (error) {
    debugger;
    return;
  }
};

export const disconnectUserAccount = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.UNSET_USER,
  });
};

export const setAdaUser =
  (signerAddress, adaBalance, adaWallet) => async (dispatch) => {
    try {
      return dispatch({
        type: actionTypes.SET_ADA_USER,
        payload: {
          signerAddress,
          adaBalance: adaBalance / 1000000,
          adaWallet,
        },
      });
    } catch (error) {
      debugger;
      return;
    }
  };

export const setUsersToken = (userAmounts, assetsIds) => async (dispatch) => {
  try {
    return dispatch({
      type: actionTypes.SET_USERS_TOKEN,
      payload: {
        userAmounts,
        assetsIds,
      },
    });
  } catch (error) {
    debugger;
    return;
  }
};

export const setUserCredit = (creditAmount) => async (dispatch) => {
  try {
    return dispatch({
      type: actionTypes.SET_USERS_CREDIT,
      payload: creditAmount,
    });
  } catch (error) {
    debugger;
    return;
  }
};

export const setUserClaimedRewards = (rewards) => async (dispatch) => {
  try {
    return dispatch({
      type: actionTypes.SET_USERS_CLAIMED_REWARDS,
      payload: rewards,
    });
  } catch (error) {
    debugger;
    return;
  }
};

export const setUsersTotalRewards =
  (totalTangRewards, totalElvRewards, totalcPiggyRewards, totalBukeleRewards) =>
  async (dispatch) => {
    try {
      return dispatch({
        type: actionTypes.SET_USERS_TOTAL_REWARDS,
        payload: {
          totalTangRewards,
          totalElvRewards,
          totalcPiggyRewards,
          totalBukeleRewards,
        },
      });
    } catch (error) {
      debugger;
      return;
    }
  };

export const setTotalStaked = (totalStaked) => async (dispatch) => {
  try {
    return dispatch({
      type: actionTypes.SET_TOTAL_STAKED,
      payload: totalStaked,
    });
  } catch (error) {
    debugger;
    return;
  }
};

export const setNftAssets = (nftAssets) => async (dispatch) => {
  try {
    return dispatch({
      type: actionTypes.SET_NFT_ASSETS,
      payload: nftAssets,
    });
  } catch (error) {
    debugger;
    return;
  }
};
