export const SET_ADA_USER = "SET_ADA_USER";
export const SET_DISCONNECTED_USER = "SET_DISCONNECTED_USER";
export const UNSET_USER = "UNSET_USER";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_ETH_PRICE = "SET_ETH_PRICE";
export const SET_TANG_PRICE = "SET_TANG_PRICE";
export const SET_CPIGGY_PRICE = "SET_CPIGGY_PRICE";
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";
export const SET_ADA_RAISED = "SET_ADA_RAISED";
export const SET_ETH_RAISED = "SET_ETH_RAISED";
export const SET_USERS_TOKEN = "SET_USERS_TOKEN";
export const SET_USERS_CREDIT = "SET_USERS_CREDIT";
export const SET_USERS_CLAIMED_REWARDS = "SET_USERS_CLAIMED_REWARDS";
export const SET_USERS_TOTAL_REWARDS = "SET_USERS_TOTAL_REWARDS";
export const SET_TOTAL_STAKED = "SET_TOTAL_STAKED";
export const SET_NFT_ASSETS = "SET_NFT_ASSETS";
