import { Address, Value } from "@emurgo/cardano-serialization-lib-asmjs";

/**
 * Poll the wallets it can read from the browser.
 * Sometimes the html document loads before the browser initialized browser plugins (like Nami or Flint).
 * So we try to poll the wallets 3 times (with 1 second in between each try).
 * @param count The current try count.
 */
export const pollWallets = (count = 0, setAvailableAdaWallets) => {
  console.log(count, "count");
  const wallets = [];
  for (const key in window.cardano) {
    if (window.cardano[key].enable && wallets.indexOf(key) === -1) {
      wallets.push(key);
    }
  }
  if (wallets.length === 0 && count <= 4) {
    setTimeout(() => {
      pollWallets(count + 1, setAvailableAdaWallets);
    }, 1000);
    return;
  }
  setAvailableAdaWallets(wallets);
};

/**
 * Enables the wallet that was chosen by the user
 * When this executes the user should get a window pop-up
 * from the wallet asking to approve the connection
 * of this app to the wallet
 * @returns {Promise<boolean>}
 */

export const enableWallet = async (walletKey) => {
  try {
    const walletProvider = await window.cardano[walletKey].enable();
    return walletProvider;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Checks if a connection has been established with
 * the wallet
 * @returns {Promise<boolean>}
 */
export const checkIfWalletEnabled = async (walletKey) => {
  let walletIsEnabled = false;

  try {
    walletIsEnabled = await window.cardano[walletKey].isEnabled();
  } catch (err) {
    console.log(err);
  }

  return walletIsEnabled;
};

/**
 * Gets the Network ID to which the wallet is connected
 * 0 = testnet
 * 1 = mainnet
 * Then writes either 0 or 1 to state
 * @returns {Promise<void>}
 */
export const getNetworkId = async (provider) => {
  try {
    const networkId = await provider.getNetworkId();
    return networkId;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Gets the current balance of in Lovelace in the user's wallet
 * This doesnt resturn the amounts of all other Tokens
 * For other tokens you need to look into the full UTXO list
 * @returns {Promise<void>}
 */
export const getBalance = async (provider) => {
  try {
    const balanceCBORHex = await provider.getBalance();

    const balance = Value.from_bytes(Buffer.from(balanceCBORHex, "hex"))
      .coin()
      .to_str();
    return balance;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Get the address from the wallet into which any spare UTXO should be sent
 * as change when building transactions.
 * @returns {Promise<void>}
 */
export const getChangeAddress = async (provider) => {
  try {
    const raw = await provider.getChangeAddress();
    const changeAddress = Address.from_bytes(
      Buffer.from(raw, "hex")
    ).to_bech32();
    return changeAddress;
  } catch (err) {
    console.log(err);
  }
};
