import React from "react";
import LoadLogo from "assets/tang_logo_animated.svg";
import { connect } from "react-redux";
import "./earn.scss"
const Earn = () => {
  return (
    <div className="earnloader">
      <div className="earnloadContainer">
        <img className="loadLogo" src={LoadLogo} alt="Loading" />
        <p className="loadText">
          Coming Soon
          <span className="animText">
            <span style={{ "--i": 1 }}>.</span>
            <span style={{ "--i": 2 }}>.</span>
            <span style={{ "--i": 3 }}>.</span>
          </span>
        </p>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(Earn);
