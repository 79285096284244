import React, { useState } from "react";
import { Row, Text, Column } from "../../../components";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const DropdownMenu = ({ options, onOptionChanged }) => {
  const [menu, setMenu] = useState(10);

  const handleChange = (event) => {
    setMenu(event.target.value);
    onOptionChanged(event.target.value);
  };
  const OptionItem = ({ value, text }) => {
    const optionV = (value + 1) * 10;
    return (
      <MenuItem value={optionV}>
        <Row>{text}</Row>
      </MenuItem>
    );
  };
  return (
    <Box>
      <FormControl fullWidth variant="standard" size="small">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={menu}
          onChange={handleChange}
          style={{ fontSize: "15px", borderBottom: "1px solid #fff" }}
        >
          <MenuItem value={10}>
            <Row>{options[0]}</Row>
          </MenuItem>
          <MenuItem value={20}>
            <Row>{options[1]}</Row>
          </MenuItem>
          <MenuItem value={30}>
            <Row>{options[2]}</Row>
          </MenuItem>
          <MenuItem value={40}>
            <Row>{options[3]}</Row>
          </MenuItem>
          {/* {options?.map((item, index) => 
            <OptionItem
              key={index}
              val={index}
              text={item}
            />
          )} */}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownMenu;
