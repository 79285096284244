/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Column, Row, Text, Input, Button } from "../../index";
import SliderIcon from "../../../assets/stakingModal/slider-icon.svg";
import { Range, getTrackBackground } from "react-range";
import { depositNFT } from "utils/ada/deposit";
import { getUsersStakedNFTs } from "utils/ada/getPoolData";
import { withdraw } from "utils/ada/withdraw";
import { loadAvailableAmountDeposit } from "utils/ada/loadAvailableAmountToDeposit";
import { createNotification } from "store/actions/uiActions";
import Loader from "react-js-loader";
import { connect } from "react-redux";
import {
  setPoolsStakedTokens,
  setUsersRewards,
  setUsersStakedTokens,
} from "store/actions/poolActions";

const STEP = 1;
const MIN = 0;

const StakingModal = ({
  type,
  stakeTokenName,
  earnTokenName,
  stakingUnit,
  stakingWallet,
  modalType,
  amountOfStakedTokens,
  onClose,
  setUsersRewards,
  setUsersStakedTokens,
  createNotification,
  setPoolsStakedTokens,
  poolId,
  network,
  setActionIsLoading
}) => {
  const [value, setValue] = useState([0]);
  const [MAX, setMAX] = useState(1);
  const [stakedUnits, setStakedUnits] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [walletsAmountOfStakingUnit, setWalletsAmountOfStakingUnit] =
    useState(false);
  const [walletsAmountOfAda, setWalletsAmountOfAda] = useState(false);

  useEffect(() => {
    setMAX(amountOfStakedTokens)
  }, []);

  const maxButtonStaking = () => {
    setValue([MAX]);
  };

  const onChangeInput = (val) => {
    if (val <= MAX) setValue([val]);
  };

  const stake = async () => {
    onClose()
    setActionIsLoading(true);
    setIsLoading(true);
    try {
      if (value < 1 || MAX === 0) {
        createNotification("error", "You do not have enough tokens", 4000);
        return;
      }
     
      var nftItems = stakingUnit.split(",").slice(0, value);

      let stakingObject = {};
      for (let i = 0; i < nftItems.length; ++i) {
         stakingObject[nftItems[i]] =  1;

        //await depositNFT(stakingObject, stakingWallet, item == nftItems.length - 1, network == "pre");
      }

      // const stakingObject = {};
      // for (const item in nftItems) {
      //  stakingObject[nftItems[item]] = 1;
      // }
      // await depositNFT(stakingObject, stakingWallet, true);
      await depositNFT(stakingObject, stakingWallet, true, network == "pre");
     
      //await loadStakingUnitFromUser();
      //onClose();
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      setUsersRewards(network == "pre");
      setUsersStakedTokens(network == "pre");
      setPoolsStakedTokens(network == "pre");
      setIsLoading(false);
      setActionIsLoading(false);
    }
  };

  const unstake = async () => {
    onClose()
    setActionIsLoading(true);
    setIsLoading(true);
    try {
      await withdraw(value, poolId, stakingWallet,network == "pre");
      onClose();
    } catch (error) {
      debugger;
      console.log(JSON.stringify(error));
    } finally {
      setUsersRewards(network == "pre");
      setUsersStakedTokens(network == "pre");
      setPoolsStakedTokens(network == "pre");
      setIsLoading(false);
      setActionIsLoading(false);
    }
  };

  return (
    <Column className="staking-modal">
      {isLoading ? (
        <div style={{ width: "30%" }}>
          <Loader
            type="spinner-default"
            title=""
            bgColor={"#d02315"}
            size={50}
          />
        </div>
      ) : (
        <>
          <Row className="staking-modal__header">
            {type ? (
              <Text text={"Stake NFTs"} className="staking-modal__title" />
            ) : (
              <Text text={"Unstake NFTs"} className="staking-modal__title" />
            )}
          </Row>
          <Row style={{ width: "100%" }}>
            <Text
              text={
                "Stake " + stakeTokenName + " NFTs to earn " + earnTokenName
              }
              className="staking-modal__sub-title"
            />
          </Row>
          <Row className="staking-modal__balance">
            <Text text="Balance: " className="staking-modal__text" />
            <Text
              style={{ color: "#E1E2E6", textAlign: "right" }}
              text={MAX}
              className="staking-modal__text"
            />
          </Row>
          <Row style={{ width: "100%", marginBottom: "0.5rem" }}>
            {type ? (
              <Text
                text={"Stake NFTs"}
                className="staking-modal__sub-title"
              />
            ) : (
              <Text
                text={"Unstake NFTs"}
                className="staking-modal__sub-title"
              />
            )}
          </Row>
          <Row className="staking-modal__input__container">
            <Input
              type="number"
              value={value}
              onChange={(event) => onChangeInput(event.target.value)}
              placeholder=""
              className="staking-modal__input"
            />
            <Row className="staking-modal__input__buttons">
              <div
                className="staking-modal__max-button"
                onClick={maxButtonStaking}
              >
                <Text text="Use Max" style={{ fontSize: "12px" }} />
              </div>
              <div>
                <Text
                  text={earnTokenName}
                  style={{ color: "#a3a8ac" }}
                />
              </div>
            </Row>
          </Row>
        </>
      )}
      <Row style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Range
            values={value}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={(values) => {
              setValue(values);
            }}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "36px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "15px",
                    width: "100%",
                    borderRadius: "7px",
                    border: "solid 1px #54585b",

                    background: getTrackBackground({
                      values: value,
                      colors: ["#4ba3cf", "#1a2730"],
                      min: MIN,
                      max: MAX,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "42px",
                  width: "42px",
                  borderRadius: "4px",
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="staking-modal__slider-handle"
              >
                <img src={SliderIcon} alt="handle" />
                {/* <Text className="staking-modal__slider-text">
                  {value[0]} TANG
                </Text> */}
              </div>
            )}
          />
        </div>
      </Row>
      <Row
        style={{
          width: "100%",
          color: "#a3a8ac",
          justifyContent: "space-between",
          marginBottom: "5rem",
        }}
      >
        <Text>0 {earnTokenName}</Text>
        <Text>{MAX} {earnTokenName}</Text>
      </Row>
      <Row className="staking-modal__buttons">
        {type ? (
          <Button
            text={"Stake"}
            onClick={stake}
            className="red-button"
            style={{ width: "100%", fontSize: "15px" }}
          />
        ) : (
          <Button
            text={"Unstake"}
            onClick={unstake}
            className="red-button"
            style={{ width: "100%", fontSize: "15px" }}
          />
        )}
      </Row>
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ui.loading,
    network:state.network.network
  };
};

export default connect(mapStateToProps, {
  setUsersRewards,
  setUsersStakedTokens,
  createNotification,
  setPoolsStakedTokens,
})(StakingModal);
