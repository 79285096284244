import React, { useState, useEffect } from "react";
import axios from "axios";
import { NETWORK } from "utils/constants";
import starIcon from "../../assets/Star.png";

import bigInt from "big-integer";
import { Column, Box, Text, Row, CountUpNumber } from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const TreasuryBalance = () => {
  const [adaBalance, setAdaBalance] = useState(null);
  const [adaBalanceUsd, setAdaBalanceUsd] = useState(null);
  const [tangBalance, setTangBalance] = useState(0);

  const [circulationSupply, setCirculationSupply] = useState(0);
  const [epochNumber, setEpochNumber] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  async function getCoinGeckoPrice(symbol) {
    const response = await axios.get(`https://pro-api.coingecko.com/api/v3/simple/price?ids=${symbol}&vs_currencies=usd&x_cg_pro_api_key=CG-3a52etupLhiEC1vGv94wyBnH`);
    return response.data[symbol].usd;
  }

  const address =
    NETWORK == "pre"
      ? "addr_test1qz0pr5t3k2u87my7q5s63qenjqgl8wclj2vl677gasw3mhlrycqnj7mwl59je38rkcnfw9zghhhlxh5ptjatvgu00fmsxhlaw8"
      : "addr1q9d4sdume9sn8kfmnes07rzvj45l4dpmjzvukm730pg3upyxj7hwpxu5tght0zquuapxgugqpzhhg3ej9xkk9kf0arpq92g6a4";

  let environment = NETWORK == "pre" ? "preprod" : "mainnet";

  let CORE_URL = `https://cardano-${environment}.blockfrost.io/api/v0`;

  let ADA_BALANCE_API_URL = `https://cardano-${environment}.blockfrost.io/api/v0/addresses/`;

  const project_id =
    NETWORK == "pre"
      ? "preprodZtkDCJAY6VeCUO6wuqNI5PmELLfH1gwL"
      : "mainnetzlLTysAcRwAtzsmzuIPUTAmR8LRGNDzG";

  const unit =
    NETWORK == "pre"
      ? "402447f6f37f52e212cd25d5d3f6cebf1c0b9d21ef755efc63c1fb205454414e47"
      : "bf9354cba4ee83c5de05c72830c6430967a26a1656b06293541d23e154414e47";


  const circulationSupplyAddress1 =
    NETWORK == "pre"
      ? "addr_test1qrqq6ze3ed86vuk2dcrd5r8ggflkmx2txk8ydsk6nzuc3yvqeaa899m4jtha0vedsq6uyu3w3gzxa3fwv95vzfwvshjsppmw8v"
      : "addr1q933qus8syyqdfppsktwh9xnr7f0acvhy3jr03ry0yy4edme9wenl05c4ev6yarggp0wgf0nrtauh3yxp90ddnu5n32qv3hjzw";

  const circulationSupplyAddress2 =
    NETWORK == "pre"
      ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
      : "addr1qxa2n6n52lpuujjlarlhqu5lav5cqjux20re2z96d5hqzncn7v05fpa9tvm6rav2qupfxpt8svv4zwymu6z05vpsa76q9vknvk";


  const circulationSupplyAddress3 =
    NETWORK == "pre"
      ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
      : "addr1q9avxz6ut0ysrwz94wtccy6sv47v4x5r0xwl0quysymrkegvua2g8jnv57nrgwvgphhlwpdgqh7rre7dzcguje0mpc4qt5sku7";

  const circulationSupplyAddress4 =
    NETWORK == "pre"
      ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
      : "addr1q9mgvf2pl5xtmck4jtuypqqmwakapjgt04a3srpqldu09l3y0a0z3q48yqpwzd7gaa9792675uwte3hujg7xfacj5j6qjdl8sx";


  const circulationSupplyAddress5 =
      NETWORK == "pre"
        ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
        : "addr1q9d4sdume9sn8kfmnes07rzvj45l4dpmjzvukm730pg3upyxj7hwpxu5tght0zquuapxgugqpzhhg3ej9xkk9kf0arpq92g6a4";
  
  const circulationSupplyAddress6 =
        NETWORK == "pre"
          ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
          : "addr1q9ekj6gaqlx6ackrwx9x60meukdt44gcl0f6vux0ph79pkn36t9a9zupqdhdm5rnf05l4gjsd62fnwmhdck3p3d6reqsfagkxf";
    
    
  

  useEffect(() => {
    // Function to fetch ADA balance
    const fetchAdaBalance = async () => {
      try {
        const response = await axios.get(
          `${ADA_BALANCE_API_URL}${address}`,
          {
            headers: {
              "Content-Type": "application/json",
              project_id: project_id, // Replace with your actual API key
            },
          }
        );

        const utxos = response.data;
        console.log(utxos);
        let lovelaceSum;
        const adaBalance = utxos.amount[0].quantity;

        const ethPriceResponse = await axios.get(
          `https://pro-api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=USD,EUR,GBP&x_cg_pro_api_key=CG-3a52etupLhiEC1vGv94wyBnH`
        );
        lovelaceSum = adaBalance / 1000000;
        console.log(ethPriceResponse.data.cardano?.usd);
        const ethPrice = ethPriceResponse.data.cardano?.usd;
        const priceInUSD = lovelaceSum * ethPrice;

        
        let tangBalance, cPiggyBalance;

        const tangPrice = await getCoinGeckoPrice('tangent'); // Replace 'tang' with the actual token symbol on CoinGecko
        const cPiggyPrice = await getCoinGeckoPrice('cpiggy-bank-token'); // Replace 'cpiggy' with the actual token symbol on CoinGecko

        const tangTokenUnit = 'bf9354cba4ee83c5de05c72830c6430967a26a1656b06293541d23e154414e47';
        const cPiggyTokenUnit = '0c3b14ddb319c5a50bfd93e69d0706d1527e2b2169744665a1c534d0635049474759';
        for (const amount of utxos.amount) {
          if (amount.unit === tangTokenUnit) {
            tangBalance = parseFloat(amount.quantity) / 10 ** 6;
          } else if (amount.unit === cPiggyTokenUnit) {
            cPiggyBalance = parseFloat(amount.quantity) / 10 ** 2;
          }
        }

        const tangBalanceUsd = tangBalance * tangPrice;
        const cPiggyBalanceUsd = cPiggyBalance * cPiggyPrice;

        const tangBalanceAda = tangBalanceUsd / ethPrice; // Convert tang balance to ADA
        const cPiggyBalanceAda = cPiggyBalanceUsd / ethPrice; // Convert cPiggy balance to ADA

        let totalAmount = lovelaceSum + tangBalanceAda + cPiggyBalanceAda;
        let totalUsdAmount = priceInUSD + cPiggyBalanceUsd + tangBalanceUsd;

        setAdaBalance(totalAmount);
        setAdaBalanceUsd(totalUsdAmount);

      
       

       

        console.log('TANG Balance:', tangBalance);
        console.log('cPiggy Balance:', cPiggyBalance);


        console.log('TANG Balance (ADA):', tangBalanceAda);
        console.log('TANG Balance (USD):', tangBalanceUsd);
        console.log('cPiggy Balance (ADA):', cPiggyBalanceAda);
        console.log('cPiggy Balance (USD):', cPiggyBalanceUsd);


        const response1 = await axios.get(
          `${ADA_BALANCE_API_URL}${circulationSupplyAddress1}/utxos?`,
          {
            headers: {
              "Content-Type": "application/json",
              project_id: project_id, // Replace with your actual API key
            },
          }
        );
        const utxos1 = response1.data;
        let tangSum1;
        if (utxos1 && utxos1.length > 0) {

          tangSum1 = utxos1.reduce((total, item) => {
            const tangAmount = item.amount.find((a) => a.unit === unit);
            return total + parseInt(tangAmount ? tangAmount.quantity : 0);
          }, 0);
          //  tangSum1 = tangSum1 * 10 ** 6
        }
        const response2 = await axios.get(
          `${ADA_BALANCE_API_URL}${circulationSupplyAddress2}/utxos`,
          {
            headers: {
              "Content-Type": "application/json",
              project_id: project_id, // Replace with your actual API key
            },
          }
        );
        const utxos2 = response2.data;
        let tangSum2;
        if (utxos2 && utxos2.length > 0) {

          tangSum2 = utxos2.reduce((total, item) => {
            const tangAmount = item.amount.find((a) => a.unit === unit);
            return total + parseInt(tangAmount ? tangAmount.quantity : 0);
          }, 0);

        }

        const response3 = await axios.get(
          `${ADA_BALANCE_API_URL}${circulationSupplyAddress3}/utxos`,
          {
            headers: {
              "Content-Type": "application/json",
              project_id: project_id, // Replace with your actual API key
            },
          }
        );
        const utxos3 = response3.data;
        let tangSum3;
        if (utxos3 && utxos3.length > 0) {

          tangSum3 = utxos3.reduce((total, item) => {
            const tangAmount = item.amount.find((a) => a.unit === unit);
            return total + parseInt(tangAmount ? tangAmount.quantity : 0);
          }, 0);

        }

        const response4 = await axios.get(
          `${ADA_BALANCE_API_URL}${circulationSupplyAddress4}/utxos`,
          {
            headers: {
              "Content-Type": "application/json",
              project_id: project_id, // Replace with your actual API key
            },
          }
        );
        const utxos4 = response4.data;
        let tangSum4;
        if (utxos4 && utxos4.length > 0) {

          tangSum4 = utxos4.reduce((total, item) => {
            const tangAmount = item.amount.find((a) => a.unit === unit);
            return total + parseInt(tangAmount ? tangAmount.quantity : 0);
          }, 0);
        }

          
          let tangSum5 = 0;

        
            const response5 = await axios.get(`${ADA_BALANCE_API_URL}${circulationSupplyAddress5}/total`, {
              headers: {
                'Content-Type': 'application/json',
                  'project_id': project_id,
              },
          });
  
          if (!response.data || !response.data.amount) {
              console.log(`No data found for address ${circulationSupplyAddress5}`);
              return;
          }
  
          const tokenAmounts = response.data.amount;
          const targetUnit = 'bf9354cba4ee83c5de05c72830c6430967a26a1656b06293541d23e154414e47';
  
    
          for (const tokenAmount of tokenAmounts) {
              if (tokenAmount.unit === targetUnit) {
                tangSum5 += parseInt(tokenAmount.quantity);
              }
          }
  
          console.log(`Sum of quantities with unit ${targetUnit}: ${tangSum5}`);

        

          const response6 = await axios.get(
            `${ADA_BALANCE_API_URL}${circulationSupplyAddress6}/utxos`,
            {
              headers: {
                "Content-Type": "application/json",
                project_id: project_id, // Replace with your actual API key
              },
            }
          );
          const utxos6 = response6.data;
          let tangSum6;
          if (utxos6 && utxos6.length > 0) {
  
            tangSum6 = utxos6.reduce((total, item) => {
              const tangAmount = item.amount.find((a) => a.unit === unit);
              return total + parseInt(tangAmount ? tangAmount.quantity : 0);
            }, 0);
          }


          const tang1WithDecimals = parseFloat((tangSum1 / 10 ** 6).toFixed(6));
          const tang2WithDecimals = parseFloat((tangSum2 / 10 ** 6).toFixed(6));
          const tang3WithDecimals = parseFloat((tangSum3 / 10 ** 6).toFixed(6));
          const tang4WithDecimals = parseFloat((tangSum4 / 10 ** 6).toFixed(6));
          const tang5WithDecimals = parseFloat((tangSum5 / 10 ** 6).toFixed(6));
          const tang6WithDecimals = parseFloat((tangSum6 / 10 ** 6).toFixed(6));

          // Summing up the numbers with decimals
          const sum = tang1WithDecimals + tang2WithDecimals + tang3WithDecimals + tang4WithDecimals + tang5WithDecimals + tang6WithDecimals;
          // console.log('sum', sum)

          //  console.log('tang', tangSum1, tangSum2)
          setTangBalance(sum)
          setCirculationSupply(((3141592650 - sum) / 3141592650) * 100)
        




        const responseEpoch = await axios.get(`${CORE_URL}/epochs/latest`, {
          headers: {
            "Content-Type": "application/json",
            project_id: project_id, // Replace with your actual API key
          },
        });
        setEpochNumber(responseEpoch.data.epoch);

        // const responseCirculation = await axios.get(`${CORE_URL}/addresses/${address}/total`, {
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'project_id': project_id, // Replace with your actual API key
        //   },
        // });
        // setCirculationSupply("0 %");
      } catch (error) {
        console.error("Error fetching ADA balance:", error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch ADA balance when the component mounts or the address changes
    fetchAdaBalance();
  }, []);

  // Display loading state while waiting for the response
  if (loading) {
    return <Text text="Loading .." className="info-section__currency" />;
  }

  const toggleExpand = () => {
    setExpanded(!expanded);
  };


  return (
    <div className="expandable-box"
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <div
        style={{
          height: "200px",
          width: "200px",
          right: "-130px",
          top: "-100px",
          position: "absolute",
          borderRadius: 331.65,
          opacity: 0.25,
          background: "#F83472",
          filter: "blur(76.9px)",
        }}
      ></div>
      <div
        style={{
          height: "200px",
          width: "200px",
          bottom: "-130px",
          left: "-100px",
          position: "absolute",
          borderRadius: 331.65,
          opacity: 0.16,
          background: "#4BA3CF",
          filter: "blur(67.95704650878906px)",
        }}
      ></div>
      <button
        className={`show-more-button ${expanded ? "expanded" : ""}`}
        onClick={toggleExpand}
      >
        {expanded ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </button>
      {/* <div
        style={{
          height: "40%",
          top: "-280px",
          right: "-130px",
          position: "relative",
          borderRadius: 331.65,
          opacity: 0.25,
          background: "#F83472",
          filter: "blur(76.9px)",
        }}
      ></div> */}

      <Row>
        <div className="info-section__infoIcon">
          <img
            className="info-section__header__icon"
            src={starIcon}
            alt="Star icon"
          />
        </div>
        <Text text="Treasury" className="info-section__header" />
      </Row>
      {!expanded && (
        <>
          <Row className="info-section__pair">
            <CountUpNumber
              className="info-section__number"
              startValue={0}
              end={adaBalance !== null ? `${adaBalance}` : "N/A"}
              decimals={2}
            />
            <Text text="ADA" className="info-section__currency" />
          </Row>
          <Row className="info-section__pair">
            <Text
              text="$"
              className="info-section__header"
              style={{ fontSize: 22 }}
            />

            <CountUpNumber
              className="info-section__number"
              startValue={0}
              end={adaBalanceUsd !== null ? `${adaBalanceUsd}` : "N/A"}
              decimals={2}
            />
            <Text text="USD" className="info-section__currency" />
          </Row>
        </>
      )}

      {/* Additional rows to be shown when expanded */}
      {expanded && (
        <>
          <Row
            className="info-section__pair"
            style={{
              marginTop: 30, "alignItems": "baseline",  /* Add a smooth transition */
            }}
          >
            <Text
              text="Epoch:  "
              className="info-section__currency"
              style={{ "fontSize": 13, color: "#ffffff78" }}
            />
            <CountUpNumber
              style={{
                fontSize: "11px !important",
                marginLeft: 5,
              }}
              className="info-section__balance"
              startValue={0}
              end={epochNumber}
              decimals={0}
            />
          </Row>
          <Row
            className="info-section__pair"
            style={{
              marginBottom: "1rem",
              "alignItems": "baseline",
            }}
          >
            <Text
              text="TANG Circulating Supply: "
              className="info-section__currency"
              style={{ "fontSize": 13, color: "#ffffff78" }}
            />
            <CountUpNumber
              className="info-section__balance"
              suffix={"%"}
              startValue={0}
              end={circulationSupply}
              decimals={1}
            />
          </Row>
          <Row
            style={{
              "borderBottom": "1.5px solid rgba(225, 226, 230, 0.15)",
              marginTop: 25,
              marginBottom: 25,
            }}
          ></Row>

          <Column>
            <Text
              text=" Treasury Value:  "
              className="info-section__currency"
              style={{ "fontSize": 13, color: "#ffffff78" }}
            />
          </Column>
          <Row style={{ "alignItems": "baseline" }}>
            <Column>
              <CountUpNumber
                className="info-section__balance"
                startValue={0}
                end={adaBalance !== null ? `${adaBalance}` : "N/A"}
                decimals={2}
              />
            </Column>
            <Text text="ADA" className="info-section__balance" />
          </Row>
          <Row style={{ marginTop: "1rem", "alignItems": "baseline" }}>
            <Column>
              <CountUpNumber
                style={{ fontSize: 22 }}
                className="info-section__balance"
                startValue={0}
                end={adaBalanceUsd !== null ? `${adaBalanceUsd}` : "N/A"}
                decimals={2}
              />
            </Column>
            <Text text="USD" className="info-section__balance" />
          </Row>
          <Row className="info-section__pair">
            <a
              className="info-section__account"
              target="_blank"
              rel="noreferrer"
              style={{ "overflowWrap": "anywhere" }}
              href={`https://${NETWORK == "pre" ? "preprod." : ""
                }cardanoscan.io/address/${address}`}
            >
              {address}
            </a>
          </Row>
        </>
      )}

      {/* Show More button */}
      {/* <button className="show-more-button" onClick={toggleExpand}>
      {expanded ? "Show Less" : "Show More"}
    </button> */}
    </div>
    // <>

    //   <Row
    //     className="info-section__pair"
    //     style={{
    //     }}
    //   >
    //     <Text text="Epoch:  " className="info-section__currency" />
    //     <CountUpNumber
    //       style={{
    //         fontSize: '11px !important',
    //         marginLeft: 5
    //       }}
    //       className="info-section__balance"

    //       startValue={0}
    //       end={epochNumber}
    //       decimals={0}
    //     />
    //   </Row>
    //   <Row
    //     className="info-section__pair"
    //     style={{
    //       borderBottom: "1px dashed #b3acbb",
    //       paddingBottom: "2rem",
    //       marginBottom: "1rem",
    //     }}
    //   >
    //     <Text text="Circulating Supply: " className="info-section__currency" />
    //     <CountUpNumber
    //       className="info-section__balance"

    //       startValue={0}
    //       end={circulationSupply}
    //       decimals={0}
    //     />
    //   </Row>
    //   <Row
    //     style={{
    //       marginTop: "2rem",
    //     }}>
    //     <Text text="Value: " className="info-section__currency" />
    //   </Row>
    //   <Row
    //     className="info-section__pair"
    //   >
    //     <CountUpNumber
    //       className="info-section__number"
    //       startValue={0}
    //       end={adaBalance !== null ? `${adaBalance}` : 'N/A'}
    //       decimals={2}
    //     />
    //     <Text text="ADA" className="info-section__currency" />
    //   </Row>
    //   <Row
    //     className="info-section__pair"
    //   >
    //     <CountUpNumber
    //       className="info-section__number"
    //       startValue={0}
    //       end={adaBalanceUsd !== null ? `${adaBalanceUsd}` : 'N/A'}
    //       decimals={2}
    //     />
    //     <Text text="USD" className="info-section__currency" />
    //   </Row>
    // </>
  );
};

export default TreasuryBalance;
