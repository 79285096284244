import React, { useState, useEffect } from "react";
import { Row, Text, Column, CountUpNumber } from "../../../components";
import { connect } from "react-redux";
import tangLogo from "../../../assets/tokenStaking/tang.png";
import goldieLogo from "../../../assets/tokenStaking/goldie.png";
import ratDaoLogo from "../../../assets/tokenStaking/ratdao.png";
import adaLogo from "../../../assets/tokenStaking/ada.svg";
import arrowDown from "../../../assets/arrow-down.svg";
import arrowUp from "../../../assets/arrow-up.svg";
import arrowDownHover from "../../../assets/arrow-down-hover.svg";
import arrowUpHover from "../../../assets/arrow-up-hover.svg";
import ExpandedOrderItem from "./ExpandedOrderItem";
import { createNotification } from "store/actions/uiActions";

// take id of farm => based on id show image
const OrderItem = ({
  data,
  stakingWallet,
  adaWallet,
  createNotification,
  style,
  windowSize,
}) => {
  const [itemIsExpanded, setItemIsexpanded] = useState(false);
  const [arrowImg, setArrowImg] = useState(arrowDown);

  // const isUnstakingAvailable = false;

  const isMobileViewEnabled = windowSize[0] < 900;

  useEffect(() => {
    // console.log("Data:", data);
  }, []);
  const expand = () => {
    setItemIsexpanded(!itemIsExpanded);
    if (itemIsExpanded) {
      setArrowImg(arrowDown);
    } else {
      setArrowImg(arrowUp);
    }
  };

  const onArrowHover = () => {
    if (itemIsExpanded) {
      setArrowImg(arrowUpHover);
    } else {
      setArrowImg(arrowDownHover);
    }
  };

  const onArrowOut = () => {
    if (itemIsExpanded) {
      setArrowImg(arrowUp);
    } else {
      setArrowImg(arrowDown);
    }
  };

  const StatusComponent = ({ status }) => {
    var bgColor = "#ef8b11";
    // console.log("Status:", status);
    switch (status) {
      case "Open":
        bgColor = "#ef8b11";
        break;
      case "Completed":
        bgColor = "#8cf29f";
        break;
      case "Pending":
        bgColor = "#4ba3cf";
        break;
      case "Failed":
        bgColor = "#f83472";
        break;
    }
    return (
      <Row style={{ alignItems: "center" }}>
        <div style={{ backgroundColor: bgColor }} className="statusIcon" />
        <Text text={status} style={{ paddingLeft: "4px", color: "#fff" }} />
      </Row>
    );
  };

  return (
    <Column>
      <Row
        className="liquidity-item"
        style={{
          ...style,
          ...(itemIsExpanded && { borderBottom: "none", marginBottom: 0 }),
        }}
        onClick={expand}
      >
        <Row className="liquidity-item__big-space">
        <img
          src={data.icon}
          alt="NFT"
          className="liquidity-item__image"
          style={{
            width: "27px",
          }}
        />
        <Text text={data.asset} className="liquidity-item__title" />
        </Row>

        {isMobileViewEnabled ? (
          <Row style={{ width: "100%" }}>
            <div className="liquidity-item__small-space">
              <Text text="Action" className="liquidity-item__value" />
            </div>
            <div className="liquidity-item__dashline" />
            <div className="liquidity-item__small-space">
              <Text
                text={data?.action}
                className="liquidity-item__value"
                style={{ textAlign: "right" }}
              />
            </div>
          </Row>
        ) : (
          <div className="liquidity-item__small-space">
            <Text text={data?.action} className="liquidity-item__value" />
          </div>
        )}
        {isMobileViewEnabled ? (
          <Row style={{ width: "100%" }}>
            <Row style={{ width: "100%" }}>
              <div className="liquidity-item__small-space">
                <Text text="Amount" className="liquidity-item__value" />
              </div>
              <div className="liquidity-item__dashline" />
              <div className="liquidity-item__small-space">
                <Text
                  text={(data?.amount / 10 ** data.decimal).toLocaleString()}
                  className="liquidity-item__value"
                  style={{ textAlign: "left" }}
                />
              </div>
            </Row>
          </Row>
        ) : (
          <Column className="liquidity-item__small-space">
            <Text
                  text={(data?.amount / 10 ** data.decimal).toLocaleString()}
                  className="liquidity-item__value"
              style={{ textAlign: "left" }}
            />
          </Column>
        )}
        {isMobileViewEnabled ? (
          <Row className="liquidity-item__big-space">
            <Row style={{ width: "100%" }}>
              <div className="liquidity-item__small-space">
                <Text text="Status" className="liquidity-item__value" />
              </div>
              <div className="liquidity-item__dashline" />
              <div className="liquidity-item__small-space">
                <StatusComponent status={data.status} />
              </div>
            </Row>
          </Row>
        ) : (
          <div className="liquidity-item__small-space">
            <StatusComponent status={data.status} />
          </div>
        )}

        <Row className="liquidity-item__small-space" style={{ width: "5%" }}>
          <img
            onClick={expand}
            className="liquidity-item__expand"
            src={arrowImg}
            onMouseOver={() => onArrowHover()}
            onMouseOut={() => onArrowOut()}
            alt="Arrow down"
          />
        </Row>
      </Row>
      {itemIsExpanded && (
        <ExpandedOrderItem
          isMobileViewEnabled={isMobileViewEnabled}
          data={data}
        />
      )}
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
  };
};

export default connect(mapStateToProps, { createNotification })(OrderItem);
