import React from "react";
import styled from "styled-components";
import Row from "../Row/Row";
import Text from "../Text/Text";
import { connect } from "react-redux";
import raisedIcon from "../../assets/progress/raised.svg";
import soldIcon from "../../assets/progress/sold.svg";

export const Slider = styled.input`
  &::-webkit-slider-thumb {
    margin-left: ${(props) => props.marginLeft}rem;
  }
`;

export const Fill = styled.div`
  width: ${(props) => props.progress}%;
  height: 15px;
  border-radius: 5px;
  background: ${(props) => (!props.raisedImage ? "#F83472" : "#4BA3CF")};
  position: absolute;
  z-index: 9;
`;

export const Container = styled.div`
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background-color: #1c1532;
  position: relative;
`;

const Progress = styled.div`
  width: ${(props) => props.progress}%;
  background: ${(props) => (!props.raisedImage ? "#F83472" : "#4BA3CF")};
`;

const ProgressIcon = styled.img`
  right: ${(props) => props.progress}%;
`;

const ProgressBar = ({
  progress,
  raisedImage,
  adaRaised,
  tangentPurchased,
}) => {
  if (progress === 0) {
    progress = 1;
  }
  return (
    <div className="progress">
      <Progress
        raisedImage={raisedImage}
        progress={progress}
        className="progress-done"
      />
      <ProgressIcon
        progress={100 - (Number(progress) + 7)}
        src={raisedImage ? raisedIcon : soldIcon}
        className="progress-icon"
        alt="progress icon"
      />
      <Row className="bubble">
        <Text
          style={{ color: "black" }}
          text={
            raisedImage
              ? `${adaRaised} Ada raised`
              : `${tangentPurchased} TANG sold`
          }
        ></Text>{" "}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adaRaised: state.user.adaRaised,
    tangentPurchased: state.user.tangentPurchased,
  };
};

export default connect(mapStateToProps, null)(ProgressBar);
