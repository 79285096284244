import React from "react";
import { Row, Text } from "../../../components";

const LiquidityTopics = () => {
  return (
    <Row className="liqudity-topic">
      <div className="liqudity-topic__big-space">
        <Text
          text="Asset"
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="liqudity-topic__small-space"
       style={{ width: '15%'}}>
        <Text
          text="Yield"
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px"}}
        />
      </div>
      <div className="liqudity-topic__small-space"
       style={{ width: '20%', marginLeft: '4%'}}>
        <Text
          text="Staked"
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="liqudity-topic__small-space"
       style={{ width: '23%', paddingRight: 10}}>
        <Text
          text="Earn"
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="liqudity-topic__small-space"
       style={{ width: '15%'}}>
        <Text
          text=""
          className="liqudity-topic__col-title"
          style={{ fontSize: "12px" }}
        />
      </div>
    </Row>
  );
};

export default LiquidityTopics;
