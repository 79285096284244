import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { Column, Input, Text } from "components";

const ShippingForm = () => {
  const [phone, setPhone] = useState();
  return (
    <Column style={{ width: "100%" }}>
      <Column className="section">
        <Text className="shipping-label" text="Your Name" />
        <Input className="shipping-input" type="text" />
      </Column>
      <Column className="section">
        <Text className="shipping-label" text="Address" />
        <Input className="shipping-input__address" type="text" />
      </Column>
      <div className="email-phone">
        <Column style={{ width: "100%" }}>
          <Text className="shipping-label" text="Email" />
          <Input className="shipping-input" type="text" />
        </Column>
        <Column className="phone-container">
          <Text
            style={{ marginBottom: "1rem" }}
            className="shipping-label"
            text="Phone"
          />
          <PhoneInput
            country={"us"}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            containerStyle={{
              width: "100%",
              height: "42px",
            }}
            inputStyle={{
              background: "transparent",
              color: "white",
              width: "100%",
              height: "42px",
              border: "solid 1px #54585b",
            }}
            buttonStyle={{
              background: "transparent",
              border: "solid 1px #54585b",
            }}
            className="phone-input"
          />
        </Column>
      </div>
    </Column>
  );
};

export default ShippingForm;
