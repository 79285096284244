import React from "react";
import CountUp from "react-countup";
import { connect } from "react-redux";

const CountUpNumber = ({
  className,
  startValue,
  end,
  decimals,
  suffix,
  prefix,
  contractsLoadedAlready,
}) => {
  return (
    <CountUp
      className={className}
      start={contractsLoadedAlready ? startValue : 0}
      end={end}
      decimals={decimals}
      {...(prefix && { prefix })}
      {...(suffix && { suffix })}
      duration={1}
      separator=","
    />
  );
};

const mapStateToProps = (state) => {
  return {
    contractsLoadedAlready: state.ui.contractsLoadedAlready,
  };
};

export default connect(mapStateToProps)(CountUpNumber);
