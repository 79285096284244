import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { NETWORK } from "utils/constants";
import starIcon from "../../assets/Star.png";

import bigInt from "big-integer";
import { Column, Box, Text, Row, CountUpNumber } from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const TotalReward = ({userRewards, claimedRewards}) => {
  const [adaBalance, setAdaBalance] = useState(null);
  const [adaBalanceUsd, setAdaBalanceUsd] = useState(null);
  const [tangBalance, setTangBalance] = useState(0);

  const [rewards, setReward] = useState({"TANG": 0});

  const [circulationSupply, setCirculationSupply] = useState(0);
  const [epochNumber, setEpochNumber] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  async function getCoinGeckoPrice(symbol) {
    const response = await axios.get(`https://pro-api.coingecko.com/api/v3/simple/price?ids=${symbol}&vs_currencies=usd&x_cg_pro_api_key=CG-3a52etupLhiEC1vGv94wyBnH`);
    return response.data[symbol].usd;
  }

  useEffect(() => {
    if(!userRewards) return;
    
    let sum = { };
    Object.keys(userRewards).forEach(poolId => {
      userRewards[poolId].forEach(rewardInfo => {
          const rewardName = rewardInfo.rewardUnit.name;
          const amount = rewardInfo.amount;

          if (sum[rewardName]) {
            sum[rewardName] += amount;
          }else{
            sum[rewardName] = amount
          }
      });
      setReward(sum)
    });
    
  }, [userRewards]);

  const address =
    NETWORK == "pre"
      ? "addr_test1qz0pr5t3k2u87my7q5s63qenjqgl8wclj2vl677gasw3mhlrycqnj7mwl59je38rkcnfw9zghhhlxh5ptjatvgu00fmsxhlaw8"
      : "addr1q9d4sdume9sn8kfmnes07rzvj45l4dpmjzvukm730pg3upyxj7hwpxu5tght0zquuapxgugqpzhhg3ej9xkk9kf0arpq92g6a4";

  let environment = NETWORK == "pre" ? "preprod" : "mainnet";

  let CORE_URL = `https://cardano-${environment}.blockfrost.io/api/v0`;

  let ADA_BALANCE_API_URL = `https://cardano-${environment}.blockfrost.io/api/v0/addresses/`;

  const project_id =
    NETWORK == "pre"
      ? "preprodZtkDCJAY6VeCUO6wuqNI5PmELLfH1gwL"
      : "mainnetzlLTysAcRwAtzsmzuIPUTAmR8LRGNDzG";

  const unit =
    NETWORK == "pre"
      ? "402447f6f37f52e212cd25d5d3f6cebf1c0b9d21ef755efc63c1fb205454414e47"
      : "bf9354cba4ee83c5de05c72830c6430967a26a1656b06293541d23e154414e47";


  const circulationSupplyAddress1 =
    NETWORK == "pre"
      ? "addr_test1qrqq6ze3ed86vuk2dcrd5r8ggflkmx2txk8ydsk6nzuc3yvqeaa899m4jtha0vedsq6uyu3w3gzxa3fwv95vzfwvshjsppmw8v"
      : "addr1q933qus8syyqdfppsktwh9xnr7f0acvhy3jr03ry0yy4edme9wenl05c4ev6yarggp0wgf0nrtauh3yxp90ddnu5n32qv3hjzw";

  const circulationSupplyAddress2 =
    NETWORK == "pre"
      ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
      : "addr1qxa2n6n52lpuujjlarlhqu5lav5cqjux20re2z96d5hqzncn7v05fpa9tvm6rav2qupfxpt8svv4zwymu6z05vpsa76q9vknvk";


  const circulationSupplyAddress3 =
    NETWORK == "pre"
      ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
      : "addr1q9avxz6ut0ysrwz94wtccy6sv47v4x5r0xwl0quysymrkegvua2g8jnv57nrgwvgphhlwpdgqh7rre7dzcguje0mpc4qt5sku7";

  const circulationSupplyAddress4 =
    NETWORK == "pre"
      ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
      : "addr1q9mgvf2pl5xtmck4jtuypqqmwakapjgt04a3srpqldu09l3y0a0z3q48yqpwzd7gaa9792675uwte3hujg7xfacj5j6qjdl8sx";


  const circulationSupplyAddress5 =
      NETWORK == "pre"
        ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
        : "addr1q9d4sdume9sn8kfmnes07rzvj45l4dpmjzvukm730pg3upyxj7hwpxu5tght0zquuapxgugqpzhhg3ej9xkk9kf0arpq92g6a4";
  
  const circulationSupplyAddress6 =
        NETWORK == "pre"
          ? "addr_test1qqy4249sc3rpk7j65ckjhnzrspgk4jttz0wd08zq07j0snukt3cjr5yf64lskkr599ha7x5getytkkxn24p4544frf5qj82e8w"
          : "addr1q9ekj6gaqlx6ackrwx9x60meukdt44gcl0f6vux0ph79pkn36t9a9zupqdhdm5rnf05l4gjsd62fnwmhdck3p3d6reqsfagkxf";
    
    

  // Display loading state while waiting for the response
  if (!userRewards || !claimedRewards) {
    return <Text text="Loading .." className="info-section__currency" />;
  }

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  

  return (
    <div className="expandable-box"
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <div
        style={{
          height: "200px",
          width: "200px",
          right: "-130px",
          top: "-100px",
          position: "absolute",
          borderRadius: 331.65,
          opacity: 0.25,
          background: "#F83472",
          filter: "blur(76.9px)",
        }}
      ></div>
      <div
        style={{
          height: "200px",
          width: "200px",
          bottom: "-130px",
          left: "-100px",
          position: "absolute",
          borderRadius: 331.65,
          opacity: 0.16,
          background: "#4BA3CF",
          filter: "blur(67.95704650878906px)",
        }}
      ></div>
      <button
        className={`show-more-button ${expanded ? "expanded" : ""}`}
        onClick={toggleExpand}
      >
        {expanded ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </button>
      {/* <div
        style={{
          height: "40%",
          top: "-280px",
          right: "-130px",
          position: "relative",
          borderRadius: 331.65,
          opacity: 0.25,
          background: "#F83472",
          filter: "blur(76.9px)",
        }}
      ></div> */}

      <Row>
        <div className="info-section__infoIcon">
          <img
            className="info-section__header__icon"
            src={starIcon}
            alt="Star icon"
          />
        </div>
        <Text text="Available Rewards" className="info-section__header" />
      </Row>
     
        <>
          <Row className="info-section__pair">
            <CountUpNumber
              className="info-section__number"
              startValue={0}
              end={rewards["TANG"]}
              decimals={2}
            />
            <Text text="TANG" className="info-section__currency" />
          </Row>

          {expanded && (

<>

<Row
style={{
  "borderBottom": "1.5px solid rgba(225, 226, 230, 0.15)",
  marginTop: 25,
  marginBottom: 25,
}}
></Row>

<Column>
<Text
  text=" Claimed Rewards:  "
  className="info-section__currency"
  style={{ "fontSize": 13, color: "#ffffff78" }}
/>
</Column>
<Row className="info-section__pair">
<CountUpNumber
  className="info-section__number"
  startValue={0}
  end={claimedRewards["1"]}
  decimals={2}
/>
<Text text="TANG" className="info-section__currency" />
</Row> </>
               )}
        </>


     
      {expanded && (
        <>
        {Object.entries(rewards).map(([key, value], index) =>
           <Row className="info-section__pair">
            <CountUpNumber
              className="info-section__number"
              startValue={0}
              end={value}
              decimals={2}
            />
            <Text text={key} className="info-section__currency" />
          </Row>
        )}
        </>
      )}

    </div>
   
  );
};


const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    userStakedTokens: state.pool.userStakedTokens,
    userRewards: state.pool.userRewards,
    userTokenAmount: state.user.userTokenAmount,
    claimedRewards: state.user.claimedRewards,
    network: state.network.network,
  };
};

export default connect(mapStateToProps, {  })(TotalReward);;

