import { store } from "../../index.js";
import { Blockfrost, C, Lucid, Tx } from "lucid-cardano";
import {
  BLOCKFROST_API_KEY,
  BLOCKFROST_NETWORK,
  BLOCKFROST_URL,
  BF_TEST_API_KEY,
  BF_MAIN_API_KEY,
} from "utils/constants";
import { createNotification } from "store/actions/uiActions.js";

export const initializeLucid = async (walletapi, isPrepord) => {
  let lucid = await Lucid.new(
    new Blockfrost(
      isPrepord
        ? "https://cardano-preprod.blockfrost.io/api/v0"
        : "https://cardano-mainnet.blockfrost.io/api/v0", // blockfrost url
      isPrepord ? BF_TEST_API_KEY : BF_MAIN_API_KEY // secret
    ),
    isPrepord ? "Preprod" : "Mainnet" //  as Network
  );
  // let lucid = await Lucid.new(
  //   new Blockfrost(
  //     BLOCKFROST_URL, // blockfrost url
  //     BLOCKFROST_API_KEY // secret
  //   ),
  //   BLOCKFROST_NETWORK //  as Network
  // );
  if (walletapi) lucid = lucid.selectWallet(walletapi);
  return lucid;
};

export const depositTx = async (value, stakingWallet, lucid, utxos) => {
  let tx = new Tx(lucid);

  if (utxos) {
    tx = tx.collectFrom(utxos);
  }
  tx = tx.payToAddress(stakingWallet, value);

  return await tx.complete();
};

export const awaitTx = async (txhash, isPreProd) => {
  const walletName = store.getState().user.adaWallet;
  const loadedLucid = await initializeLucid(
    await window.cardano[walletName].enable(),
    isPreProd
  );
  if (await loadedLucid.awaitTx(txhash)) return; // load() *** TODO: add load function and handle load like in ADAO code ***
};

export const getUserWallet = async (isPreProd) => {
  const lucid = await getLucid(isPreProd);
  const userWallet = await lucid.wallet.address();
  console.log(userWallet);
  return userWallet;
};

export const getLucid = async (isPreProd) => {
  const walletName = store.getState().user.adaWallet;
  console.log(walletName);

  if (!walletName) {
    store.dispatch(
      createNotification("error", "Please connect your wallet", 4000)
    );
  }

  const lucid = await initializeLucid(
    await window.cardano[walletName].enable(),
    isPreProd
  );
  return lucid;
};

export const signMessage = async (message, isPreProd) => {
  const lucid = await getLucid(isPreProd);
  const userWallet = await getUserWallet(isPreProd);

  const signedMessage = await lucid.newMessage(userWallet, message).sign();

  return signedMessage;
};

export const getPublicKeyHash = async () => {
  const userWallet = await getUserWallet();
  const pkh = C.Address.from_bech32(userWallet)
    .as_base()
    ?.payment_cred()
    .to_keyhash()
    ?.to_hex();
  return pkh;
};
