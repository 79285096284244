import React, { useEffect, useState } from "react";
import { Row, Box, Column, WalletModalButton } from "../../index";
import { connect } from "react-redux";
import closeIcon from "../../../assets/wallets/x.png";
import logo from "../../../assets/logo/logo.svg";
import exportIcon from "../../../assets/wallets/export.svg";
import infoIcon from "../../../assets/wallets/info.svg";
import {
  createNotification,
  setError,
  setNotifyModal,
  startAction,
  stopAction,
} from "../../../store/actions/uiActions";
import namiIcon from "../../../assets/wallets/nami.svg";
import flintIcon from "../../../assets/wallets/flint.svg";
import NufiIcon from "../../../assets/wallets/nufi_trans.png";
import eternlIcon from "../../../assets/wallets/eternl_trans.png";
import Button from "../../Button/Button";
import { Loader } from "../..";
import { Modal as ReactResponsiveModal } from "react-responsive-modal";
import { setAdaUser } from "../../../store/actions/userActions";
import {
  checkIfWalletEnabled,
  enableWallet,
  pollWallets,
} from "../../../utils/ada/connectors";
import { getUserWallet } from "utils/ada/lucid";
import { addWallets } from "utils/ada/addWallets";
import { Text, LazyImage } from "components";

const NotifyModal = ({
  setNotifyModal,
  createNotification,
  setAdaUser,
  startAction,
  stopAction,
  loading,
  network,
  walletModal,
  poolsData,
}) => {
  const [availableAdaWallets, setAvailableAdaWallets] = useState([]);
  const [enabledWallets, setEnabledWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState();
  const [checkboxIsSelected, setCheckboxIsSelected] = useState(false);
  const [checkboxHasError, setCheckboxHasError] = useState(false);

  const walletIcons = {
    nami: namiIcon,
    nufi: NufiIcon,
    eternl: eternlIcon,
    flint: flintIcon,
    // Add more wallet icons as needed
  };

  useEffect(() => {
    pollWallets(0, setAvailableAdaWallets);
    const wallets = [];
    for (const key in window.cardano) {
      if (window.cardano[key].enable && wallets.indexOf(key) === -1) {
        wallets.push(key);
      }
    }
    console.log(wallets);

    const allWallets = ["nami", "nufi", "eternl", "flint"];
    //setEnabledWallets(allWallets);
    const filteredWallets = wallets.filter((wallet) =>
      allWallets.includes(wallet)
    );
    console.log(filteredWallets);
    setEnabledWallets(filteredWallets);
  }, []);

  console.log(enabledWallets);

  const connectAdaWallet = async (walletKey) => {
    if (!availableAdaWallets.includes(walletKey)) {
      createNotification(
        "error",
        `We couldn't find this wallet in your current browser`,
        4000
      );
      return;
    }

    try {
      startAction();
      const walletProvider = await enableWallet(walletKey);

      if (walletProvider && (await checkIfWalletEnabled(walletKey))) {
        setAdaUser(null, null, walletKey);
        const signerAddress = await getUserWallet(network === "pre");
        //await addWallets(signerAddress, poolsData, network === "pre");
        setAdaUser(signerAddress, null, walletKey);
        setNotifyModal(false);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      stopAction();
    }
  };

  return (
    <ReactResponsiveModal
      center
      classNames={{
        // overlay: "customOverlay",
        // modal: "customModal",
        background: "transparent"
      }}
      open={walletModal}
      onClose={() => setNotifyModal(false)}
      //closeIcon={closeIcon}
    >
      <Box className="wallet-modal">
        {/* {loading ? (
          <div style={{ width: "20%" }}>
            <Loader loadState={loading} />
          </div>
        ) : ( */}
          <>
       
                <Row
                  className="wallet-modal__header"
                  style={{ display: "block" }}
                >
                  {/* <img
                    onClick={() => setWalletModal(false)}
                    className="wallet-modal__close"
                    src={closeIcon}
                    alt="close-icon"
                  /> */}

                  <Column>
                    <Text
                      style={{
                        "text-align": "center",
                        color: "#FFF",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 27,
                        "font-style": "normal",
                        "font-weight": 600,
                        "line-height": "normal",
                        marginTop: 10,
                        marginBottom: 8,
                      }}
                    >
                    Under Maintainance
                    </Text>
                 
                  </Column>
                </Row>
                <Column center className="wallet-column">
                  <Text style={{marginTop: 20,     color: "white",
    fontSize: 15,
    textAlign: "center"}}>We're currently performing maintenance on our pools. They will be back online soon. Thank you for your patience!</Text>
                 

                 
                  <Row
                    onClick={() => {
                     setNotifyModal(false)
                    }}
                    style={{
                      cursor: "pointer",
                      marginTop: "20px",
                      marginBottom: "10px",
                      width: "95%",
                      padding: 7,
                      "text-align": "center",
                      display: "block",
                      borderRadius: 4,
                    
                      backgroundColor: "#f83472",
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 15,
                        "font-style": "normal",
                        "font-weight": 500,
                        "line-height": "normal",
                      }}
                    >
                      Ok!
                    </Text>
                  </Row>
                  {/* <Text style={{ color: "white", fontSize: 14, marginTop: 20 }}>
                    *Must turn on Single Address mode in settings
                  </Text> */}
                </Column>
              </>
           
     
        {/* )} */}
      </Box>
    </ReactResponsiveModal>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ui.loading,
    network: state.network.network,
    poolsData: state.pool.poolsData,
  };
};

export default connect(mapStateToProps, {
  setError,
  setNotifyModal,
  createNotification,
  setAdaUser,
  startAction,
  stopAction,
})(NotifyModal);
