import { Text, Column, Row, Button, LazyImage } from "components";
import React from "react";
import { connect } from "react-redux";
import { createNotification } from "store/actions/uiActions";

const NftLaunchpadModel = ({ setIsRedeemProcess, setBuyModalIsDisplayed, createNotification }) => {
  return (
    <Column className="nft-launchpad-modal">
      <Column>
        <Text className="token-name" text="Collection: Non-Alcoholic Beer" />
        <Text className="product-name" text="Tangent Protocol <> Gweilo Beer" />
        <Text className="product-name" text="Limited Edition" />

      </Column>
      <Row className="choose-container">
        <Column>
          <Row align>
            <Text className="price" text="20 ADA" />
            <Text
              style={{ marginLeft: "1rem" }}
              className="currency"
              text="($11,10 USD)"
            />
          </Row>
        </Column>
      </Row>

      <Column className="balance-container">
        <Row align spaceBetween>
          <Text className="currency" text="ADA Balance" />
          <Text className="unit" text="557.252344 ADA" />
        </Row>
        <Row align spaceBetween>
          <Text className="currency" text="BEER Balance" />
          <Text className="unit" text="2 BEER" />
        </Row>
      </Column>
      <div className="divider" />
      <Column className="balance-container">
        <Row align spaceBetween>
          <Text className="currency" text="Buy For" />
          <Text className="unit" text="31.25234445 ADA" />
        </Row>
        <Row align spaceBetween>
          <Text className="currency" text="Sell For" />
          <Text className="unit" text="30.98635675 ADA" />
        </Row>
      </Column>
      <div className="divider" />
      <Column className="balance-container">
        <Row align spaceBetween>
          <Text className="currency" text="Network Fee" />
          <Text className="unit" text="0.0008 ADA" />
        </Row>
      </Column>
      <Button
        className="buy-button"
        onClick={() => {
          createNotification(
            "info",
            `Coming Soon`,
            6000
          )
          return;
          setIsRedeemProcess(false);
          setBuyModalIsDisplayed(true);
        }}
      >
        Buy with ADA
      </Button>
      <Button
        className="redeem-button"
        onClick={() => {
          createNotification(
            "info",
            `Coming Soon`,
            4000
          );
          return;
          setIsRedeemProcess(true);
          setBuyModalIsDisplayed(true);
        }}
      >
        Redeem
      </Button>
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
   
  };
};

export default connect(mapStateToProps, { createNotification })(NftLaunchpadModel);
