import { useState } from "react";
import { Column, Text, H1, Button, ClickableFormInput } from "components";
import "./quantity.scss";

const Quantity = ({ closeModal, forth, back }) => {
  const [value, setValue] = useState(0);
  return (
    <Column className="launchpad-quantity-modal">
      <H1
        text="Quantity"
        style={{
          margin: "1rem 0",
        }}
      />
      <Text
        style={{
          margin: "1rem 0",
          color: "#a3a8ac",
          fontSize: "13px",
        }}
      >
        Please select how many NFTs to buy.
      </Text>

      <ClickableFormInput
        type="number"
        className="input"
        value={value}
        min={0}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        upButtonFunction={() => {
          setValue(value + 1);
        }}
        downButtonFunction={() => {
          setValue(value - 1);
        }}
      />
      <Text
        text={`Available 978 / 1000`}
        style={{ color: "#a3a8ac", fontSize: "12px" }}
      />

      <Button
        style={{
          margin: "2rem 0",
        }}
        className="buy-button"
        onClick={() => forth()}
      >
        BUY
      </Button>
    </Column>
  );
};

export default Quantity;
