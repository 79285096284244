import React, { useState } from "react";
import {
  Header,
  NftLaunchpadModal,
  Text,
  Column,
  NftBuyLaunchpadModal,
} from "../../components";
import { ImageSlider } from "./components/ImageSlider";
import { connect } from "react-redux";
import beer from "assets/nftLaunchpad/beer-min.gif";
import poweredByNmkr from "assets/powered-by.svg";
import LoadLogo from "assets/tang_logo_animated.svg";

const NftLaunchpad = () => {
  const [buyModalIsDisplayed, setBuyModalIsDisplayed] = useState(false);
  const [isRedeemProcess, setIsRedeemProcess] = useState();
  const [orderData, setOrderData] = useState([1]);

  const closeModal = () => setBuyModalIsDisplayed(false);

  return (
    <>
          <div className="launchpadloader">
        <div className="launchpadloadContainer">
          <img className="loadLogo" src={LoadLogo} alt="Loading" />
          <p className="loadText">
          Coming Soon
            <span className="animText">
              <span style={{ "--i": 1 }}>.</span>
              <span style={{ "--i": 2 }}>.</span>
              <span style={{ "--i": 3 }}>.</span>
            </span>
          </p>
        </div>
      </div>
      <div className="nft-launchpad-page">
      {buyModalIsDisplayed && (
        <NftBuyLaunchpadModal
          isRedeemProcess={isRedeemProcess}
          closeModal={closeModal}
          orderData={orderData}
          productIcon={beer}
        />
      )}
      <div className="nft-launchpad__slider">
        <div style={{ marginBottom: "2rem" }} className="nft-launchpad__title">
          <Text className="token-name" text="Collection: Non-Alcoholic Beer" />
          <Text className="product-name" text="Gweilo x Tangent Limited Edition" />
        </div>
        <ImageSlider src={beer}/>
      </div>
      <Column className="nft-launchpad">
        <Column>
          <NftLaunchpadModal
            setIsRedeemProcess={setIsRedeemProcess}
            setBuyModalIsDisplayed={setBuyModalIsDisplayed}
          />
          <Column center>
            {/* <img
              style={{
                height: "38px",
                width: "160px",
                marginTop: "2rem",
              }}
              src={poweredByNmkr}
              alt="Powered by NMKR"
            /> */}
          </Column>
        </Column>
      </Column>
    </div>
    </>

  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(NftLaunchpad);
