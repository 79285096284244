import React, { useState, useEffect } from "react";
import { store } from "../../index";
import axios from "axios";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Range, getTrackBackground } from "react-range";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowSize } from "utils/windowSizeHook";
import { useHistory } from "react-router-dom";
import { Column, H2, Header, Row, Text, NftCard } from "../../components";
import {
  startAction,
  stopAction,
} from "store/actions/uiActions";

//Import assets
import NFTImage from "../../assets/nfts/BashoSilver.png";
import BackArrow from "../../assets/nftDetail/arrow-left.svg";
import arrowDown from "../../assets/arrow-down.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

// import required modules
import { FreeMode, Pagination, Autoplay } from "swiper";

// import Mock data
// import { mockData } from "./mockData";

ChartJS.register(ArcElement, Tooltip, Legend);

const NftDetail = () => {
  const history = useHistory();
  const windowSize = useWindowSize();

  const [stamvalue, setStamvalue] = useState([30]);
  const [lvlvalue, setLvlvalue] = useState([4]);
  const [slidesView, setSlidesView] = useState(4);
  const [nftData, setNftData] = useState(null);

  const {fingerPrint} = useParams();

  const getData = async () => {
    // let { assetId } = useParams();
     const assetResponse = await axios.get(`https://cardano-mainnet.blockfrost.io/api/v0/assets/${fingerPrint}`, {//
            headers: {
              project_id: 'mainnetzlLTysAcRwAtzsmzuIPUTAmR8LRGNDzG',
            },
          });
          // console.log('here',assetResponse.data)
          setNftData(assetResponse.data);


        //   if(assetResponse.data.onchain_metadata.image){//assetResponse.data.onchain_metadata.collection){
        //     nftAssets.push({
        //       policy_id: assetResponse.data.policy_id,
        //       fingerprint: assetResponse.data.fingerprint,
        //       name: assetResponse.data.onchain_metadata.character,
        //       collection: assetResponse.data.onchain_metadata.collection,
        //       edition: assetResponse.data.onchain_metadata.edition,
        //       unit: asset.unit,
        //       quantity: asset.quantity,
        //       txHash: utxo.tx_hash,
        //       tokenName: assetResponse.data.asset_name,
        //       outputIndex: utxo.output_index,
        //       image: assetResponse.data.onchain_metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/")
        //     });
        //  }
    store.dispatch(stopAction());
  }

  useEffect(() => {
    store.dispatch(startAction());
    getData();
    if (windowSize[0] > 1520) setSlidesView(4);
    if (windowSize[0] < 1520 && windowSize[0] > 1200) setSlidesView(3);
    if (windowSize[0] < 1200 && windowSize[0] > 900) setSlidesView(2);
    if (windowSize[0] < 900) setSlidesView(1);
    // setTimeout(() => store.dispatch(stopAction()), 3000);
    
  }, [windowSize]);

  const DetailProps = ({ title, status, percent }) => {
    return (
      <Column className="nft-detail__section__info__details__body__prop">
        <Text text={title} style={{ color: "#fff", fontWeight: 600 }} />
        <Text text={status} style={{ color: "#fff", paddingTop: "10px" }} />
        <Text
          text={percent + "% have this property"}
          style={{ fontSize: "13px", color: "#a3a8ac" }}
        />
      </Column>
    );
  };
  //let { fingerPrint } = useParams();
  return (
    
    <div>
      <Column className="nft-detail">
        <Column className="response">
          <Column className="">
            <Row
              className="nft-detail__back"
              onClick={() => {
                history.push("/my-nft");
              }}
            >
              <img src={BackArrow} />
              <Text text="Back" className="nft-detail__back__text" />
            </Row>
          </Column>
          {nftData ? 
          <div className="nft-detail__section">
            <div>
              <img src={nftData.onchain_metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/")} className="nft-detail__section__image" />
            </div>
            <Column className="nft-detail__section__info">
              <Column className="nft-detail__section__info__main">
                <Text
                  text={nftData.onchain_metadata.character}
                  className="nft-detail__bigtext"
                  style={{ fontSize: "27px", fontWeight: 600 }}
                />
                <Text
                  text={nftData.onchain_metadata.edition}
                  className="nft-detail__smalltext"
                  style={{ fontSize: "13px" }}
                />
                <Text
                  className="nft-detail__section__intro"
                  text="Tangent will disrupt art and financial markets by creating a launchpad for the issuance of innovative NFTs back by tangible and intangible assets and financial services"
                />

                <Row className="nft-detail__section__info__unique">
                  <Text
                    text="Policy Id"
                    className="nft-detail__section__info__unique__title"
                    style={{ fontSize: "13px" }}
                  />
                  <div className="dashline"></div>
                  <div className="nft-detail__section__info__unique__idcontainter">
                    <Text
                      text={nftData.policy_id}
                      style={{
                        color: "#ff6e9c",
                        fontSize: "13px",
                        wordBreak: `${
                          windowSize[0] < 1520 ? "break-all" : "normal"
                        }`,
                        textAlign: "right",
                      }}
                    />
                  </div>
                </Row>
                <Row className="nft-detail__section__info__unique">
                  <Text
                    text="Fingerprint"
                    className="nft-detail__section__info__unique__title"
                    style={{ fontSize: "13px" }}
                  />
                  <div className="dashline"></div>
                  <div className="nft-detail__section__info__unique__idcontainter">
                    <Text
                      text={nftData.fingerprint}
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        wordBreak: `${
                          windowSize[0] < 1024 ? "break-all" : "normal"
                        }`,
                        textAlign: "right",
                      }}
                    />
                  </div>
                </Row>
                <div className="divider" />

                {/* <Row className="nft-detail__section__info__price">
                  <Text
                    text="0.0042"
                    style={{ color: "#fff", fontSize: "27px", fontWeight: 600 }}
                  />
                  <Text
                    text="TANG"
                    style={{ color: "#fff", paddingLeft: "10px" }}
                  />
                </Row> */}
              </Column>
              {/* <div className="nft-detail__section__info__details">
                <Accordion
                  style={{
                    borderRadius: "20px",
                    background: "#2b3740",
                    padding: "0 40px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<img src={arrowDown} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      background: "#2b3740",
                      borderRadius: "20px",
                      padding: "5px 0",
                    }}
                  >
                    <div style={{ background: "#2b3740" }}>
                      <Text
                        text="NFT Details"
                        style={{ color: "#a3a8ac", fontSize: "13px" }}
                      />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      background: "#2b3740",
                      borderRadius: "20px",
                      padding: "8px 0px 16px",
                    }}
                  >
                    <Column className="nft-detail__section__info__details__body">
                      <Text
                        text="Properties"
                        style={{
                          color: "#fff",
                          fontSize: "21px",
                          fontWeight: 600,
                        }}
                      />
                      <div className="nft-detail__section__info__details__body__props">
                        <DetailProps
                          title="Personality"
                          status="Sad"
                          percent={29}
                        />
                        <DetailProps
                          title="Mouth"
                          status="Surprised"
                          percent={29}
                        />
                        <DetailProps title="Eyes" status="Big" percent={20} />
                        <DetailProps
                          title="Base"
                          status="Surprised"
                          percent={20}
                        />
                      </div>
                      <div className="divider" />
                      <Text
                        text="Ranking"
                        style={{
                          color: "#fff",
                          fontSize: "21px",
                          fontWeight: 600,
                          marginTop: "30px",
                          marginBottom: "20px",
                        }}
                      />
                      <Column>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            flexWrap: "wrap",
                            width: "100%",
                          }}
                        >
                          <Text
                            text="Stamina"
                            style={{ fontSize: "13px", color: "#fff" }}
                          />
                          <Range
                            values={stamvalue}
                            step={1}
                            min={0}
                            max={90}
                            onChange={() => {}}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: "36px",
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "15px",
                                    width: "100%",
                                    borderRadius: "7px",
                                    border: "solid 2px #54585b",

                                    background: getTrackBackground({
                                      values: stamvalue,
                                      colors: ["#f83472", "#1a2730"],
                                      min: 0,
                                      max: 90,
                                    }),
                                    alignSelf: "center",
                                  }}
                                >
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "42px",
                                  width: "42px",
                                  borderRadius: "4px",
                                  backgroundColor: "transparent",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                className="staking-modal__slider-handle"
                              >
                                <Text
                                  className="nft-detail__slider-text"
                                  style={{ color: "#ff6e9c" }}
                                >
                                  {stamvalue[0]}
                                </Text>
                              </div>
                            )}
                          />
                          <Row
                            style={{
                              width: "100%",
                              color: "#a3a8ac",
                              justifyContent: "space-between",
                              marginBottom: "1.5rem",
                            }}
                          >
                            <Text>0 </Text>
                            <Text>90</Text>
                          </Row>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            flexWrap: "wrap",
                            width: "100%",
                          }}
                        >
                          <Text
                            text="Level"
                            style={{ fontSize: "13px", color: "#fff" }}
                          />
                          <Range
                            values={lvlvalue}
                            step={1}
                            min={0}
                            max={8}
                            onChange={() => {}}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: "36px",
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "15px",
                                    width: "100%",
                                    borderRadius: "7px",
                                    border: "solid 2px #54585b",
                                    background: getTrackBackground({
                                      values: lvlvalue,
                                      colors: ["#4ba3cf", "#1a2730"],
                                      min: 0,
                                      max: 8,
                                    }),
                                    alignSelf: "center",
                                  }}
                                >
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "42px",
                                  width: "42px",
                                  borderRadius: "4px",
                                  backgroundColor: "transparent",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                className="staking-modal__slider-handle"
                              >
s                                <Text className="nft-detail__slider-text">
                                  {lvlvalue[0]}
                                </Text>
                              </div>
                            )}
                          />
                        </div>

                        <Row
                          style={{
                            width: "100%",
                            color: "#a3a8ac",
                            justifyContent: "space-between",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Text>0 </Text>
                          <Text>8</Text>
                        </Row>
                      </Column>
                      <div className="divider" />
                      <Text
                        text="Stats"
                        style={{
                          color: "#fff",
                          fontSize: "21px",
                          fontWeight: 600,
                          marginTop: "30px",
                          marginBottom: "10px",
                        }}
                      />
                      <Row style={{ alignItems: "baseline" }}>
                        <Text
                          text="2"
                          style={{
                            fontSize: "27px",
                            color: "#ff6e9c",
                            fontWeight: 600,
                          }}
                        />
                        <Text
                          text="Generation"
                          style={{ color: "#fff", paddingLeft: "1rem" }}
                        />
                        <Text
                          text="Out of 2"
                          style={{
                            fontSize: "13px",
                            color: "#a3a8ac",
                            paddingLeft: "0.8rem",
                          }}
                        />
                      </Row>
                    </Column>
                  </AccordionDetails>
                </Accordion>
              </div> */}
            </Column>
          </div> : <div></div>}
        </Column>
        {/* <Column className="nft-detail__otherNft">
          <Text
            text="Other NFT"
            style={{
              fontSize: "21px",
              color: "#fff",
              fontWeight: 600,
              textAlign: "center",
              marginBottom: "1.5rem",
            }}
          />
          <Swiper
            grabCursor={true}
            slidesPerView={slidesView}
            spaceBetween={30}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            {mockData.map((nft, index) => {
              if (index < 6)
                return (
                  <SwiperSlide>
                    <NftCard
                      image={nft.image}
                      title={nft.title}
                      tokenName={nft.tokenName}
                      contractAddress={nft.contractAddress}
                      cardType={2}
                    />
                  </SwiperSlide>
                );
            })}
          </Swiper>
        </Column> */}
      </Column>
    </div> 
  );
};

export default NftDetail;
