import { Column, Text, Row } from "components";
import { connect } from "react-redux";
import React, { useState } from 'react';
import "./pool-card.scss";
import moment from "moment";
import { useWindowSize } from "utils/windowSizeHook";
import Loader from "react-js-loader";
import Button from "../../../components/Button/Button"
import { useHistory } from "react-router-dom";
import { CountUpNumber } from "../../../components";
import { takeRewards } from "utils/ada/takeRewards";
import exportIcon from "../../../assets/export.png";
import lockIcon from "../../../assets/lockIcon.svg";
import lockedIcon from "../../../assets/lockedIcon.svg";
import bigInt from "big-integer";
import expandedIcon from "../../../assets/expandedIcon.svg";
import collapsedIcon from "../../../assets/collapsedIcon.svg";
import { setUsersRewards } from "store/actions/poolActions";
import { createNotification } from "store/actions/uiActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

function formatNumberWithSuffix(number) {
  if (typeof number !== 'number') {
    return number; // Return as is if it's not a number
  }

  if (number >= 1e15) {
    return (number / 1e15).toFixed(2) + ' Q';
  } else if (number >= 1e12) {
    return (number / 1e12).toFixed(2) + ' T';
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(2) + ' B';
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(2) + ' M';
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(2) + ' K';
  } else {
    return number.toFixed(2);
  }
}

const LiquidityCardItem = ({ image, title, userTokenAmount, adaWallet, openModal, APY, stakedTokens, getToken, earn, decimal, stakingUnit, stakingWallet, tokenContract, poolRewards, userStakedTokens, poolId, closesAt, distributionWallet, network, userRewards, createNotification, setUsersRewards }) => {
  const history = useHistory();
  const userStakedToken = userStakedTokens ? userStakedTokens[poolId] : 0;
  const userShareOfPool = (userStakedToken * 100) / stakedTokens;
  const [expanded, setExpanded] = useState(false);
  const [mouseOverLock, setMouseOverLock] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [stakeIsLoading, setStakeIsLoading] = useState(false);
  const [unstakeIsLoading, setUnstakeIsLoading] = useState(false);

  const windowSize = useWindowSize();

  const handleIconClick = () => {
    setMouseOverLock(!mouseOverLock);
  };

  const handleIconMouseOver = () => {
    setMouseOver(true);
  };


  const handleIconMouseLeave = () => {
    setMouseOver(false);
  };


  const handleMouseEnter = () => {
    if (!mouseOverLock) {
      setExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (!mouseOverLock) {
      setExpanded(false);
    }
  };



  const rewards = userRewards ? userRewards[poolId] : poolRewards;

  const claim = async () => {
    // return;
    let hasRewardsToClaim = false;
    rewards?.map((reward) => {
      if (reward.amount > 0) {
        hasRewardsToClaim = true;
      }

      return reward;
    });
    if (!hasRewardsToClaim) {
      createNotification(
        "error",
        "You do not have any rewards in this pool",
        4000
      );
      return;
    }
    try {
      setIsLoading(true);
      await takeRewards(poolId, distributionWallet, network == "pre");
      setTimeout(async () => setUsersRewards(network == "pre"), 1000);
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div>
      <Column className="pool-card" style={{marginBottom: windowSize[0] < 600 ? 20 : 0}}>

        <div className="image-text-row">
          <img src={image} alt="Item" className="item-image" />
          <div className="details-space-small"></div>

          <div className="item-details">
            <div className="item-text">
              <span className="main-text">Yield</span>
            </div>
            <div className="item-subtext">
              <span className="sub-text-apy">
              {formatNumberWithSuffix(APY)}%
                {/* <CountUpNumber
                  startValue={(fixedAPY)}
                  end={(fixedAPY)}
                  decimals={0}
                  suffix={"%"}
                /> */}
              </span>
            </div>
          </div>
          <div className="details-space"></div>
          <div className="item-details">
            <div className="item-text">
              <span className="main-text">Staked</span>
            </div>
            <div className="item-subtext">
              <span className="sub-text">{userStakedToken/(10 ** decimal) }</span>
            </div>
          </div>
        </div>

        <div className="nft-details" style={{ width: '100%' }}>
          <Text
            size="head"
            style={{ fontFamily: "poppins-regular, sans-serif" }}
            text={title}
            className="pool-title"
          />
          <Row
            style={{
              "borderBottom": "1.5px solid rgba(225, 226, 230, 0.15)",
              marginTop: 15,
              marginBottom: 20,
              width: '100%'
            }}>
          </Row>
          <div className="info-row">
            <div className="info-title">Total Value Locked</div>
            <div className="info-value">{stakedTokens/(10**decimal)}{" "}{title}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Your % of the Pool</div>
            <div className="info-value"> <CountUpNumber
              className="card-liquidity-item__percentage"
              startValue={0}
              end={userShareOfPool}
              suffix={"%"}
              decimals={2}
            /></div>
          </div>
          <div className="info-row">
            <div className="info-title"> Pool Fee</div>
            <div className="info-value">
              <CountUpNumber
                className="card-liquidity-item__percentage"
                startValue={0}
                end={0.5}
                suffix={"%"}
                decimals={2}
              />
            </div>
          </div>
          <div className="info-row">
            <div className="info-title">Pool End Date</div>
            <div className="info-value">{moment(closesAt).format("YYYY-MM-DD")}</div>
          </div>
          <Column className="card-liquidity-item__buttonGroup">
            <Row style={{ width: "100%", justifyContent: "space-between" }}>

              {/* {stakeIsLoading ? (
                  <Loader
                    type="spinner-default"
                    title=""
                    bgColor={"#d02315"}
                    size={50}
                  />
                ) : ( */}
              {stakeIsLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (<Button
                // href="#"
                // onClick={async () => {
                //   if (minutesUntilPoolIsClosing === 0) {
                //     createNotification(
                //       "error",
                //       "Pool is already closed",
                //       4000
                //     );
                //   } else {
                //     await stake(setStakeIsLoading);
                //   }
                // }}
                onClick={async () => {
                  if (adaWallet) {
                    if (title.toLowerCase().includes("mithr")){
                      createNotification("error", "MITHR staking is not available", 4000);
                        return;
                    } else {
                    openModal(
                      "staking",
                      title,
                      earn,
                      stakingUnit,
                      stakingWallet,
                      bigInt(userTokenAmount[stakingUnit]) / bigInt(10 ** decimal),
                      poolId,
                      distributionWallet,
                      setStakeIsLoading,
                      decimal
                    );
                    }
                  } else {
                    createNotification("error", "Please connect your wallet", 4000);
                  }
                }}
                className="stake-button"
                style={{ width: "100% !important", marginRight: 5 }}
              >
                Stake
              </Button>)}
              {/*  */}
              {/* {unstakeIsLoading ? (
                  <Loader
                    type="spinner-default"
                    title=""
                    bgColor={"#d02315"}
                    size={50}
                  />
                ) : ( */}
              {unstakeIsLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <Button
                  // href="#"
                  onClick={() => {
                    if (adaWallet) {

                      if (userStakedToken > 0) {
                        openModal(
                          "unstaking",
                          title,
                          earn,
                          stakingUnit,
                          stakingWallet,
                          userStakedToken / 10 ** decimal,
                          poolId,
                          distributionWallet,
                          setUnstakeIsLoading,
                          decimal
                        );
                      } else {
                        createNotification(
                          "error",
                          "You don't have any tokens staked",
                          4000
                        );
                      }
                      // }
                    } else {
                      createNotification("error", "Please connect your wallet", 4000);
                    }
                  }}
                  className="stake-button"
                  style={{ width: "100% !important", marginRight: 0 }}
                >
                  Unstake
                </Button>)}
              {/* )} */}
              {/* {isLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <Button
                  onClick={claim}
                  text="Claim"
                  className="claim-button"
                  style={{
                    width: "100px",
                    height: "35px",
                    fontSize: "15px !important",
                  }}
                />
              )} */}
            </Row>
            <Row style={{ marginTop: "15px", alignItems: "center" }} >
              <a
                className="card-liquidity-item__link"
                // overflow: "hidden",
                // display: "-webkit-box",
                // "-webkit-line-clamp": 1, 
                //         "line-clamp": 1,
                // "-webkit-box-orient": "vertical"
                href={tokenContract}
                target="_blank"
                rel="noreferrer"
              >
                View Farm Contract
              </a>
              <img
                src={exportIcon}
                alt="NFT"
                className="liquidity-item__image"
                style={{
                  width: "15px",
                  height: "15px",
                  marginRight: "15px"
                }}
              />
              <a
                className="card-liquidity-item__link"

                href={getToken}
                target="_blank"
                rel="noreferrer"
              >
                Get {title} token
              </a>
              <img
                src={exportIcon}
                alt="NFT"
                className="liquidity-item__image"
                style={{
                  width: "15px",
                  height: "15px",
                }}
              />
            </Row>
          </Column>
           <div
           onMouseEnter={handleMouseEnter}
           onMouseLeave={handleMouseLeave}
           className={`reward-component ${expanded ? 'expanded' : ''}`}
           style={{ border: `1px solid ${expanded ? "#F83472" : "#4C565D"}` }}
         >
            <div className="reward-header">
              <Row className="expanded-liquidity-item__rowSpace">
                <Text
                  style={{
                    color: "#fff",
                    //marginRight: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Rewards:
                </Text>


                <Row style={{
                  position: "absolute",
                  right: 40
                }}>
                  {rewards &&
                    rewards.map((reward, index) => {
                      return (
                        <Column
                          style={{
                            paddingRight: index == rewards.length - 1 ? 20 : 5,
                          }}
                        >
                          <Row
                            key={index}
                            // style={{
                            //   paddingRight: index == rewards.length - 1 ?  30: 10,
                            // }}
                            className={`${"expanded-liquidity-item__first-rewardText"}`}
                          >
                            <img
                              src={reward.rewardUnit.icon}
                              alt="NFT"
                              className="liquidity-item__image"
                              style={{
                                width: "23px",
                                marginRight: 2
                              }}
                            />

                            <Text
                              className="expanded-liquidity-item__rewardText__unitText"
                              style={{
                                fontSize: "13px",
                                marginRight: 10,

                              }}
                            >
                              {reward.rewardUnit.name}
                            </Text>


                          </Row>

                          <Text
                            style={{
                              display: expanded ? "block" : "none",
                              textAlign: "right",
                              color: "#fff",
                              fontWeight: "bold",
                              fontSize: 18,
                              marginRight: 20,
                            }}
                          >
                            {parseFloat(reward.amount).toFixed()}
                          </Text>
                        </Column>

                      );
                    })}
                  {/* <button
                    className={`show-more-button`}
                    onClick={handleIconClick}
                    style={{ width: 25, height: 25, margin: 1, backgroundColor: "transparent" }}
                  >
                    {expanded ? (
                      <img
                        src={expandedIcon} />
                    ) : (
                      <img
                        src={collapsedIcon} />

                    )}
                  </button> */}
                          <button
          className={`show-more-button`}
          onClick={handleIconClick}
          onMouseEnter={handleIconMouseOver}
          onMouseLeave={handleIconMouseLeave}
          style={{ width: 25, height: 25, margin: 1, backgroundColor: "transparent" }}
        >
          {expanded ? (
            <img
              src={mouseOverLock ? lockIcon : lockedIcon } 
            
            />
          ) : (
            <img
              src={collapsedIcon}
            />
          )}
        </button>
                </Row>
              </Row>
            </div>
            {expanded && (
              <div className="reward-content" style={{ paddingTop: 25 }}>

                {isLoading ? (
                  <Loader
                    type="spinner-default"
                    title=""
                    bgColor={"#d02315"}
                    size={50}
                  />
                ) : (
                  <Button
                    onClick={claim}
                    text="Claim"
                    className="claim-button"
                    style={{
                      width: "100%",
                      height: "35px",
                      fontSize: "15px !important",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Column>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    userStakedTokens: state.pool.userStakedTokens,
    userRewards: state.pool.userRewards,
    userTokenAmount: state.user.userTokenAmount,
    network: state.network.network,
  };
};

export default connect(mapStateToProps, { createNotification, setUsersRewards, })(LiquidityCardItem);;
