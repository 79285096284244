import { Column, Button, H1, Text } from "components";
import "./confirmation.scss";
import confirmationIcon from "assets/nftLaunchpad/confirmation.svg";

const Confirmation = ({ closeModal, forth, back, isRedeemProcess }) => {
  return (
    <Column className="launchpad-confirmation-modal">
      <img src={confirmationIcon} alt="confirmation icon" style={{width: "120px"}}/>
      <H1
        style={{
          margin: "2rem 0",
        }}
        text="Congratulations!"
      />
      <Text
        style={{color: "#fff"}}
        text={`you successfully ${isRedeemProcess ? "redeemed" : "purchased"}`}
      />
      <Text
        style={{
          margin: "2rem 0",
          color: "#ff6e9c",
          fontSize: "21px"
        }}
        className="red-confirmation-text"
        text="$BEER Tangent Craft"
      />
      <Button className="buy-button" onClick={() => closeModal()} style={{marginTop: "8rem"}}>
        Okay
      </Button>
    </Column>
  );
};

export default Confirmation;
