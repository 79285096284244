import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useWindowSize } from "utils/windowSizeHook";
import logo from "../../assets/logo/logo.svg";
import logo2 from "../../assets/logo/logo@2x.svg";
import mainLogo from "../../assets/tangent-icon.svg";
import logo3 from "../../assets/logo/logo@3x.svg";
import liquidity from "../../assets/navigation/liquidity-icon.svg";
import swap from "../../assets/navigation/swap.svg";
import nft from "../../assets/navigation/nft-icon.svg";
import launchpad from "../../assets/navigation/launchpad-icon.svg";
import orders from "../../assets/navigation/orders.svg";
import magicIcon from "../../assets/magic_color.png";
import namiIcon from "../../assets/wallets/nami.svg";
import flintIcon from "../../assets/wallets/flint.svg";
import copyIcon from "../../assets/wallets/copy.svg";
import NufiIcon from "../../assets/wallets/nufi_trans.png";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eternlIcon from "../../assets/wallets/eternl_trans.png";
import { Modal } from "../../components/Modals/Modal/Modal";
import Button from "../Button/Button";
import bigInt from "big-integer";
import NetworkSelector from "components/NetworkSelector/NetworkSelector";
import { Row, Column, Text, CountUpNumber, LazyImage } from "../index";
import {
  createNotification,
  setWalletModal,
  startAction,
  stopAction,
} from "../../store/actions/uiActions";
import tangIcon from "../../assets/tokenStaking/tang.svg";
import adaIcon from "../../assets/tokenStaking/ada.svg";
import { Link, useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { disconnectUserAccount } from "../../store/actions/userActions";
// import connectIcon from "../../assets/wallet/connect.svg";
import connectIcon from "../../assets/Wallet.svg";
import { NETWORK } from "utils/constants";

import disconnectIcon from "../../assets/wallet/disconnect.svg";
import walletIcon from "../../assets/wallets/wallet.svg";
import { Box, Tabs, Tab } from "@mui/material";

const Header = ({
  signerAddress,
  setWalletModal,
  startAction,
  tangPrice,
  userTokenAmount,
  createNotification,
  stopAction,
  adaWallet,
  userCredit,
  disconnectUserAccount,
}) => {
  const { deactivate } = useWeb3React();
  const windowSize = useWindowSize();
  const history = useHistory();
  const [currentSite, setCurrentSite] = useState();
  const [value, setValue] = useState(1);
  const [chkbox, setChkbox] = useState(false);
  const [userInfoBoxIsOpen, setUserInfoBoxIsOpen] = useState(false);
  const [connectBtnIcon, setConnectBtnIcon] = useState(connectIcon);
  const [tangBalanceInUsd, setTangBalanceInUsd] = useState(0);
  const userInfoBoxRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState();
  const [modalEarn, setModalEarn] = useState();
  const [modalStakingUnit, setModalStakingUnit] = useState();
  const [modalStakingWallet, setModalStakingWallet] = useState();
  const [modalDistributionWallet, setModalDistributionWallet] = useState();
  const [stakingPoolId, setStakingPoolId] = useState();
  const [tokenDecimal, setTokenDecimal] = useState();
  const [amountOfStakedTokens, setAmountOfStakedTokens] = useState(1);
  const [setIsLoading, setSetIsLoading] = useState(false);

  const onCloseModal = () => {
    setOpen(false);
  };

  const walletIcons = {
    nami: namiIcon,
    nufi: NufiIcon,
    eternl: eternlIcon,
    flint: flintIcon,
    // Add more wallet icons as needed
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setChkbox(false);
  };
  const toggleChange = () => {
    setChkbox(!chkbox);
  };

  const handleOutsideClick = (event) => {
    if (
      userInfoBoxRef.current &&
      !userInfoBoxRef.current.contains(event.target)
    ) {
      setUserInfoBoxIsOpen(false);
    }
  };
  useEffect(() => {
    setTangBalanceInUsd(
      userTokenAmount
        ? (tangPrice * bigInt(userTokenAmount["TANG"])) / bigInt(1000000)
        : 0
    );
  }, [userTokenAmount]);

  useEffect(() => {
    if (userInfoBoxIsOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [userInfoBoxIsOpen]);

  useEffect(() => {
    let url = window.location.href;
    // if (url.includes("3000")) {
    //   // development
    //   url = url.split("3000/")[1];
    // } else {
    //   // production
    //   url = url.split("https://app.tangent.art/")[1];
    // }
    //setCurrentSite(url);
    if (url.includes("swap")) {
      setValue(0);
      // } else if (url.includes("my-nft")) {
      //   setValue(5);
    } else if (url.includes("earn")) {
      setValue(2);
    // } else if (url.includes("orders")) {
    //   setValue(3);
    // } else if (url.includes("nft-farm")) {
    //   setValue(2);
    } else if (url.includes("nft-launchpad")) {
      setValue(3);
    } else if (url.includes("ai-generator")) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, []);

  const onOpenModal = (
    name,
    title,
    earn,
    stakingUnit,
    stakingWallet,
    stakedTokens,
    poolId,
    distributionWallet,
    setIsLoading,
    decimal
  ) => {
    setModalType(name);
    setOpen(true);
    setModalTitle(title);
    setModalEarn(earn);
    setSetIsLoading(() => setIsLoading);
    setModalStakingUnit(stakingUnit);
    setModalStakingWallet(stakingWallet);
    setAmountOfStakedTokens(stakedTokens);
    setStakingPoolId(poolId);
    setModalDistributionWallet(distributionWallet);
    setTokenDecimal(decimal);

    // "staking",
    // title,
    // earn,
    // stakingUnit,
    // stakingBalanceName,
    // rewardsBalanceName
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          history.replace(props.to);
        }}
        sx={{
          color: "#a3a8ac",
          fontSize: "15px",
          fontFamily: "poppins-regular",
          textTransform: "none",
        }}
        {...props}
      />
    );
  }
  return (
    <nav className="navbar">
      <div className="navbar-container container">
        <input
          type="checkbox"
          name=""
          id=""
          checked={chkbox}
          onChange={toggleChange}
        />
        <div className="hamburger-lines">
          <span className="line line1"></span>
          <span className="line line2"></span>
          <span className="line line3"></span>
        </div>
        <ul className="menu-items">
          <div className="menus">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                orientation={`${
                  windowSize[0] < 768 ? "vertical" : "horizontal"
                }`}
              >
                {/* <LinkTab
                  label="My Assets"
                  icon={<img src={launchpad} alt="icon" className="nav-icon" />}
                  iconPosition="start"
                  to="/my-nft"
                />   */}
                <LinkTab
                  label="Swap"
                  icon={<img src={swap} alt="icon" className="nav-icon" />}
                  iconPosition="start"
                  to="/swap"
                />
                <LinkTab
                  label="AI Generator"
                  icon={<img src={magicIcon} alt="icon" className="nav-icon" />}
                  iconPosition="start"
                  to="/ai-generator"
                />
                <LinkTab
                  label="Earn"
                  icon={<img src={nft} alt="icon" className="nav-icon" />}
                  iconPosition="start"
                  to="/earn"
                />
                {/* <LinkTab
                  label="Token Farms"
                  icon={<img src={liquidity} alt="icon" className="nav-icon" />}
                  iconPosition="start"
                  to="/"
                />
                <LinkTab
                  label="NFT Staking"
                  icon={<img src={nft} alt="icon" className="nav-icon" />}
                  iconPosition="start"
                  to="/nft-farm"
                />
                <LinkTab
                  label="Orders"
                  icon={<img src={orders} alt="icon" className="nav-icon" />}
                  iconPosition="start"
                  to="/orders"
                /> */}
                <LinkTab
                  label="Launchpad"
                  icon={<img src={launchpad} alt="icon" className="nav-icon" />}
                  iconPosition="start"
                  to="/nft-launchpad"
                />
              </Tabs>
            </Box>
          </div>
          <div className="active-area">
            {/* <NetworkSelector /> */}
            {!signerAddress ? (
              <button
                className="connect-button"
                onClick={() => {
                  if (!signerAddress) {
                    setWalletModal(true);
                    // startAction();
                    // timeout(3000).then((val) => stopAction());
                  } else {
                    createNotification(
                      "success",
                      "Wallet Disconnected Successfully",
                      4000
                    );
                    deactivate();
                    disconnectUserAccount();
                  }
                }}
                onMouseOver={() => {
                  setConnectBtnIcon(disconnectIcon);
                }}
                onMouseOut={() => {
                  setConnectBtnIcon(connectBtnIcon);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                  }}
                >
                  <img
                    style={{
                      marginRight: ".8rem",
                      height: 35,
                      padding: "7px 10px",
                    }}
                    src={signerAddress ? disconnectIcon : connectBtnIcon}
                    alt="connect icon"
                  />
                  <span
                    style={{
                      fontFamily: "poppins-bold",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    {signerAddress ? "Disconnect" : "Connect Wallet"}
                  </span>
                </div>
              </button>
            ) : (
              <Row
                style={{ alignItems: "center", cursor: "pointer" }}
                onClick={() => {
                  setUserInfoBoxIsOpen(!userInfoBoxIsOpen);
                }}
              >
                {/* <img
                  style={{
                    marginRight: ".8rem",
                    height: 35,
                    padding: "7px 10px",
                  }}
                  src={walletIcon}
                  alt="connect icon"
                /> */}
                <Column
                  style={{
                    paddingRight: 30,
                    alignItems: "flex-end",
                  }}
                >
                  <Row style={{ alignItems: "center" }}>
                    <CountUpNumber
                      className="balance"
                      startValue={0}
                      end={
                        userTokenAmount &&
                        bigInt(userTokenAmount["TANG"]) / bigInt(1000000)
                      }
                      decimals={0}
                    />
                    <img src={mainLogo} alt="logo" width={25} />
                  </Row>
                  <Row style={{ marginTop: "1rem", alignItems: "center" }}>
                    <CountUpNumber
                      className="balance"
                      startValue={0}
                      end={userTokenAmount && userTokenAmount["ADA"]}
                      decimals={0}
                    />
                    <img src={adaIcon} alt="logo" width={25} />
                  </Row>
                </Column>
                <div
                  style={{
                    borderRight: "1.5px solid rgba(225, 226, 230, 0.15)",
                    height: 40,
                    marginRight: 30,
                  }}
                ></div>

                <div
                  style={{
                    backgroundColor: "#1A2730",
                    paddingRight: 15,
                    paddingLeft: 15,
                    paddingTop: 14,
                    borderRadius: 80,
                    aspectRatio: 1,
                    border: `1px solid ${
                      userInfoBoxIsOpen ? "#FF6E9C" : "#5f5c5c"
                    }`,
                  }}
                >
                  <img
                    // className="wallet-img"
                    style={{ height: "15px" }}
                    src={walletIcons[adaWallet]}
                    alt={`${adaWallet} connect`}
                  />
                </div>
                <Text
                  style={{
                    marginLeft: 15,
                    color: userInfoBoxIsOpen ? "#FF6E9C" : "#A3A8AC",
                    paddingRight: 10,
                  }}
                >
                  {`${signerAddress.substring(
                    0,
                    7
                  )}.....${signerAddress.substring(
                    signerAddress.length - 7,
                    signerAddress.length
                  )}`}
                </Text>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{
                    fontSize: 16,
                    color: userInfoBoxIsOpen ? "#FF6E9C" : "#A3A8AC",
                  }}
                />
              </Row>
            )}
          </div>
        </ul>
        <img
          src={mainLogo}
          srcSet={`
      ${mainLogo} 2x,
      ${mainLogo} 3x
    `}
          alt="logo"
          className="logo"
        />
      </div>

      {signerAddress ? (
        <Modal
          open={open}
          onClose={onCloseModal}
          modalType={modalType}
          modalTitle={modalTitle}
          modalEarn={modalEarn}
          modalStakingUnit={modalStakingUnit}
          modalStakingWallet={modalStakingWallet}
          modalDistributionWallet={modalDistributionWallet}
          amountOfStakedTokens={amountOfStakedTokens}
          poolId={stakingPoolId}
          isLoading={setIsLoading}
          tokenDecimal={tokenDecimal}
        />
      ) : (
        <div></div>
      )}

      {signerAddress ? (
        <div
          ref={userInfoBoxRef}
          style={{
            display: userInfoBoxIsOpen ? "block" : "none",
            backgroundColor: "#1A2730",
            position: "absolute",
            border: "1.5px solid #4C565D",
            borderRadius: 6,
            right: 50,
            top: 80,
          }}
        >
          <Column style={{ padding: 30, paddingBottom: 15 }}>
            <Row style={{ alignItems: "center", marginBottom: 30 }}>
              <img
                // className="wallet-img"
                style={{ height: "50px", marginRight: 20 }}
                src={walletIcons[adaWallet]}
                alt={`${adaWallet} connect`}
              />
              <Column>
                <Text
                  style={{
                    "text-transform": "capitalize",
                    color: "#fff",
                    fontSize: 18,
                  }}
                >
                  {adaWallet}
                </Text>

                <Text style={{ color: "#2C923C" }}>Connected</Text>
              </Column>
              <div style={{ width: "100%" }}></div>
              <Row
                style={{
                  alignItems: "center",
                  borderRadius: 5,
                  backgroundColor: "#FFFFFF33",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <div
                  className={`blinking-div ${NETWORK === "pre" ? "" : "blue"}`}
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: NETWORK == "pre" ? "#2C923C" : "#77c4ff",
                    borderRadius: 100,
                    marginRight: 6,
                  }}
                ></div>
                <Text
                  style={{
                    color: "#fff",
                    textWrap: "nowrap",
                    fontWeight: 300,
                    fontFamily: "poppins-regular",
                  }}
                >
                  {NETWORK == "pre" ? "Pre-prod" : "Mainnet"}
                </Text>
              </Row>
            </Row>
            <Column>
              <Text
                text=" Available Balance:  "
                className="info-section__currency"
                style={{
                  "font-size": 16,
                  color: "#ffffff78",
                  marginLeft: 5,
                  marginBottom: 10,
                }}
              />
            </Column>
            <Row style={{ alignItems: "baseline" }}>
              <Column>
                <CountUpNumber
                  className="modal_bignum"
                  startValue={0}
                  end={
                    userTokenAmount &&
                    bigInt(userTokenAmount["TANG"]) / bigInt(1000000)
                  }
                  decimals={0}
                />
              </Column>
              <Text text="TANG" className="info-section__balance" />
            </Row>
            <Row style={{ marginTop: "1rem", alignItems: "baseline" }}>
              <Column>
                <CountUpNumber
                  className="modal_smallnum"
                  startValue={0}
                  prefix={"~ "}
                  end={
                    tangBalanceInUsd !== null ? `${tangBalanceInUsd}` : "N/A"
                  }
                  decimals={0}
                />
              </Column>
              <Text
                text="USD"
                className="info-section__balance"
                style={{ fontSize: 13, color: "#A3A8AC" }}
              />
            </Row>

            <Column
              style={{
                marginTop: 20,
                cursor: "pointer",
                borderRadius: 6,
                background:
                  "linear-gradient(90deg, rgb(237 25 91 / 23%) 0%, rgb(255 0 80) 100%) 0% 0% no-repeat padding-box padding-box",
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
                paddingBottom: 20,
                width: "100%",
              }}
            >
              <Row>
                <img
                  className="liquidity-item__image"
                  style={{
                    marginLeft: 5,
                    width: "20px",
                    height: "20px",
                    color: "white",
                    marginTop: 3,
                  }}
                  src={magicIcon}
                  alt="Lock icon"
                />
                <Text
                  text="  Credit Balance:  "
                  className="info-section__currency"
                  style={{
                    "font-size": 16,
                    color: "#fff",
                    marginLeft: 5,
                    fontWeight: 200,
                  }}
                />
              </Row>

              <Row
                style={{
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Row style={{ alignItems: "center" }}>
                  <CountUpNumber
                    className="modal_smallnum"
                    startValue={0}
                    end={userCredit}
                    decimals={0}
                  />

                  <Text
                    text="AI Credit"
                    className="info-section__balance"
                    style={{
                      fontFamily: "monospace",
                      marginTop: 2,
                      color: "#A3A8AC",
                    }}
                  />
                </Row>
                <button
                  onClick={() => {
                    onOpenModal(
                      "aiCredit",
                      "AI Credit ETH",
                      "AI Credit",
                      "lovelace",
                      "stakingWallet",
                      userTokenAmount && userTokenAmount["ADA"],
                      100,
                      "distributionWallet",
                      setIsLoading,
                      0
                    );
                  }}
                  style={{
                    fontSize: 16,
                    backgroundColor: "#0000001f",
                    border: "1.5px solid #fff",
                    cursor: "pointer",
                    color: "white",
                  }}
                  // onClick={() => handleGroupButtonClick(i)}
                >
                  <Row>
                    {/* Add */}
                    <Text
                      style={{
                        // width: "20px",
                        // height: "20px",
                        fontSize: 30,
                        margin: 5,
                        marginBottom: 7,

                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "10px",
                        padding: 5,
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      +
                    </Text>
                  </Row>
                </button>
              </Row>
            </Column>

            <Row
              style={{
                borderBottom: "1.5px solid rgba(225, 226, 230, 0.15)",
                marginTop: 25,
                marginBottom: 25,
              }}
            ></Row>
            <Text
              text="  Wallet Address:  "
              className="info-section__currency"
              style={{
                "font-size": 16,
                color: "#ffffff78",
                marginLeft: 5,
                fontWeight: 200,
                marginBottom: 10,
              }}
            />

            <Row
              onClick={() => {
                navigator.clipboard.writeText(signerAddress);
                createNotification("success", "Copied to clipboard", 2000);
                //setCheckboxIsSelected(!checkboxIsSelected);
              }}
              style={{
                cursor: "pointer",
                borderRadius: 6,
                backgroundColor: "#FFFFFF33",
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
                paddingBottom: 20,
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "#A3A8AC",
                  "font-feature-settings": "'clig' off, 'liga' off",
                  "font-size": 13,
                  "font-style": "normal",
                  "font-weight": 500,
                  "line-height": "normal",
                  letterSpacing: 1,
                }}
              >
                {" "}
                {signerAddress.substring(0, 15) +
                  "....." +
                  signerAddress.substring(
                    signerAddress.length - 15,
                    signerAddress.length
                  )}
              </Text>
              <img
                src={copyIcon}
                alt="Info"
                className="liquidity-item__image"
                style={{
                  marginLeft: 20,
                  width: "20px",
                  height: "20px",
                }}
              />
            </Row>

            <Row
              onClick={() => {
                createNotification(
                  "success",
                  "Wallet Disconnected Successfully",
                  2000
                );
                setUserInfoBoxIsOpen(false);
                deactivate();
                disconnectUserAccount();
              }}
              style={{
                cursor: "pointer",
                marginTop: "40px",
                marginBottom: "20px",
                width: "100%",
                padding: 10,
                "text-align": "center",
                display: "block",
                borderRadius: 5,
                border: "1.5px solid #f83472",
              }}
            >
              <Text
                style={{
                  color: "#f83472",
                  "font-feature-settings": "'clig' off, 'liga' off",
                  "font-size": 15,
                  "font-style": "normal",
                  "font-weight": 500,
                  "line-height": "normal",
                }}
              >
                Disconnect Wallet
              </Text>
            </Row>
          </Column>
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    signerAddress: state.user.signerAddress,
    adaWallet: state.user.adaWallet,
    userCredit: state.user.userCredit,
    tangPrice: state.user.tangPrice,
    userTokenAmount: state.user.userTokenAmount,
  };
};

export default connect(mapStateToProps, {
  createNotification,
  setWalletModal,
  startAction,
  stopAction,
  disconnectUserAccount,
})(Header);
