import { Column, ShippingForm, H1, Text, Button, Row } from "components";

const Shipping = ({ forth, back }) => {
  return (
    <Column className="launchpad-shipping-modal">
      <H1 text="DHL Shipping Info" style={{ marginBottom: "20px" }} />
      <Text
        text="Please provide your info below"
        style={{ color: "#a3a8ac", fontSize: "13px" }}
      />

      <ShippingForm />
      <div
        className="divider"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      />

      <Row className="redeem-detail-container">
        <Text style={{ color: "#a3a8ac", marginRight: "10px" }}>
          NOIR Redeemed
        </Text>
        <div className="dashline"></div>
        <Text style={{ color: "#fff", paddingLeft: "1rem" }}>1 NOIR</Text>
      </Row>
      <Row className="redeem-detail-container">
        <Text style={{ color: "#a3a8ac", marginRight: "10px" }}>
          XOR Transaction fee
        </Text>
        <div className="dashline"></div>
        <Text style={{ color: "#fff", paddingLeft: "1rem" }}>0.0007 XOR</Text>
      </Row>
      <Row style={{width: "100%", margin: "3rem"}}>
        <Button className="buy-button" onClick={() => forth()}>
          Redeem
        </Button>
      </Row>
      <Row>
        <Text style={{ color: "#a3a8ac", textAlign: "center" }}>
          Acknowledge that I am over 21 years of age and I am not acting
          in violation of my country’s laws
        </Text>
      </Row>
    </Column>
  );
};

export default Shipping;
