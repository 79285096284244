import { Modal as ReactResponsiveModal } from "react-responsive-modal";
import React, { useState, useRef } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const ImageItem = ({ handleCheckboxChange, isSelected,isSelectable, imageSrc, fullWidth }) => {
  const [open, setOpen] = useState(false);
  const [mouseEntered, setMouseEntered] = useState(false);

  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedPosition, setZoomedPosition] = useState({ x: 0, y: 0 });
  const zoomedImageRef = useRef(null);

  const handleMouseMove = (e) => {
    if (isZoomed) {
      const { left, top, width, height } = zoomedImageRef.current.getBoundingClientRect();
      const x = (e.clientX - left) / width;
      const y = (e.clientY - top) / height;
      setZoomedPosition({ x, y });
    }
  };

  const handleMouseEnter = () => {
    setMouseEntered(true);
  };

  const handleMouseLeave = () => {
    setMouseEntered(false);
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsZoomed(false);
  };

  const closeIcon = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#FFF" fillRule="nonzero">
        <path
          d="M21.787 2.666h-11.56c-4.52 0-7.56 3.174-7.56 7.894v10.893c0 4.707 3.04 7.88 7.56 7.88h11.56c4.52 0 7.546-3.173 7.546-7.88V10.56c0-4.72-3.026-7.894-7.546-7.894"
          opacity=".4"
        />
        <path d="m20.02 18.36-2.371-2.37 2.37-2.37a1.165 1.165 0 0 0 0-1.65 1.163 1.163 0 0 0-1.649-.002l-2.372 2.371-2.372-2.373a1.17 1.17 0 0 0-1.65 0 1.17 1.17 0 0 0 0 1.65l2.373 2.374-2.368 2.366a1.165 1.165 0 0 0 .824 1.992c.3 0 .597-.113.825-.34l2.368-2.369 2.373 2.372a1.168 1.168 0 0 0 1.65 0 1.167 1.167 0 0 0 0-1.65" />
      </g>
    </svg>
  );

  return (
    <div 
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
   
    style={{
      color: "white",
      marginLeft: 10,
      width:fullWidth ? "100%": "30%",
      borderRadius: 15,
      cursor: "pointer",
      display: "flex"
    }}>
      {/* <IconButton
color="primary"

style={{ position: 'absolute', zIndex: 2, marginLeft: '10px' }}
>

</IconButton>*/}
     {isSelectable ?  <div
        onClick={() => handleCheckboxChange()}
        className={`item-checkbox ${
          mouseEntered || isSelected ? "visible" : ""
        }`}
      >
        <CheckCircleIcon
          color={isSelected ? "secondary" : "white"}
          style={{ height: 20, width: 20,backgroundColor: isSelected? "white" : "black",
            borderRadius: 20 }}
        />
      </div> : null}

      <img
        src={imageSrc}
        alt="Image"
        onClick={handleOpen}
        style={{
          color: "white",
          marginLeft: 10,
          width: "100%",
          borderRadius: 15,
          cursor: "pointer",
        }}
      />

      {/* <Dialog open={open} onClose={handleClose} maxWidth="md">
<DialogContent>
  <ReactImageMagnify
    {...{
      smallImage: {
        src: imageSrc,
        width: "25%",
        height: "100%",
        // width: 400, // adjust as needed
        // height: 300, // adjust as needed
      },
      largeImage: {
        src: imageSrc,
        width: "80%",
        height: "100%",
      },
    }}
  />
</DialogContent> 
</Dialog> */}

      <ReactResponsiveModal
        center
        open={open}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
        onClose={handleClose}
        closeIcon={closeIcon}
      >
        <div style={{ textAlign: "center", height: "100%" }} onMouseMove={handleMouseMove}>
                   {/* , position: "relative" }} onMouseMove={handleMouseMove}>
        <img
        src={imageSrc}
        alt="Image"
        className={`zoomable-image ${isZoomed ? "zoomed" : ""}`}
        onClick={toggleZoom}
      />
      {isZoomed && (
        <div
          className="zoomed-image"
          ref={zoomedImageRef}
          style={{
            background: `url(${imageSrc})`,
            backgroundPosition: `${zoomedPosition.x * 100}% ${zoomedPosition.y * 100}%`,
          }}
        ></div>
      )} */}
          <img
            src={imageSrc}
            alt="Image"
            className={`zoomable-image ${isZoomed ? "zoomed" : ""}`}
            onClick={toggleZoom}
            style={{
              marginLeft: 10,
              height: "100%",
              cursor: isZoomed ? "zoom-out" : "zoom-in",
            }}
          />
        </div>
      </ReactResponsiveModal>
    </div>
  );
};
