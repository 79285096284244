import React, { useState } from "react";
import { Row, Text, Column, CountUpNumber } from "../../../components";
import { connect } from "react-redux";
import tangLogo from "../../../assets/tokenStaking/tang.svg";
import goldieLogo from "../../../assets/tokenStaking/goldie.png";
import ratDaoLogo from "../../../assets/tokenStaking/ratdao.png";
import arrowDown from "../../../assets/arrow-down.svg";
import elvLogo from "../../../assets/tokenStaking/elv.png";
import cPiggyLogo from "../../../assets/tokenStaking/cPiggy.png";
import BUKELELogo from "../../../assets/tokenStaking/BUKELE.png";
import arrowUp from "../../../assets/arrow-up.svg";
import bigInt from "big-integer";
import arrowDownHover from "../../../assets/arrow-down-hover.svg";
import arrowUpHover from "../../../assets/arrow-up-hover.svg";
import ExpandedLiquidityItem from "./ExpandedLiquidityItem";
import { createNotification } from "store/actions/uiActions";

function formatNumberWithSuffix(number) {
  if (typeof number !== 'number') {
    return number; // Return as is if it's not a number
  }

  if (number >= 1e15) {
    return (number / 1e15).toFixed(2) + ' Q';
  } else if (number >= 1e12) {
    return (number / 1e12).toFixed(2) + ' T';
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(2) + ' B';
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(2) + ' M';
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(2) + ' K';
  } else {
    return number.toFixed(2);
  }
}
// take id of farm => based on id show image
const LiquidityItem = ({
  title,
  APY,
  stakingUnit,
  farmContractName,
  style,
  windowSize,
  farm,
  openModal,
  earn,
  stakingWallet,
  adaWallet,
  poolId,
  userStakedTokens,
  stakedTokens,
  userRewards,
  poolRewards,
  createNotification,
  minutesUntilPoolIsClosing,
  closesAt,
  tangentAmount,
  elvAmount,
  cPiggyAmount,
  tangentADAAmount,
  elvADAAmount,
  bukeleAmount,
  distributionWallet,
  decimal,
  icon,
  userTokenAmount,
  tokenContract,
  getToken,
}) => {
  const [itemIsExpanded, setItemIsexpanded] = useState(false);
  const [arrowImg, setArrowImg] = useState(arrowDown);

  // const isUnstakingAvailable = false;

  const isMobileViewEnabled = windowSize[0] < 900;

  const staked = userStakedTokens ? userStakedTokens[poolId] : 0;
  // const rewards = userRewards
  //   ? Object.keys(userRewards).map((key) => {
  //       var value = userRewards[key];
  //       return {
  //         [value.rewardUnit.name]: value.amount,
  //       };
  //     })
  //   : [{ [title]: 0 }];

  const expand = () => {
    setItemIsexpanded(!itemIsExpanded);
    if (itemIsExpanded) {
      setArrowImg(arrowDown);
    } else {
      setArrowImg(arrowUp);
    }
  };

  const onArrowHover = () => {
    if (itemIsExpanded) {
      setArrowImg(arrowUpHover);
    } else {
      setArrowImg(arrowDownHover);
    }
  };

  const onArrowOut = () => {
    if (itemIsExpanded) {
      setArrowImg(arrowUp);
    } else {
      setArrowImg(arrowDown);
    }
  };

  return (
    <Column>
      <Row
        className="liquidity-item"
        style={{
          ...style,
          ...(itemIsExpanded && { borderBottom: "none", marginBottom: 0 }),
        }}
        onClick={expand}
      >
        <Row className="liquidity-item__big-space">
          <img
            src={icon}
            alt="NFT"
            className="liquidity-item__image"
            style={{
              width: "27px",
            }}
          />
          <Text text={title} className="liquidity-item__title" />
        </Row>
        {isMobileViewEnabled ? (
          <Row style={{ width: "100%" }}>
            <div className="liquidity-item__small-space">
              <Text text="Yield" className="liquidity-item__value" />
            </div>
            <div className="liquidity-item__dashline" />
            <div className="liquidity-item__small-space">
              <Text
                text={formatNumberWithSuffix(APY) + "%"}
                className="liquidity-item__value"
                style={{ textAlign: "right" }}
              />
            </div>
          </Row>
        ) : (
          <div className="liquidity-item__small-space">
             <Text
              
              text={formatNumberWithSuffix(APY) + "%"}
              className="liquidity-item__apy"          
            />
          </div>
        )}
        {isMobileViewEnabled ? (
          <Row style={{ width: "100%" }}>
            <Row style={{ width: "100%" }}>
              <div className="liquidity-item__small-space">
                <Text text="Staked" className="liquidity-item__value" />
              </div>
              <div className="liquidity-item__dashline" />
              <div className="liquidity-item__small-space">
                <Text
                  text={`${staked / 10 ** decimal}%`}
                  className="liquidity-item__value"
                  style={{ textAlign: "right" }}
                />
              </div>
            </Row>
          </Row>
        ) : (
          <Column className="liquidity-item__small-space">
            <CountUpNumber
              className="liquidity-item__value"
              startValue={0}
              end={staked / 10 ** decimal}
              // end={title.toLowerCase().includes("tang/ada") ? staked : title.toLowerCase() === ("elv") ? staked/100 : title.toLowerCase().includes("elv/ada") ? staked : title.toLowerCase().includes("cpiggy") ? staked/100 :  title.toLowerCase().includes("bukele")? staked : staked/1000000}
              decimals={0}
            />
          </Column>
        )}
        {isMobileViewEnabled ? (
          <Row className="liquidity-item__big-space">
            <Row style={{ width: "100%" }}>
              <div className="liquidity-item__small-space">
                <Text text="Earn" className="liquidity-item__value" />
              </div>
              <div className="liquidity-item__dashline" />
              <div className="liquidity-item__small-space">
                <Text
                  text="TANG"
                  className="liquidity-item__value"
                  style={{ textAlign: "right", whiteSpace: "nowrap" }}
                />
              </div>
            </Row>
          </Row>
        ) : (
          <div className="liquidity-item__small-space">
            <Text text={earn} className="liquidity-item__value" />
          </div>
        )}

        <Row className="liquidity-item__small-space">
          <img
            onClick={expand}
            className="liquidity-item__expand"
            src={arrowImg}
            onMouseOver={() => onArrowHover()}
            onMouseOut={() => onArrowOut()}
            alt="Arrow down"
          />
        </Row>
      </Row>
      {itemIsExpanded && (
        <ExpandedLiquidityItem
          minutesUntilPoolIsClosing={minutesUntilPoolIsClosing}
          closesAt={closesAt}
          title={title}
          icon={icon}
          staked={staked}
          poolId={poolId}
          tokenContract={tokenContract}
          getToken={getToken}
          farmContractName={farmContractName}
          rewards={userRewards[poolId] ?? poolRewards}
          decimal={decimal}
          earn={earn}
          distributionWallet={distributionWallet}
          stake={(setIsLoading) => {
            if (adaWallet) {
              if (title.toLowerCase().includes("mithr")){
                createNotification("error", "MITHR staking is not available", 4000);
                  return;
              } else {
              openModal(
                "staking",
                title,
                earn,
                stakingUnit,
                stakingWallet,
                bigInt(userTokenAmount[stakingUnit]) / bigInt(10 ** decimal),
                poolId,
                distributionWallet,
                setIsLoading,
                decimal
              );
              }
            } else {
              createNotification("error", "Please connect your wallet", 4000);
            }
          }}
          unstake={(setIsLoading) => {
            // return;
            if (adaWallet) {
              //   if (title.toLowerCase().includes("bukele")){
              //     createNotification("error", "BUKEKE pool will be open soon", 4000);
              //     return;
              // } else {
              if (staked > 0) {
                createNotification("error", "Unstaking Will Be available soon", 4000);
                return
                openModal(
                  "unstaking",
                  title,
                  earn,
                  stakingUnit,
                  stakingWallet,
                  staked / 10 ** decimal,
                  poolId,
                  distributionWallet,
                  setIsLoading,
                  decimal
                );
              } else {
                createNotification(
                  "error",
                  "You don't have any tokens staked",
                  4000
                );
              }
              // }
            } else {
              createNotification("error", "Please connect your wallet", 4000);
            }
          }}
          claim={() => {}}
          isMobileViewEnabled={isMobileViewEnabled}
        />
      )}
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    userStakedTokens: state.pool.userStakedTokens,
    stakedTokens: state.pool.stakedTokens,
    userRewards: state.pool.userRewards,
    tangentAmount: state.user.tangentAmount,
    tangentADAAmount: state.user.tangentADAAmount,
    elvAmount: state.user.elvAmount,
    elvADAAmount: state.user.elvADAAmount,
    cPiggyAmount: state.user.cPiggyAmount,
    bukeleAmount: state.user.bukeleAmount,
    userTokenAmount: state.user.userTokenAmount,
  };
};

export default connect(mapStateToProps, { createNotification })(LiquidityItem);
