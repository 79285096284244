import { Column, Text, Row } from "components";
import "./nft-card.scss";
import Button from "../Button/Button";
import { useHistory } from "react-router-dom";

const NftCard = ({ image, title, tokenName, contractAddress, creatorName, cardType, unit }) => {
  const history = useHistory();

  const RedirectToMyNFT = () => {
    if (cardType == 2) {
      history.push("/my-nft");
    }
  }

  return (
    <Column className={`${cardType == 1 ? "nft-card" : "other-card"}`} onClick={() => RedirectToMyNFT()}>
         {/* {unit == 'faf9889f47c0b39177a7e07fe71ee5f5037beb01dcec3d4f2daae586456c657661746f72204c616273' ? (
                <img className="nft-image" src={image} alt="NFT" />
         ) : (        
          <img className="nft-image" src={image} alt="NFT" />
         )} */}
      <div className="nft-details">
        <Text
          style={{ fontFamily: "poppins-regular, sans-serif" }}
          text={title}
          className="nft-title"
        />
        <Text
          style={{ fontFamily: "poppins-regular, sans-serif" }}
          text={tokenName}
          className="nft-token-name"
        />
        <Text
          style={{ fontFamily: "poppins-regular, sans-serif" }}
          text={contractAddress}
          className="nft-contract-address"
        />
        <Text
          style={{ fontFamily: "poppins-regular, sans-serif",  marginTop: 10  }}
          text={'-------------------------------------------'}
          className="nft-contract-address"
        />
         <Text
          style={{ fontFamily: "poppins-regular, sans-serif", marginTop: 10}}
          text={'Policy ID'}
          className="nft-token-name"
        />
       <Text
       
          style={{ fontFamily: "poppins-regular, sans-serif", fontSize: 11, marginTop: 10 }}
          text={unit}
          className="nft-contract-address"
        />
        {cardType == 1 ? (
          <>
            <div className="nft-details__hover">
              {/* <div className="flex">
                <Text
                  style={{ fontFamily: "poppins-regular, sans-serif" }}
                  text="0.0042"
                  className="nft-title"
                />
                <Text
                  style={{
                    fontFamily: "poppins-regular, sans-serif",
                    paddingLeft: "12px",
                  }}
                  text="Tang"
                  className="nft-token-name"
                />
              </div> */}
              <Button
                className="flex"
                style={{
                  background: "rgba(255, 255, 255, 0.0001)",
                  border: "1px solid rgba(255, 255, 255, 0.4)",
                }}
                text={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    Details
                  </div>
                }
                onClick={() => {
                  history.push(`/nft-detail/${unit}`);
                }}
              />
            </div>
          </>
        ) : (
          <Row style={{ alignItems: "baseline" }}>
            <Text
              style={{ fontFamily: "poppins-regular, sans-serif" }}
              text="0.0042"
              className="nft-title"
            />
            <Text
              style={{
                fontFamily: "poppins-regular, sans-serif",
                paddingLeft: "12px",
              }}
              text="Tang"
              className="nft-token-name"
            />
          </Row>
        )}
      </div>
    </Column>
  );
};

export default NftCard;
