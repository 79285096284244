/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React from "react";
import { Column, H1, Row, Text, H3, Button } from "../../index";
import closeIcon from "../../../assets/notifications/x.png";
import stepDone from "../../../assets/nft-minter/task-done.png";
import Loader from "react-js-loader";
import { connect } from "react-redux";

const TransactionsModal = (props) => {
  const { steps, currentStep, closeModal, loading, currentTx, networkType } =
    props;
  return (
    <Column className="transactions-modal">
      <Row className="staking-modal__header">
        <H1 text="Follow steps" className="transactions-modal__title" />
        <img
          alt="Close icon"
          onClick={closeModal}
          className="fas fa-times staking-modal__close"
          src={closeIcon}
        />
      </Row>

      <Row className="transactions-modal__container">
        <Column className="transactions-modal__steps">
          {steps.map((step, index) => (
            <Row
              key={index}
              className="transactions-modal__steps__single-step"
              style={{
                ...(currentStep === index && {
                  backgroundColor: "#1e1d2b",
                }), // currentstep design
              }}
            >
              <div className="transactions-modal__steps__single-step__number">
                {currentStep > index ? (
                  <img src={stepDone} alt="Step done icon" />
                ) : (
                  step.number
                )}
              </div>
              <Text text={step.topic} />
            </Row>
          ))}
        </Column>
        <Column className="transactions-modal__current-step">
          <H3
            className="transactions-modal__current-step__title"
            text={steps[currentStep].title}
          />
          {currentTx ? (
            <a
              style={{
                cursor: "pointer",
                color: "#3986eb",
                fontWeight: 500,
                fontSize: "16px",
                letterSpacing: "0",
                lineHeight: "18px",
              }}
              className="transactions-modal__current-step__text"
              href={`https://${
                networkType === "testnet" ? "rinkeby." : ""
              }etherscan.io/tx/${currentTx}`}
              target="_blank"
              rel="noreferrer"
            >
              View Transaction
            </a>
          ) : (
            <Text
              text={steps[currentStep].text}
              className="transactions-modal__current-step__text"
            />
          )}
          {loading ? (
            <div style={{ width: "30%" }}>
              <Loader
                type="spinner-default"
                title=""
                bgColor={"#d02315"}
                size={50}
              />
            </div>
          ) : (
            <Button
              className="red-button transactions-modal__current-step__button"
              text={steps[currentStep].topic}
              onClick={props[steps[currentStep].functionName]} // we pass the approve and stake function as props to this component
            />
          )}
        </Column>
      </Row>
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ui.loading,
    networkType: state.blockchainNetwork.networkType,
  };
};

export default connect(mapStateToProps)(TransactionsModal);
