import * as networkActionTypes from "../actionTypes/networkActionTypes";
import { NETWORK } from "utils/constants";

const initialState = {
  network: NETWORK,
};

const networkReducer = (state = initialState, action) => {
  switch (action.type) {
    case networkActionTypes.SET_NETWORK:
      return {
        ...state,
        network: action.payload,
      };
    default:
      return state;
  }
};

export default networkReducer;
