import { getLucid } from "./lucid";

export const loadAvailableAmountDeposit = async (stakingUnit) => {
  try {
    // get lucid
    // const lucid = await getLucid();
    // let usersTotalAmountOfStakingUnit = 0;
    // // get ALL utxos of wallet
    // const utxos = await lucid.wallet.getUtxos();
    // // holds all wallets with amount of staking unit
    // const walletsAmountOfStakingUnitFromUser = {};
    // const walletsAmountOfAdaFromUser = {};
    let usersTotalAmountOfStakingUnit = 0;
    // get ALL utxos of wallet
    const utxos = "";
    // holds all wallets with amount of staking unit
    const walletsAmountOfStakingUnitFromUser = {};
    const walletsAmountOfAdaFromUser = {};
    /*
    utxos.forEach((u) => {
      // if there is a staking unit
      if (u.assets[stakingUnit]) {
        // sum total amount of staking unit
        usersTotalAmountOfStakingUnit += Number(u.assets[stakingUnit]);
        // if address already exists in Object sum staking unit
        if (walletsAmountOfStakingUnitFromUser[u.address]) {
          walletsAmountOfStakingUnitFromUser[u.address] += Number(
            u.assets[stakingUnit]
          );
          // if not set the current number
        } else {
          walletsAmountOfStakingUnitFromUser[u.address] = Number(
            u.assets[stakingUnit]
          );
        }
      }
      // if there is a staking unit
      if (u.assets["lovelace"]) {
        // if lovelave already exists in Object sum staking unit
        if (walletsAmountOfAdaFromUser[u.address]) {
          walletsAmountOfAdaFromUser[u.address] += Number(
            u.assets["lovelavce"]
          );
          // if not set the current number
        } else {
          walletsAmountOfAdaFromUser[u.address] = Number(u.assets["lovelavce"]);
        }
      }
    });
    */
    return {
      usersTotalAmountOfStakingUnit,
      walletsAmountOfStakingUnitFromUser,
      walletsAmountOfAdaFromUser
    };
  } catch (error) {
    debugger;
    console.log(error, error?.message);
  }
};
