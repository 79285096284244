import axios from "axios";
import { getUserWallet } from "./lucid";
import { API_URL, API_URL_MAIN } from "../constants";
import {
  setTotalStaked,
  setUsersTotalRewards,
} from "store/actions/userActions";
import { setPoolsData } from "store/actions/poolActions";
import { store } from "index";
import { mockNftInfo } from "../mockInfo";
import { mockNftInfoBeta } from "../mockInfoBeta";
import { mockTokenInfoBeta } from "../mockInfoBeta";


import { STATE } from "../constants";

export const getPoolsStakedTokens = async (isPreProd) => {
  try {
    const response = await axios(
      `${isPreProd ? API_URL : API_URL_MAIN}pool/getPoolsStakedTokens`
    );
    const poolsStakedTokens = response?.data?.poolsStakedTokens;
    if (poolsStakedTokens) {
      let totalStakedTokens = 0;
      for (const key in poolsStakedTokens) {
        totalStakedTokens += Number(poolsStakedTokens[key]);
      }
      store.dispatch(setTotalStaked(totalStakedTokens));
      return poolsStakedTokens;
    }
  } catch (error) {
    console.log(error, error?.message);
  }
};

export const getUsersRewards = async (isPreProd) => {
  const userWallet = await getUserWallet(isPreProd);
  try {
    const response = await axios.post(
      `${isPreProd ? API_URL : API_URL_MAIN}pool/getRewardPerUser`,
      {
        userWallet,
      }
    );
    const usersRewardsPerPoolId = response?.data?.usersRewardsPerPoolId;
    if (usersRewardsPerPoolId) {
      console.log("usersRewardsPerPoolId", usersRewardsPerPoolId);
      let totalElvRewards = 0;
      let totalcPiggyRewards = 0;
      let totalTangRewards = 0;
      let totalBukeleRewards = 0;
      for (const key in usersRewardsPerPoolId) {
        if (key == 3 || key == 40 || key == 41 || key == 42) continue;
        totalTangRewards += Number(usersRewardsPerPoolId[key]);
      }
      totalcPiggyRewards = usersRewardsPerPoolId[40];
      totalElvRewards = usersRewardsPerPoolId[3] + usersRewardsPerPoolId[41];
      totalBukeleRewards = usersRewardsPerPoolId[42];
      store.dispatch(
        setUsersTotalRewards(
          totalTangRewards,
          totalElvRewards,
          totalcPiggyRewards,
          totalBukeleRewards
        )
      );
      return usersRewardsPerPoolId;
    }
  } catch (error) {
    console.log(error, error?.message);
  }
};

export const getUsersStakedTokens = async (isPreProd) => {
  const userWallet = await getUserWallet();
  try {
    const response = await axios.post(
      `${isPreProd ? API_URL : API_URL_MAIN}pool/getUsersStakedTokens`,
      {
        userWallet,
      }
    );
    if (response?.data?.usersStakedTokensPerPoolId) {
      return response.data.usersStakedTokensPerPoolId;
    }
  } catch (error) {
    console.log(error, error?.message);
  }
};

export const getUsersStakedNFTs = async (poolId, isPreProd) => {
  const userWallet = await getUserWallet(isPreProd);
  try {
    const response = await axios.post(
      `${isPreProd ? API_URL : API_URL_MAIN}pool/getUsersNFTUnits`,
      {
        userWallet,
        poolId,
      }
    );
    if (response?.data?.poolDepositsUnits) {
      return response.data.poolDepositsUnits;
    }
  } catch (error) {
    console.log(error, error?.message);
  }
};

export const getOrders = async (isPreProd, setTokenInfo, setAllTokenInfo) => {
  const userWallet = await getUserWallet(isPreProd);
  try {
    const response = await axios.post(
      `${isPreProd ? API_URL : API_URL_MAIN}pool/orders`,
      {
        userWallet,
      }
    );
    if (response?.data?.orders) {
      setTokenInfo(response.data.orders);
      setAllTokenInfo(response.data.orders);
      return response.data.orders;
    }
  } catch (error) {
    console.log(error, error?.message);
  }
};

export const getPools = async (
  mockTokenInfo,
  setTokenInfo,
  setTokens,
  isPreProd
) => {
  try {

    const url = `${isPreProd ? API_URL : API_URL_MAIN}pool/getPools?state=${STATE}`;
    const response = await axios(url);
    const dbPools = response?.data?.pools;
    // const dbPools = nftPools;
    if (dbPools) {
      store.dispatch(setPoolsData(dbPools));
      console.log("dbPools", dbPools);
      var data = dbPools.filter((value) => value.type == "token");
      setTokens(data);
      return setTokenInfo(data);
    }
  } catch (error) {
    console.log(error, error?.message);
  }
};
export const getNftPools = async (
  nftAssets,
  setTokenInfo,
  setTokens,
  isPreProd
) => {
  try {
    const url = `${isPreProd ? API_URL : API_URL_MAIN}pool/getPools?state=${STATE}`;
    const response = await axios(url);
    const dbPools = response?.data?.pools; //.filter((value) => value.type === "nft");
    // const dbPools = response?.data?.pools;

    if (dbPools) {
      store.dispatch(setPoolsData(dbPools));
      dbPools.sort(function (a, b) {
        return a.id - b.id;
      });

      var data = dbPools.filter((value) => value.type == "nft");

      setTokens(data);
      return setTokenInfo(data);
    }
  } catch (error) {
    console.log(error, error?.message);
  }
};
