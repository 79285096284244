import React from "react";

const Row = ({
  onClick,
  style,
  className,
  children,
  center,
  spaceBetween,
  align,
}) => {
  return (
    <div
      onClick={onClick}
      className={`row${className ? ` ${className}` : ""}`}
      style={{
        ...(center && { justifyContent: "center", alignItems: "center" }),
        ...(spaceBetween && { justifyContent: "space-between" }),
        ...(align && { alignItems: "center" }),
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Row;
