export const START_ACTION = "START_ACTION";
export const STOP_ACTION = "STOP_ACTION";
export const SET_CONNECTION = "SET_CONNECTION";
export const SET_ERROR = "SET_ERROR";
export const SET_WALLET_MODAL = "SET_WALLET_MODAL";
export const SET_NOTIFY_MODAL = "SET_NOTIFY_MODAL";
export const SET_CONTRACTS_LOADED_ALREADY = "SET_CONTRACTS_LOADED_ALREADY";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const SET_TX = "SET_TX";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
