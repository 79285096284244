import { useState } from "react";
import { Confirmation, Quantity, Shipping, Terms } from "./components";
import { Text, LazyImage } from "components";
import closeIcon from "assets/nftLaunchpad/close.svg";

const NftBuyLaunchpadModal = ({
  isRedeemProcess,
  closeModal,
  orderData,
  productIcon,
}) => {
  const [currentModalNumber, setCurrentModalNumber] = useState(1);

  const back = () => {
    if (currentModalNumber > 1) setCurrentModalNumber(currentModalNumber - 1);
  };

  const forth = () => {
    setCurrentModalNumber(currentModalNumber + 1);
  };

  const getCurrentModal = () => {
    if (currentModalNumber === 1) {
      if (!isRedeemProcess)
        return (
          <Quantity
            back={back}
            forth={forth}
            isRedeemProcess={isRedeemProcess}
            closeModal={closeModal}
          />
        );
      else return <Terms forth={forth} closeModal={closeModal} />;
    } else if (currentModalNumber === 2) {
      if (isRedeemProcess)
        return (
          <Quantity
            back={back}
            forth={forth}
            isRedeemProcess={isRedeemProcess}
            closeModal={closeModal}
          />
        );
      else {
        setCurrentModalNumber(4);
        return (
          <Confirmation
            isRedeemProcess={isRedeemProcess}
            closeModal={closeModal}
          />
        );
      }
    } else if (currentModalNumber === 3)
      return <Shipping back={back} forth={forth} closeModal={closeModal} />;
    else if (currentModalNumber === 4)
      return (
        <Confirmation
          isRedeemProcess={isRedeemProcess}
          closeModal={closeModal}
        />
      );
  };

  const ItemContainer = () => {
    if (currentModalNumber <= 3 && isRedeemProcess)
      return (
        <div className="item-container">
          <Text className="item-text" text="Your order" />
          <div className="img-container">
            <LazyImage
              style={{ width: "38px", height: "86px" }}
              className="product-icon"
              src={productIcon}
              alt="beer brand"
            />
          </div>
        </div>
      );
    else if (currentModalNumber <= 2)
      return (
        <div className="item-container">
          <Text className="item-text" text="Your order" />
          <div className="img-container">
            <LazyImage
              style={{ width: "38px", height: "86px" }}
              src={productIcon}
              className="product-icon"
              alt="beer brand"
            />
          </div>
        </div>
      );
  };
  return (
    <div className="nft-buy-launchpad-modal">
      <div className="close-icon-container">
        <img
          className="close-icon"
          onClick={() => closeModal()}
          src={closeIcon}
          alt="Close icon"
        />
      </div>
      <div className="middle-container">
        <ItemContainer />
        <div className="nft-buy-launchpad-content">{getCurrentModal()}</div>
      </div>
    </div>
  );
};

export default NftBuyLaunchpadModal;
