import React, { useState } from "react";
import LoadLogo from "assets/tang_logo_animated.svg";

const Loader = ({ loadState }) => {
  if (loadState) {
    return (
      <div className="loader">
        <div className="loadContainer">
          <img className="loadLogo" src={LoadLogo} alt="Loading" /> 
          <p className="loadText">
            Please Wait
            <span className="animText">
              <span style={{ "--i": 1 }}>.</span>
              <span style={{ "--i": 2 }}>.</span>
              <span style={{ "--i": 3 }}>.</span>
            </span>
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Loader;
