import React, { useState } from "react";
import { Row, Column, Button, Text, CountUpNumber } from "../../../components";
import { connect } from "react-redux";
import { takeRewards } from "utils/ada/takeRewards";
import { createNotification } from "store/actions/uiActions";
import Loader from "react-js-loader";
import moment from "moment";
const ExpandedOrderItem = ({ isMobileViewEnabled, data }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Column className="expanded-liquidity-item">
      <Column className="expanded-liquidity-item__section1">
        <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "20px",
              whiteSpace: "nowrap",
              fontSize: "13px",
            }}
          >
            Amount Received
          </Text>
          <div className="dashline"></div>
          <Row>
            <Row
              style={{ marginLeft: 5, marginRight: -10 }}
              className="expanded-liquidity-item__first-rewardText"
            >
              <Text>{(data?.amount / 10 ** data.decimal).toLocaleString()}</Text>
              <Text
                className="expanded-liquidity-item__rewardText__unitText"
                style={{ fontSize: "13px" }}
              >
                TANG
              </Text>
            </Row>
          </Row>
        </Row>
        {/* {isMobileViewEnabled ? (
          <Row className="expanded-liquidity-item__rowSpace">
            <Text
              style={{
                color: "#a3a8ac",
                marginRight: "10px",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
            >
              Farm Fee
            </Text>
            <div className="dashline"></div>
            <Row>
              <Row className="expanded-liquidity-item__first-rewardText">
                <Text>{data.farm_fee}</Text>
                <Text
                  className="expanded-liquidity-item__rewardText__unitText"
                  style={{ fontSize: "13px", marginLeft: 5, marginRight: -10 }}
                >
                  TANG
                </Text>
              </Row>
            </Row>
          </Row>
        ) : (
          <Row className="expanded-liquidity-item__rowSpace">
            <Text
              style={{
                color: "#a3a8ac",
                marginRight: "10px",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
            >
              Fram Fee
            </Text>
            <div className="dashline"></div>
            <Row>
              <Row className="expanded-liquidity-item__first-rewardText">
                <Text>{data.farm_fee}</Text>
                <Text
                  className="expanded-liquidity-item__rewardText__unitText"
                  style={{
                    fontSize: "13px",
                    marginLeft: 5,
                    marginRight: -10,
                  }}
                >
                  TANG
                </Text>
              </Row>
            </Row>
          </Row>
        )} */}

        {/* {isMobileViewEnabled ? (
          <Row className="expanded-liquidity-item__rowSpace">
            <Text
              style={{
                color: "#a3a8ac",
                marginRight: "10px",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
            >
              Network Fee
            </Text>
            <div className="dashline"></div>
            <Row>
              <Row className="expanded-liquidity-item__first-rewardText">
                <Text>{data.network_fee}</Text>
                <Text
                  className="expanded-liquidity-item__rewardText__unitText"
                  style={{ fontSize: "13px", marginLeft: 5, marginRight: -10 }}
                >
                  TANG
                </Text>
              </Row>
            </Row>
          </Row>
        ) : (
          <Row className="expanded-liquidity-item__rowSpace">
            <Text
              style={{
                color: "#a3a8ac",
                marginRight: "10px",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
            >
              Network Fee
            </Text>
            <div className="dashline"></div>
            <Row>
              <Row className="expanded-liquidity-item__first-rewardText">
                <Text>{data.network_fee}</Text>
                <Text
                  className="expanded-liquidity-item__rewardText__unitText"
                  style={{
                    fontSize: "13px",
                    marginLeft: 5,
                    marginRight: -10,
                  }}
                >
                  TANG
                </Text>
              </Row>
            </Row>
          </Row>
        )} */}

        <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "10px",
              whiteSpace: "nowrap",
              fontSize: "13px",
            }}
          >
            Created at
          </Text>
          <div className="dashline"></div>
          <Row
            className="expanded-liquidity-item__poolText"
            style={{ fontSize: "13px" }}
          >
            <Column className="expanded-liquidity-item__percentage-container">
              <Text text={moment(data.time).format('DD-MM-YYYY hh:mm:ss')} style={{ whiteSpace: "nowrap" }} />
            </Column>
          </Row>
        </Row>

        <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "10px",
              whiteSpace: "nowrap",
              fontSize: "13px",
            }}
          >
            Transaction hash
          </Text>
          <div className="dashline"></div>
          <Row
            className="expanded-liquidity-item__poolText"
            style={{ fontSize: "13px" }}
          >
            <Column>
              {isMobileViewEnabled ? (
                <Text text={data.transactionHash.substring(0, 15) + " ..."} style={{ whiteSpace: "nowrap" }} />
              ) : (
                <Text text={data.transactionHash} style={{ whiteSpace: "nowrap" }} />
              )}
            </Column>
          </Row>
        </Row>
      </Column>
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
    stakedTokens: state.pool.stakedTokens,
  };
};

export default connect(mapStateToProps, {
  createNotification,
})(ExpandedOrderItem);
