import { getPoolsStakedTokens, getUsersRewards, getUsersStakedTokens } from "utils/ada/getPoolData";
import * as poolActionTypes from "../actionTypes/poolActionTypes";

export const setUsersStakedTokens = (isPreProd) => async (dispatch) => {
  const usersStakedTokens = await getUsersStakedTokens(isPreProd);
  dispatch({
    type: poolActionTypes.SET_USERS_STAKED_TOKENS,
    payload: usersStakedTokens,
  });
};

export const setUsersRewards = (isPreProd) => async (dispatch) => {
  const usersRewards = await getUsersRewards(isPreProd);
  dispatch({
    type: poolActionTypes.SET_USER_REWARDS,
    payload: usersRewards,
  });
};

export const setPoolsData = (data) => async (dispatch) => {
  dispatch({
    type: poolActionTypes.SET_POOLS_DATA,
    payload: data,
  });
};

export const setPoolsStakedTokens = (isPreProd) => async (dispatch) => {
  const poolsStakedTokens = await getPoolsStakedTokens(isPreProd);
  console.log(poolsStakedTokens);
  dispatch({
    type: poolActionTypes.SET_POOLS_STAKED_TOKENS,
    payload: poolsStakedTokens,
  });
};
