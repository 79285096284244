import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { store } from "../../index";
import { Column, H2, Header, NftCard, Row } from "../../components";
import NftDetail from "../NftDetail/NftDetail";
import { mockNftInfo } from "../../utils/mockInfo";
// import { mockData } from "./mockData";
import { Loader } from "../../components";
import LogoLoader from "assets/tang_logo_animated.svg";
import { jsonData } from "./nfts-preprod";
import LoadLogo from "../../assets/empty.png";
import connectIcon from "../../assets/wallet/connect.svg";
import disconnectIcon from "../../assets/wallet/disconnect.svg";
import { getWalletAssets } from "utils/ada/addWallets";
import {
  createNotification,
  setWalletModal,
  startAction,
  stopAction,
} from "../../store/actions/uiActions";

import { disconnectUserAccount } from "../../store/actions/userActions";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

const MyNfts = ({ signerAddress,nftAssets, setWalletModal, disconnectUserAccount, network }) => {
  let { path, url } = useRouteMatch();
  const { deactivate } = useWeb3React();
  const [connectBtnIcon, setConnectBtnIcon] = useState(connectIcon);
  const [nftsData, setNftsData] = useState(null);

  if(signerAddress){
    if(nftAssets == null ){
      getWalletAssets(signerAddress, setNftsData, network == "pre");
    }

  }
 


  return (

    <div className="my-nfts-page">


      <Column className="my-nfts">
        <Row>
          <H2 text="My Assets" className="nft-heading" />
        </Row>
        {!signerAddress ?
          <>

            <div className="emptyPageContainer">
              <img className="emptyImg" src={LoadLogo} alt="Loading" />
              <p className="emptyText">
                No data available, please connect wallet
              </p>
              <button
                className="connect-button-mynft"
                onClick={() => {
                  if (!signerAddress) {
                    setWalletModal(true);
                  } else {
                    createNotification(
                      "success",
                      "Wallet Disconnected Successfully",
                      4000
                    );
                    deactivate();
                    disconnectUserAccount();
                  }
                }}
                onMouseOver={() => {
                  setConnectBtnIcon(disconnectIcon);
                }}
                onMouseOut={() => {
                  setConnectBtnIcon(connectBtnIcon);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    color: "#fff",
                  }}
                >
                  {signerAddress ? "Disconnect" : "Connect Wallet"}
                </div>
              </button>
            </div>

          </>


          : nftAssets == null ?
          <div className="loadContainer">
          <img className="loadLogo" src={LogoLoader} alt="Loading" />
          <p className="loadText">
            Fetching Your Assets
            <span className="animText">
              <span style={{ "--i": 1 }}>.</span>
              <span style={{ "--i": 2 }}>.</span>
              <span style={{ "--i": 3 }}>.</span>
            </span>
          </p>
        </div>
          :
          <div className="nft-container">
            {nftAssets.map((nft, index) => (
              <NftCard
                key={index}
                image={nft.image}
                title={nft.name}
                unit={nft.unit}
                tokenName={nft.collection}
                creatorName={nft.edition}
                contractAddress={nft.edition}//"Tezt"//{nft.contractAddress}
                cardType={1}
              />
            ))}
          </div>
        }
      </Column>

      {/* <Switch>
        <Route path={`${path}/:fingerPrint`}>
          <NftDetail />
        </Route>
      </Switch> */}
    </div>
  );
};



const mapStateToProps = (state) => {
  return {
    signerAddress: state.user.signerAddress,
    nftAssets: state.user.nftAssets,
    network: state.network.network,
  };
};

export default connect(mapStateToProps, {
  createNotification,
  setWalletModal,
  startAction,
  stopAction,
  disconnectUserAccount,
})(MyNfts);
