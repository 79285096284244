import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Column, Button, Text, CountUpNumber } from "../../../components";
import { connect } from "react-redux";
import exportIcon from "../../../assets/export.png";
import { takeRewards } from "utils/ada/takeRewards";
import { createNotification } from "store/actions/uiActions";
import Loader from "react-js-loader";
import { setUsersRewards } from "store/actions/poolActions";

const ExpandedLiquidityItem = (props) => {
  let {
    rewards,
    stake,
    unstake,
    earn,
    isMobileViewEnabled,
    stakedTokens,
    staked,
    title,
    poolId,
    closesAt,
    minutesUntilPoolIsClosing,
    createNotification,
    setUsersRewards,
    distributionWallet,
    network,
    decimal,
    tokenContract,
    getToken,
    icon,
  } = props;

  const [endTime, setEndTime] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [stakeIsLoading, setStakeIsLoading] = useState(false);
  const [unstakeIsLoading, setUnstakeIsLoading] = useState(false);

  const claim = async () => {
    let hasRewardsToClaim = false;
    rewards?.map((reward) => {
      if (reward.amount > 0) {
        hasRewardsToClaim = true;
      }

      return reward;
    });
    if (!hasRewardsToClaim) {
      createNotification(
        "error",
        "You do not have any rewards in this pool",
        4000
      );
      return;
    }
    try {
      setIsLoading(true);
      await takeRewards(poolId, distributionWallet, network == "pre");
      setTimeout(async () => setUsersRewards(network == "pre"), 1000);
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    var endTime = closesAt;
    // setEndTime(moment().add('minutes', minutesUntilPoolIsClosing).format('YYYY-MM-DD mm:ss'));
    setEndTime(moment(closesAt).format("YYYY-MM-DD"));
  }, []);

  const totalStakedTokensOfPool = stakedTokens ? stakedTokens[poolId] : 0;

  const userShareOfPool = (staked * 100) / totalStakedTokensOfPool;

  return (
    <Column className="expanded-liquidity-item">
      <Column className="expanded-liquidity-item__section1">
        <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "20px",
              whiteSpace: "nowrap",
            }}
          >
            Total Value Locked
          </Text>
          <div className="dashline"></div>
          <Row>
            {!isMobileViewEnabled ? (
              <Row
                style={{
                  marginLeft: title.toLowerCase().includes("BUKELE") ? 5 : 5,
                  marginRight: title.toLowerCase().includes("bukele")
                    ? "10px !important"
                    : -10,
                }}
                className="expanded-liquidity-item__first-rewardText"
              >
                <Text>{totalStakedTokensOfPool / 10 ** decimal}</Text>
                <Text
                  className="expanded-liquidity-item__rewardText__unitText"
                  style={{
                    fontSize: "13px",
                    width:
                      title.toLowerCase() === "tang"
                        ? 30
                        : title.toLowerCase().includes("tang")
                        ? 90
                        : title.toLowerCase().includes("cpiggy")
                        ? 45
                        : title.toLowerCase().includes("bukele")
                        ? 45
                        : title.toLowerCase() === "elv"
                        ? 25
                        : title.toLowerCase().includes("elv")
                        ? 75
                        : 30,
                  }}
                >
                  {title}
                </Text>
              </Row>
            ) : (
              <Row
                style={{ marginLeft: 5, marginRight: -10 }}
                className="expanded-liquidity-item__first-rewardText"
              >
                <Text
                // style={{ fontSize: "13px", width: title.toLowerCase() === ("tang") ? 30 : title.toLowerCase().includes("tang") ?  90 : title.toLowerCase().includes("cpiggy") ? 45 :title.toLowerCase().includes("bukele") ? 45 : title.toLowerCase()=== ("elv") ? 25: title.toLowerCase().includes("elv") ? 75 : 30  }}
                >
                  {totalStakedTokensOfPool / 10 ** decimal}
                </Text>
                {/* <Text
                className="expanded-liquidity-item__rewardText__unitText"
                style={{ fontSize: "12px", width: title.toLowerCase().includes("tang") ?  90 : title.toLowerCase().includes("cpiggy") ? 45 : title.toLowerCase().includes("BUKELE") ? 35 : 30 }}
              >
                {title}
              </Text> */}
              </Row>
            )}
          </Row>
        </Row>

        <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "10px",
              whiteSpace: "nowrap",
            }}
          >
            Your % of the Pool
          </Text>
          <div className="dashline"></div>
          <Row
            className="expanded-liquidity-item__poolText"
            style={{ fontSize: "13px" }}
          >
            <Column className="expanded-liquidity-item__percentage-container">
              <CountUpNumber
                className="expanded-liquidity-item__percentage"
                startValue={0}
                end={userShareOfPool}
                suffix={"%"}
                decimals={2}
              />
              {!isMobileViewEnabled ? (
                <div className="expanded-liquidity-item__percentage__bubble">
                  Your % of the Pool
                </div>
              ) : null}
            </Column>
          </Row>
        </Row>

        <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "10px",
              whiteSpace: "nowrap",
            }}
          >
            Pool Fee
          </Text>
          <div className="dashline"></div>
          <Row
            className="expanded-liquidity-item__poolText"
            style={{ fontSize: "13px" }}
          >
            <Column className="expanded-liquidity-item__percentage-container">
              <CountUpNumber
                className="expanded-liquidity-item__percentage"
                startValue={0}
                end={0.5}
                suffix={"%"}
                decimals={2}
              />
              {!isMobileViewEnabled ? (
                <div className="expanded-liquidity-item__percentage__bubble">
                  Pool Fee
                </div>
              ) : null}
            </Column>
          </Row>
        </Row>

        <Row className="expanded-liquidity-item__rowSpace">
          {/* style={{display: 'flex', justifyContent: 'space-between'}}> */}
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "10px",
              whiteSpace: "nowrap",
            }}
          >
            Pool End Date
          </Text>
          <div className="dashline"></div>
          {!isMobileViewEnabled ? (
            <Row
              className="expanded-liquidity-item__poolText"
              style={{ fontSize: "13px", width: 115 }}
            >
              <Column>
                <Text style={{ fontSize: "13px" }}>{`${endTime}`}</Text>
              </Column>
            </Row>
          ) : (
            <Row
              className="expanded-liquidity-item__poolText"
              style={{ fontSize: "13px", width: 200 }}
            >
              <Column>
                <Text style={{ fontSize: "13px" }}>{`${endTime}`}</Text>
              </Column>
            </Row>
          )}
        </Row>
      </Column>
      {isMobileViewEnabled ? (
        <Row className="expanded-liquidity-item__rowSpace">
          <Text
            style={{
              color: "#a3a8ac",
              marginRight: "10px",
              whiteSpace: "nowrap",
            }}
          >
            Rewards
          </Text>
          <div className="dashline"></div>
          <Row>
            {rewards.map((reward, index) => {
              return (
                <Row
                  key={index}
                  className={`${
                    index === 0
                      ? "expanded-liquidity-item__first-rewardText"
                      : "expanded-liquidity-item__rewardText"
                  }`}
                >
                  <Text>{parseFloat(reward.amount).toFixed(6)}</Text>
                  <Text
                    className="expanded-liquidity-item__rewardText__unitText"
                    style={{
                      fontSize: "13px",
                      marginLeft: 5,
                      marginRight: -10,
                      width:
                        title.toLowerCase() === "tang"
                          ? 30
                          : title.toLowerCase().includes("tang/ada")
                          ? 90
                          : title.toLowerCase().includes("cpiggy")
                          ? 45
                          : title.toLowerCase() === "elv"
                          ? 25
                          : title.toLowerCase().includes("elv/ada")
                          ? 75
                          : title.toLowerCase() === "BUKELE"
                          ? 55
                          : 55,
                    }}
                  >
                    {reward.rewardUnit.name}
                  </Text>
                </Row>
              );
            })}
          </Row>
        </Row>
      ) : (
        <Row className="expanded-liquidity-item__rowSpace">
          <div style={{ width: "100%" }}></div>
          <Text
            style={{
              color: "#fff",
              //marginRight: "10px",
              whiteSpace: "nowrap",
              marginRight: 40,
            }}
          >
            Rewards:
          </Text>
          <Row>
            {rewards &&
              rewards.map((reward, index) => {
                return (
                  <Column
                    style={{
                      paddingRight: index == rewards.length - 1 ? 0 : 25,
                    }}
                  >
                    <Row
                      key={index}
                      className={`${"expanded-liquidity-item__first-rewardText"}`}
                    >
                      <img
                        src={reward.rewardUnit.icon}
                        alt="NFT"
                        className="liquidity-item__image"
                        style={{
                          width: "27px",
                        }}
                      />

                      <Text
                        className="expanded-liquidity-item__rewardText__unitText"
                        style={{
                          fontSize: "13px",
                          marginLeft: 15,
                          marginRight: 10,
                          width:
                            reward.rewardUnit.name.toLowerCase() === "tang"
                              ? 30
                              : reward.rewardUnit.name.toLowerCase() === "$tang"
                              ? 47
                              : reward.rewardUnit.name
                                  .toLowerCase()
                                  .includes("tang/ada")
                              ? 90
                              : reward.rewardUnit.name
                                  .toLowerCase()
                                  .includes("cpiggy")
                              ? 45
                              : reward.rewardUnit.name.toLowerCase() === "elv"
                              ? 25
                              : reward.rewardUnit.name
                                  .toLowerCase()
                                  .includes("elv/ada")
                              ? 30
                              : reward.rewardUnit.name.toLowerCase() ===
                                "BUKELE"
                              ? 55
                              : 55,
                        }}
                      >
                        {reward.rewardUnit.name}
                      </Text>

                      {/* <Text>{parseFloat(item[key]).toFixed(6)}</Text>
                      <Text
                        className="expanded-liquidity-item__rewardText__unitText"
                        
                        style={{ fontSize: "13px", marginLeft: 5, marginRight: -10,  width: title.toLowerCase().includes("tang") ?  30 : title.toLowerCase().includes("cpiggy") ? 45 : title.toLowerCase().includes("BUKELE") ? 35 : 30  }}
                      >
                        {key}
                      </Text> */}
                    </Row>
                    <Text
                      style={{
                        textAlign: "right",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: 18,
                        marginRight: 20,
                      }}
                    >
                      {parseFloat(reward.amount).toFixed()}
                    </Text>
                  </Column>
                );
              })}
          </Row>
        </Row>
      )}
      <Column>
        {!isMobileViewEnabled ? (
          <Column className="expanded-liquidity-item__buttonGroup">
            <Row style={{ width: "100%", justifyContent: "space-between" }}>
              <Row>
                {stakeIsLoading ? (
                  <Loader
                    type="spinner-default"
                    title=""
                    bgColor={"#d02315"}
                    size={50}
                  />
                ) : (
                  <Button
                    // href="#"
                    onClick={async () => {
                      if (minutesUntilPoolIsClosing === 0) {
                        createNotification(
                          "error",
                          "Pool is already closed",
                          4000
                        );
                      } else {
                        await stake(setStakeIsLoading);
                      }
                    }}
                    className="stake-button"
                  >
                    Stake
                  </Button>
                )}
                {unstakeIsLoading ? (
                  <Loader
                    type="spinner-default"
                    title=""
                    bgColor={"#d02315"}
                    size={50}
                  />
                ) : (
                  <Button
                    // href="#"
                    onClick={() => {
                      unstake(setUnstakeIsLoading);
                    }}
                    className="stake-button"
                  >
                    Unstake
                  </Button>
                )}
              </Row>
              {isLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <Button
                  onClick={claim}
                  text="Claim"
                  className="claim-button"
                  style={{
                    width: "100px",
                    height: "35px",
                    fontSize: "15px !important",
                  }}
                />
              )}
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <a
                className="expanded-liquidity-item__link"
                href={tokenContract}
                target="_blank"
                rel="noreferrer"
              >
                View Farm Contract
              </a>
              <img
                src={exportIcon}
                alt="NFT"
                className="liquidity-item__image"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
              <a
                className="expanded-liquidity-item__link"
                href={getToken}
                target="_blank"
                rel="noreferrer"
              >
                Get {title} token
              </a>
              <img
                src={exportIcon}
                alt="NFT"
                className="liquidity-item__image"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </Row>
          </Column>
        ) : (
          <Column style={{ marginTop: "1rem" }}>
            <Row className="expanded-liquidity-item__staking">
              {stakeIsLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <a
                  // href="/"
                  onClick={async () => {
                    //createNotification("error", "Staking Will Be available soon", 4000);
                    createNotification("error", "Staking Will Be available soon", 4000);
                    return
                    if (minutesUntilPoolIsClosing === 0) {
                        createNotification(
                            "error",
                            "Pool is already closed",
                            4000
                        );
                    } else {
                        await stake(setStakeIsLoading);
                    }
                  }}
                  className="expanded-liquidity-item__staking__buttons__a"
                >
                  Stake
                </a>
              )}
            </Row>

            <Row className="expanded-liquidity-item__staking">
              {unstakeIsLoading ? (
                <Loader
                  type="spinner-default"
                  title=""
                  bgColor={"#d02315"}
                  size={50}
                />
              ) : (
                <a
                  // href="/"
                  onClick={() => {
                    createNotification("error", "Unstaking Will Be available soon", 4000);
                    // unstake(setUnstakeIsLoading);
                  }}
                  className="expanded-liquidity-item__staking__buttons__a"
                >
                  Unstake
                </a>
              )}
            </Row>
            {isLoading ? (
              <Loader
                type="spinner-default"
                title=""
                bgColor={"#d02315"}
                size={50}
              />
            ) : (
              <Button
                onClick={claim}
                text="Claim"
                className="claim-button"
                style={{
                  fontSize: "15px !important",
                  width: "100%",
                  marginTop: "1rem",
                }}
              />
            )}
          </Column>
        )}
      </Column>

      {isMobileViewEnabled ? (
        <Column>
          <a
            className="expanded-liquidity-item__link"
            href={tokenContract}
            target="_blank"
            rel="noreferrer"
          >
            View Farm Contract
          </a>
          <img
            src={exportIcon}
            alt="NFT"
            className="liquidity-item__image"
            style={{
              width: "20px",
              height: "20px",
            }}
          />

          <a
            className="expanded-liquidity-item__link"
            href={getToken}
            target="_blank"
            rel="noreferrer"
          >
            Get {title} token
          </a>
          <img
            src={exportIcon}
            alt="NFT"
            className="liquidity-item__image"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        </Column>
      ) : null}
    </Column>
  );
};

const mapStateToProps = (state) => {
  return {
    stakedTokens: state.pool.stakedTokens,
    network: state.network.network,
  };
};

export default connect(mapStateToProps, {
  createNotification,
  setUsersRewards,
})(ExpandedLiquidityItem);
