import * as poolActionTypes from "../actionTypes/poolActionTypes";

const initialState = {
  userStakedTokens: false,
  stakedTokens: false,
  userRewards: false,
  poolsData: false,
};

const poolReducer = (state = initialState, action) => {
  switch (action.type) {
    case poolActionTypes.SET_POOLS_DATA:
      return {
        ...state,
        poolsData: action.payload,
      };
    case poolActionTypes.SET_USERS_STAKED_TOKENS:
      return {
        ...state,
        userStakedTokens: action.payload,
      };
    case poolActionTypes.SET_USER_REWARDS:
      return {
        ...state,
        userRewards: action.payload,
      };
    case poolActionTypes.SET_POOLS_STAKED_TOKENS:
      return {
        ...state,
        stakedTokens: action.payload,
      };

    default:
      return state;
  }
};

export default poolReducer;
