import axios from "axios";
import { awaitTx, depositTx, getUserWallet, initializeLucid } from "./lucid";
import { API_URL, API_URL_MAIN } from "../constants";
import { store } from "index";
import { createNotification } from "store/actions/uiActions";

export const takeRewards = async (poolId, distributionWallet, isPreProd) => {
  try {
    const feeTransactionHash = await depositWithdrawFee(distributionWallet, isPreProd)

    await executeClaimRequest(poolId, feeTransactionHash, isPreProd);
  } catch (error) {
    console.log("takeRewards error", error, error?.message);
  }
};

const executeClaimRequest = async (poolId, feeTransactionHash, isPreProd) => {
  try {
    const userWallet = await getUserWallet(isPreProd);
    await axios.post(`${isPreProd ? API_URL :API_URL_MAIN }pool/takeRewards/${poolId}`, {
      userWallet,
      feeTransactionHash
    });

    store.dispatch(
      createNotification(
        "success",
        "Your claim of tokens will be executed shortly",
        4000
      )
    );
  } catch (error) {
    store.dispatch(
      createNotification("error", `Something went wrong during claiming`, 4000)
    );
    console.log("executeClaimRequest error", error, error?.message);
    return error;
  }
};

export const depositWithdrawFee = async (stakingWallet, isPreProd) => {
  try {
    const walletName = store.getState().user.adaWallet;

    const lucid = await initializeLucid(
      await window.cardano[walletName].enable(), isPreProd
    );

    const utxos = await lucid.wallet.getUtxos();

    let tx;

    const depositObject = {
      lovelace: 2000000
    }
    tx = await depositTx(depositObject, stakingWallet, lucid, utxos);

    const signedTx = await tx.sign().complete();

    const txHash = await signedTx.submit();

    await awaitTx(txHash, isPreProd);

    return txHash;
  } catch (error) {
    if (
      error?.info === "user declixned tx" ||
      error?.info === "user declined to sign tx"
    ) {
      store.dispatch(
        createNotification("error", `You need to sign the message`, 6000)
      );
      return;
    }
    console.log(error, error?.message);
    debugger;
    store.dispatch(createNotification("error", JSON.stringify(error), 6000));
  }
};
