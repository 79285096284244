import { useEffect } from "react";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import { Router } from "react-router-dom";
import AdaWalletModal from "./components/Modals/AdaWalletModal/AdaWalletModal";
import NotifyModal from "./components/Modals/NotifyModal/NotifyModal";
import Routes from "./routes/Routes";
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import { Notifications, Header, Loader } from "./components";
import {
  loadAdaPrice,
  loadTangPrice,
  loadcPiggyPrice,
} from "./store/actions/userActions";
// import { createBrowserHistory } from "history";
import { setNetwork } from "store/actions/networkActions";
import { NETWORK } from "utils/constants";
const history = createBrowserHistory();

function App({
  loadAdaPrice,
  loadTangPrice,
  walletModal,
notifyModal,
  loadcPiggyPrice,
  loaderStatus,
}) {
  useEffect(() => {
    loadAdaPrice();
    loadTangPrice();
    loadcPiggyPrice();
  }, [loadAdaPrice, loadTangPrice]);

  // const history = createBrowserHistory();

  const displayWalletModal = () => {
    if (walletModal) {
      return <AdaWalletModal walletModal={walletModal} />;
    }
  };

  // const displayNotifyModal = () => {
  //   if (notifyModal) {
  //     return <NotifyModal walletModal={notifyModal} />;
  //   }
  // };

  return (
    <Router history={history}>
      <Header />
      <Routes/>
      {displayWalletModal()}
{/* {displayNotifyModal()} */}
      <Notifications
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2000}
      />
      <Loader loadState={loaderStatus} />

    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    walletModal: state.ui.walletModal,
    notifyModal: state.ui.notifyModal,
    network: state.network.network,
    loaderStatus: state.ui.loading,
  };
};

export default connect(mapStateToProps, {
  loadAdaPrice,
  loadTangPrice,
  loadcPiggyPrice,
})(App);
