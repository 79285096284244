import React, { useState } from "react";
import Slider from "react-slick";
import { Row, LazyImage } from "../../../components";
import PrevIcon from "assets/nftLaunchpad/prev.svg";
import NextIcon from "assets/nftLaunchpad/next.svg";

const images = [
  {
    id: 1,
    src: "https://picsum.photos/350/600/?random",
    alt: "Placeholder image",
  },
  {
    id: 2,
    src: "https://picsum.photos/351/600/?random",
    alt: "Placeholder image",
  },
  {
    id: 3,
    src: "https://picsum.photos/352/600/?random",
    alt: "Placeholder image",
  },
  {
    id: 4,
    src: "https://picsum.photos/349/600/?random",
    alt: "Placeholder image",
  },
  {
    id: 5,
    src: "https://picsum.photos/348/600/?random",
    alt: "Placeholder image",
  },
  {
    id: 6,
    src: "https://picsum.photos/353/600/?random",
    alt: "Placeholder image",
  },
];

export const ImageSlider = ({ src, slidesToShow = 3 }) => {
  const customSlider = React.createRef();
  const [imageIndex, setImageIndex] = useState(0);

  const gotoNext = () => {
    customSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customSlider.current.slickPrev();
  };

  const settings = {
    centerMode: true,
    infinite: true,
    dots: false,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: slidesToShow,
    centerPadding: "0",
    swipeToSlide: true,
    focusOnSelect: true,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const templateImages = images.map((image, idx) => {
    return (
      <div
        className={idx === imageIndex ? "activeSlide" : "slide"}
        key={image.id}
      >
        <div className="slideWrapper">
          {/* {image.code ? (
            image.code
          ) : (
            <img src={image.src} alt={image.alt} className="slideImage" />
          )} */}
          <LazyImage className="beer-image" src={src} alt="beer brand" />
        </div>
      </div>
    );
  });

  return (
    <div>
      <Slider {...settings} ref={customSlider}>
        {templateImages}
      </Slider>
      <Row style={{ justifyContent: "center", marginTop: "6rem" }}>
        <button onClick={() => gotoPrev()} className="arrow-btn">
          <img src={PrevIcon} className="prev-icon" alt="prev" />
        </button>
        <p className="indicate">{imageIndex + 1} / 6</p>
        <button onClick={() => gotoNext()} className="arrow-btn">
          <img src={NextIcon} className="next-icon" alt="next" />
        </button>
      </Row>
    </div>
  );
};
