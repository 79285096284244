import React from "react";
import { Modal as ReactResponsiveModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { InfoSection } from "../..";
import StakingModal from "../StakingModal/StakingModal";
import NftStakingModal from "../NftStakingModal/NftStakingModal";
import AiCreditModal from "../AiCreditModal/AiCreditModal";

export const Modal = ({
  open,
  onClose,
  modalType,
  modalStakingUnit,
  modalTitle,
  modalEarn,
  modalStakingWallet,
  modalDistributionWallet,
  amountOfStakedTokens,
  poolId,
  isLoading,
  tokenDecimal,
}) => {
  const closeIcon = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#FFF" fillRule="nonzero">
        <path
          d="M21.787 2.666h-11.56c-4.52 0-7.56 3.174-7.56 7.894v10.893c0 4.707 3.04 7.88 7.56 7.88h11.56c4.52 0 7.546-3.173 7.546-7.88V10.56c0-4.72-3.026-7.894-7.546-7.894"
          opacity=".4"
        />
        <path d="m20.02 18.36-2.371-2.37 2.37-2.37a1.165 1.165 0 0 0 0-1.65 1.163 1.163 0 0 0-1.649-.002l-2.372 2.371-2.372-2.373a1.17 1.17 0 0 0-1.65 0 1.17 1.17 0 0 0 0 1.65l2.373 2.374-2.368 2.366a1.165 1.165 0 0 0 .824 1.992c.3 0 .597-.113.825-.34l2.368-2.369 2.373 2.372a1.168 1.168 0 0 0 1.65 0 1.167 1.167 0 0 0 0-1.65" />
      </g>
    </svg>
  );
  return (
    <ReactResponsiveModal
      center
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
      }}
      open={open}
      onClose={() => onClose()}
      closeIcon={closeIcon}
    >
      {modalType === "aiCredit" && (
        <AiCreditModal
          stakingWallet={modalStakingWallet}
          type={true}
          stakeTokenName={modalTitle}
          earnTokenName={modalEarn}
          stakingUnit={modalStakingUnit}
          amountOfStakedTokens={amountOfStakedTokens}
          modalType={modalType}
          onClose={onClose}
          poolId={poolId}
          setActionIsLoading={isLoading}
        />
      )}
      {modalType === "nftStaking" && (
        <NftStakingModal
          stakingWallet={modalStakingWallet}
          type={true}
          stakeTokenName={modalTitle}
          earnTokenName={modalEarn}
          stakingUnit={modalStakingUnit}
          amountOfStakedTokens={amountOfStakedTokens}
          modalType={modalType}
          onClose={onClose}
          poolId={poolId}
          setActionIsLoading={isLoading}
        />
      )}
      {modalType === "nftUnstaking" && (
        <NftStakingModal
          modalType={modalType}
          type={false}
          stakeTokenName={modalTitle}
          earnTokenName={modalEarn}
          amountOfStakedTokens={amountOfStakedTokens}
          onClose={onClose}
          poolId={poolId}
          distributionWallet={modalDistributionWallet}
          stakingWallet={modalStakingWallet}
          setActionIsLoading={isLoading}
        />
      )}
      {modalType === "staking" && (
        <StakingModal
          stakingWallet={modalStakingWallet}
          type={true}
          stakeTokenName={modalTitle}
          earnTokenName={modalEarn}
          amountOfStakedTokens={amountOfStakedTokens}
          stakingUnit={modalStakingUnit}
          modalType={modalType}
          onClose={onClose}
          poolId={poolId}
          setActionIsLoading={isLoading}
          tokenDecimal={tokenDecimal}
        />
      )}
      {modalType === "unstaking" && (
        <StakingModal
          modalType={modalType}
          type={false}
          stakeTokenName={modalTitle}
          earnTokenName={modalEarn}
          amountOfStakedTokens={amountOfStakedTokens}
          onClose={onClose}
          poolId={poolId}
          distributionWallet={modalDistributionWallet}
          stakingWallet={modalStakingWallet}
          setActionIsLoading={isLoading}
          tokenDecimal={tokenDecimal}
        />
      )}
      {modalType === "info" && <InfoSection />}
    </ReactResponsiveModal>
  );
};
