// import React, { useState } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { Row, Column, H1 } from "../../components";
import { Box, Text, CountUpNumber, Input } from "../../components/index";
import lockIcon from "../../assets/infoSection/lock-info-section.png";
import magicIcon from "../../assets/magic.png";
import filterIcon from "../../assets/filter.png";
import checkIcon from "../../assets/check.png";
import infoIcon from "../../assets/wallets/info.svg";
import {
  createNotification
} from "../../store/actions/uiActions";
import closeIcon from "../../assets/close-icon.svg";
import dragIcon from "../../assets/drag.png";
import { store } from "../../index";
// import * as Cardano from '@dcspark/cardano-multiplatform-lib-browser';
import nmkr from "../../assets/nmkr.png";
import saturn from "../../assets/saturn.png";
import { useWindowSize } from "utils/windowSizeHook";
import LoadLogo from "assets/tang_logo_animated.svg";
import emptyLogo from "../../assets/empty.png";
import { ImageItem } from "./ImageItem";
import { fromHex, toHex } from '../../utils/saturn/serialization';
// import Loader from "../../utils/saturn/loader"
// import Cardano from '../../utils/saturn/loader';
import { Buffer } from 'buffer'; // Ensure the 'buffer' package is installed
import { TransactionBuilder, MetadataMap, TransactionMetadata, BigNum, PrivateKey, Transaction, TransactionWitnessSet } from "@emurgo/cardano-serialization-lib-asmjs";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from "../../assets/arrow_back.svg";
import Stepper from "react-stepper-horizontal";
import connectIcon from "../../assets/wallet/connect.svg";
import disconnectIcon from "../../assets/wallet/disconnect.svg";
import React, { useState, useRef, useEffect } from "react";
import Button from "../../components/Button/Button";
import { getPools } from "utils/ada/getPoolData";

import {
  setWalletModal,
  startAction,
  stopAction,
} from "../../store/actions/uiActions";
import { disconnectUserAccount } from "../../store/actions/userActions";

import ReactImageMagnify from "react-image-magnify";

const ADD_NFT_PROJECT_MUTATION = `
  mutation AddNFTProject() {
  addNFTProject() {
      nftProject {
          id
      }
      error {
          message
      }
  }
}
`;
const ADD_NFT_MUTATION = `
mutation AddNFT($input: AddNFTInput!) {
  addNFT(input: $input) {
      nfts {
          id
      }
      error {
          message
      }
  }
}
`;
const UPDATE_NFT_MUTATION = `
    mutation UpdateNFT($input: UpdateNFTInput!) {
        updateNFT(input: $input) {
            nft {
                id
            }
            error {
                message
            }
        }
    }
`;



const CREATE_SINGLE_OR_BULK_MINT_TRANSACTION_QUERY = `
mutation CreateNFTMintBurnUpdateTransaction($input: CreateNFTMintBurnUpdateTransactionInput!) {
  createNFTMintBurnUpdateTransaction(input: $input) {
      successTransactions {
          transactionId
          hexTransaction
      }
      error {
          message
      }
  }
}
`

const SUBMIT_SINGLE_OR_BULK_MINT_TRANSACTION_QUERY = 
`mutation SubmitSingleOrBulkMintTransaction($input: SubmitSingleOrBulkMintTransactionInput!) {
  submitSingleOrBulkMintTransaction(input: $input) {
      transactionId
      error {
          message
      }
  }
}`

async function addNFTProject() {
  try {
    const response = await axios.post(
      "https://api.preprod.saturnnft.io/v2/graphql/nftproject/add",
      // "https://api.saturnnft.io/v2/graphql/nftproject/add",
      {
          query: ADD_NFT_PROJECT_MUTATION,
          variables: { id: 123333}
        },
      {
          headers: {
            'Authorization': 'Bearer d650cdca-b70d-415e-ab2e-0209e84981e7-408e167a-bd1b-452e-9e35-20d627096d46'
            // 'Authorization': 'Bearer 7a8072cc-0a5e-4d6b-8a17-66885f697e94-fd96545a-8792-4c5c-a55c-1d39eba7978f'//7a8072cc-0a5e-4d6b-8a17-66885f697e94-fd96545a-8792-4c5c-a55c-1d39eba7978f'
          }
      }
  );

    const { data } = response.data;
    console.log('hereeeee', data)
    // if (!data || !data.addNFTProject) {
    //   throw new Error("Failed to add NFT project");
    // }

    return data;
  } catch (error) {
    console.error("Error adding NFT project:", error);
    throw error;
  }
}

async function addNFT(nftProjectId) {
  try {
      const response = await axios.post(
        "https://api.preprod.saturnnft.io/v2/graphql/nft/add",
          // "https://api.saturnnft.io/v2/graphql/nft/add",
          {
              query: ADD_NFT_MUTATION,
              variables: { input: { nftProjectId: nftProjectId, count: 1 } }
          },
          {
              headers: {
                'Authorization': 'Bearer d650cdca-b70d-415e-ab2e-0209e84981e7-408e167a-bd1b-452e-9e35-20d627096d46'
                  // 'Authorization': 'Bearer 7a8072cc-0a5e-4d6b-8a17-66885f697e94-fd96545a-8792-4c5c-a55c-1d39eba7978f'
              }
          }
      );

      const { data } = response.data;

      // Check for errors in GraphQL response
      if (data.addNFT.error) {
          throw new Error(data.addNFT.error.message);
      }

      return data.addNFT.nfts;
  } catch (error) {
      console.error("Error adding NFT:", error);
      throw error;
  }
}
async function updateNFT(input) {
  try {
      const response = await axios.post(
        "https://api.preprod.saturnnft.io/v2/graphql/nft/update",
          // "https://api.saturnnft.io/v2/graphql/nft/update",
          {
              query: UPDATE_NFT_MUTATION,
              variables: { input: input }
            },
          {
              headers: {
                'Authorization': 'Bearer d650cdca-b70d-415e-ab2e-0209e84981e7-408e167a-bd1b-452e-9e35-20d627096d46'
                  // 'Authorization': 'Bearer 7a8072cc-0a5e-4d6b-8a17-66885f697e94-fd96545a-8792-4c5c-a55c-1d39eba7978f'
              }
          }
      );

      const { data } = response.data;

      // Check for errors in GraphQL response
      if (data.updateNFT.error) {
          throw new Error(data.updateNFT.error.message);
      }

      return data.updateNFT.nft;
  } catch (error) {
      console.error("Error updating NFT:", error);
      throw error;
  }
}

async function createSingleOrBulkMintTransaction(input) {
  try {
      const response = await axios.post(
        "https://api.preprod.saturnnft.io/v2/graphql/nftproject/createNFTMintBurnUpdateTransaction",
          // "https://api.saturnnft.io/v2/graphql/nftproject/createNFTMintBurnUpdateTransaction",
          {
            query: CREATE_SINGLE_OR_BULK_MINT_TRANSACTION_QUERY,
              variables: { input: input }
            },
          {
              headers: {
                'Authorization': 'Bearer d650cdca-b70d-415e-ab2e-0209e84981e7-408e167a-bd1b-452e-9e35-20d627096d46'
                  // 'Authorization': 'Bearer 7a8072cc-0a5e-4d6b-8a17-66885f697e94-fd96545a-8792-4c5c-a55c-1d39eba7978f'
              }
          }
      );

      const { data } = response.data;


      return data;
  } catch (error) {
      console.error("Error updating NFT:", error);
      throw error;
  }
}

async function submitSingleOrBulkMintTransaction(input) {
  try {
      const response = await axios.post(
        "https://api.preprod.saturnnft.io/v2/graphql/nftproject/submitSingleOrBulkMintTransaction",
          // "https://api.saturnnft.io/graphql/nftproject/submitSingleOrBulkMintTransaction",
          {
            query: SUBMIT_SINGLE_OR_BULK_MINT_TRANSACTION_QUERY,
              variables: { input: input }
            },
          {
              headers: {
                'Authorization': 'Bearer d650cdca-b70d-415e-ab2e-0209e84981e7-408e167a-bd1b-452e-9e35-20d627096d46'
                  // 'Authorization': 'Bearer 7a8072cc-0a5e-4d6b-8a17-66885f697e94-fd96545a-8792-4c5c-a55c-1d39eba7978f'
              }
          }
      );

      const { data } = response.data;


      return data;
  } catch (error) {
      console.error("Error updating NFT:", error);
      throw error;
  }
}


const AIImageGenerator = ({
  adaWallet,
  signerAddress,
  setWalletModal,
  startAction,
  network,
  stopAction,
  createNotification,
  disconnectUserAccount,
}) => {
  getPools(null, null, null, network == "pre");
  const [latestPrompts, setLatestPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedProtocol, setSelectedProtocol] = useState(0);
  const [mintButtonTitle, setMintButtonTitle] = useState("Finalize Mint");
  const [selectedImages, setSelectedImages] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [nftName, setNftName] = useState("");
  const [nftDisplayName, setNftDisplayName] = useState("");

  const [nftDes, setNftDes] = useState("");

  const [payLink, setPayLink] = useState(null);

  const handleCheckboxChange = (imageSrc) => {
    if (selectedImages.includes(imageSrc)) {
      setActiveStep(selectedImages.length == 1 ? 0 : 1);
      setSelectedImages(selectedImages.filter((img) => img !== imageSrc));
    } else {
      setSelectedImages([ imageSrc]);//[...selectedImages, imageSrc]);
      setActiveStep(1);
    }
  };

  const handlePayClick = () => {
    setTimeout(() => {
      console.log("Actions after a delay...");
      // // window.location.reload();
      // window.location.replace('/ai-generator');

      // window.location.pathname = '/ai-generator';
       setActiveStep(0)
       setNftDisplayName("")
       setNftName("")
       setPayLink(null)
       setNftDes("")
       setSelectedImages([])

    }, 2000);

  }
  const handleBackClick = () => {
    setActiveStep(1);
  }

  
  const handleClick = () => {
    setIsLoading(true);
    setMintButtonTitle("")
if(selectedProtocol == 0){
    // mintNFT().then(() => {
      
    //   setIsLoading(false);
    //   setMintButtonTitle("Finalize Mint")
     
    // });
}else{
      nmkrMint().then(() => {
      
        setIsLoading(false);
        setMintButtonTitle("Finalize Mint")
       
      });
    }
   
    
    // setTimeout(() => {
    //   setMintButtonTitle("Mint Completed")
    //   //setActiveStep(3)
    // }, 5000);
  };

  const handleOpen = () => {
    // setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
  };

  const nmkrMint = async () => {
    try {
      

      const response = await axios.post(
        "https://staking-mainnet-86e436dab2ed.herokuapp.com/pool/nmkr-mint",
        {
          userWallet: signerAddress,
          imagesUrl: selectedImages,
          tokenName: nftDisplayName,
          nftDes: nftDes,
          nftName: nftName
        }
      );

      setPayLink(response.data.payLik)

    } catch (error) {
      
    }
  }

  
  const mintNFT = async () => {
    try {
     
       
     // await Loader.load();

        const response = await axios.post(
          "https://staking-mainnet-86e436dab2ed.herokuapp.com/pool/images-hash",
          {
            userWallet: signerAddress,
            imagesUrl: selectedImages
          }
        );

        const address = signerAddress;

        // 1) Create a new NFT Project
        const nftProject = await addNFTProject();
        // const nftProject = await addNFTProject();
        console.log('here2', nftProject.addNFTProject.nftProject.id)


        const nfts = await addNFT(nftProject.addNFTProject.nftProject.id);
        const nft = nfts?.[0];

        console.log('here 3',nft)
        // 3) Update the NFT with data
        const jsonProperties = {
            'minting-platform': 'https://app.tangent.art/'
        };
        
        const updateNFTInput = {
            nftId: nft?.id,
            assetName: 'TangentNFT',
            name: nftName,
            files: response.data.hashes.map((hash) =>  {
             return { name: nftName,
              mediaType: 'image/png',
              src: `ipfs://${hash}`,};
          }),
            jsonProperties: JSON.stringify(jsonProperties),
        };
        
        const updatedNFT = await updateNFT(updateNFTInput);
        console.log('hree4', updatedNFT)

      //   const createInput = {
      //     paymentAddress,
      //     nftMintComponents,
      //     nftBurnComponents,
      //     nftUpdateComponents,
      //     royaltyMintComponents,
      // };

        // 4) Create a Mint Transaction for the NFT
        // const createSingleOrBulkMintTransactionInput = {
        //     nftProjectId: nftProject.addNFTProject.nftProject.id,
        //     nftId: [nft?.id],          
        //     paymentAddress: address,
           
        // };

        const createSingleOrBulkMintTransactionInput = {
          paymentAddress: address,
          nftMintComponents: [{
            nftId: nft?.id ,        
          }],
          // nftBurnComponents:[],
          // nftUpdateComponents:[],
          royaltyMintComponents: [
            {nftProjectId : nftProject.addNFTProject.nftProject.id}
          ]
        }

        const createSingleOrBulkMintTransactionPayload = await createSingleOrBulkMintTransaction(createSingleOrBulkMintTransactionInput);

        // if(createSingleOrBulkMintTransactionPayload.createNFTMintBurnUpdateTransaction.error){
        //   createNotification("error", `${createSingleOrBulkMintTransactionPayload.createNFTMintBurnUpdateTransaction.error.message}`, 2000);
        //   return
        // }

        // console.log('hree5', createSingleOrBulkMintTransactionPayload)
      

        const hexTransaction = createSingleOrBulkMintTransactionPayload.createNFTMintBurnUpdateTransaction.successTransactions;
        if (hexTransaction.length == 0) {
            console.log('Hex Transaction is null');
            createNotification("error", `${createSingleOrBulkMintTransactionPayload.createNFTMintBurnUpdateTransaction.error.message}`, 200000);
            return
        }

        // // 5) Reconstruct and sign tx
        const reconstructedTx = Transaction.from_bytes(fromHex(hexTransaction));
        let transactionWitnessSet = reconstructedTx.witness_set();
        if (!transactionWitnessSet) {
            transactionWitnessSet = TransactionWitnessSet.new();
        }

        const wallet = window.cardano[store.getState().user.adaWallet];
        let api = await wallet.enable();

        let  txVKeyWitnesses = await api.signTx(toHex(reconstructedTx.to_bytes()), true);

        txVKeyWitnesses = TransactionWitnessSet.from_bytes(fromHex(txVKeyWitnesses));
        transactionWitnessSet.set_vkeys(txVKeyWitnesses.vkeys());

        const signedTx = Transaction.new(reconstructedTx.body(), transactionWitnessSet, reconstructedTx.auxiliary_data());
        const signedBytes = signedTx.to_bytes();
        const signedHex = toHex(signedBytes);

        // 6) Submit Mint Transaction for the NFT
        const submitInput = {
            nftProjectId: nftProject.addNFTProject.nftProject.id,
            nftIds: [nft?.id],
            paymentAddress: address,
            hexTransaction: signedHex,
        };

        const submitSingleNFTMintTransaction = await submitSingleOrBulkMintTransaction(submitInput);
        const transactionId = submitSingleNFTMintTransaction?.submitSingleOrBulkMintTransaction.transactionId;
        if (!transactionId) {
            console.log('Transaction Id is null');
            return;
        }

        setActiveStep(3);
        setSelectedImages([]);
        createNotification("success", `Congratulations! You have successfully minting a test NFT with the Saturn API! Tx Id ${transactionId}`, 2000);
        //console.log('Congratulations! You have successfully minting a test NFT with the Saturn API! Tx Id:', transactionId);
    } catch (error) {
      try{
        console.error(`${error}`);
        
        createNotification("error", `${error}`, 2000);
      }catch{

      }
      
    }
  };




  console.log(latestPrompts);
  useEffect(() => {
    // Fetch the API data when the latestPrompts array changes
    const fetchData = async () => {
      if (signerAddress) {
        try {
          const response = await axios.post(
            "https://staking-mainnet-86e436dab2ed.herokuapp.com/pool/latest-prompts",
            {
              userWallet: signerAddress,
            }
          );

          // Handle the response here (e.g., set state or perform other actions)
          // You can access the API data using response.data
          setLatestPrompts(response.data.prompts.reverse());
        } catch (error) {
          // Handle any errors that occur during the process
          console.error("Error:", error);
        }
      }
    };

    fetchData(); // Call the fetchData function when latestPrompts changes
  }, [signerAddress]); // Include latestPrompts as a dependency

  console.log("here", latestPrompts);

  const [connectBtnIcon, setConnectBtnIcon] = useState(connectIcon);
  const [ratio, setRatio] = useState(1);
  const [count, setCount] = useState(4);
  const [progress, setProgress] = useState(0);
  const [startImagination, setStartImagination] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [finalResult, setFinalResult] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const [imageStyle, setImageStyle] = useState("");
  const [promptText, setPromptText] = useState("");

  const [selectedRatioButton, setSelectedRatioButton] = useState(0);

  const handleRatioButtonClick = (index) => {
    setSelectedRatioButton(index);
  };

  const buttonsData = [
    { label: "512 x 512", index: 0 },
    { label: "768 x 768", index: 5 },
    { label: "512 x 1024", index: 1 },
    { label: "768 x 1024", index: 2 },
    // { label: "1024 x 768", index: 3 },
    // { label: "1024 x 1024", index: 4 },
  ];

  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const dropZoneRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  // Function to handle generating the image
  const generateImage = async (prompt) => {
    console.log("Selected Ratio:", ratio);
    console.log("Selected Count:", count);
    console.log("Selected Image Style:", imageStyle);
    console.log("Prompt Text:", promptText);

    setPrompt(prompt);
    try {
      // Step 1: Send a POST request to generate the image
      const response = await axios.post(
        "https://staking-mainnet-86e436dab2ed.herokuapp.com/pool/imagine",
        {
          userWallet: signerAddress,
          ratio,
          count,
          imageStyle,
          promptText,
          prompt,
        }
      );

      console.log(response.data.result.messageId);
      const progressKey = response.data.result.messageId; // Assuming progressKey is in the response
      console.log(progressKey);

      // Step 2: Poll the GET API until progressKey reaches 100
      let progress = 0;
      while (progress < 100) {
        const progressResponse = await axios.post(
          `https://staking-mainnet-86e436dab2ed.herokuapp.com/pool/progress-imagine/${progressKey}`,
          {
            userWallet: signerAddress,
          }
        );
        progress = progressResponse.data.result.progress;
        setStartImagination(true);
        setProgress(progress);
        console.log("Progress:", progress);

        // Add some delay before making the next GET request to avoid overloading the server
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay
      }

      // Step 3: Once progress reaches 100, you can fetch the final image or result
      const finalResponse = await axios.post(
        `https://staking-mainnet-86e436dab2ed.herokuapp.com/pool/progress-imagine/${progressKey}?state=final`,
        {
          userWallet: signerAddress,
        }
      );

      const finalImage = finalResponse.data.result.response.imageUrls;
      console.log("Final Image:", finalResponse.data.result.response.imageUrls);

      // setFinalResult(finalImage);
      setLatestPrompts([
        { prompt: prompt, images: finalImage },
        ...latestPrompts,
      ]);
      setStartImagination(false);
      setInputValue("");
      // setActiveStep(1);

      console.log("Final Image:", finalImage);

      // You can perform further actions with the final image here
    } catch (error) {
      console.error("Error:", error);
      // Handle any errors that occur during the process
    }
  };

  function Button({ label, selected, onClick }) {
    return (
      <div
        className={`button-ratio ${selected ? "selected-ratio" : ""}`}
        onClick={onClick}
      >
        <div className={`circle ${selected ? "selected-r" : ""}`}>
          {selected && (
            <img
              className="info-section__header__icon"
              src={checkIcon}
              alt="Lock icon"
              style={{
                color: "white",
                display: "inline",
                marginRight: 1.5,
                height: 11,
                width: 8,
              }}
            />
          )}
        </div>
        {label}
      </div>
    );
  }

  const [inputValue, setInputValue] = useState("");

  const [isToggleOn, setIsToggleOn] = useState(true);

  const toggleButtonClick = () => {
    setIsToggleOn(!isToggleOn);
  };

  const toggleButtonStyle = {
    background: isToggleOn
      ? "linear-gradient(147deg, rgb(255 159 189) 0%, rgb(248, 52, 114) 100%) 0% 0% no-repeat padding-box padding-box"
      : "#ccc",
  };

  const handleInputChange = (event) => {
    const textArea = event.target;
  textArea.style.height = 'auto'; // Reset the height
  textArea.style.height = `${textArea.scrollHeight}px`;
    setInputValue(event.target.value);
  };

  const numberArray = (count) => Array.from({ length: count + 1 }, (_, index) => index);

  const handleButtonClick = () => {
    // Handle button click here, e.g., perform an action with inputValue
    // alert(`You entered: ${inputValue}`);
    if(selectedImages.length > 0 ){
      setSelectedImages([])
      setActiveStep(0)
      return;
    }
    generateImage(inputValue);
  };

  const windowSize = useWindowSize();

  const onSearchItem = (val) => {};

  const [selectedButton, setSelectedButton] = useState(4);
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleGroupButtonClick = (number) => {
    setSelectedButton(number);
  };

  const [sliderValue, setSliderValue] = useState(20);

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  const gradientLine = {
    background: `linear-gradient(to right, #f36b96 ${sliderValue}%, rgb(255 255 255 / 12%) ${sliderValue}%)`,
  };

  const renderButtons = () => {
    const buttons = [];

    for (let i = 1; i <= 4; i++) {
      const isSelected = selectedButton === i;
      const buttonClassName = isSelected
        ? "circle-button selected"
        : "circle-button";

      buttons.push(
        <button
          key={i}
          className={buttonClassName}
          onClick={() => handleGroupButtonClick(i)}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <>
       {/* <div className="launchpadloader">
        <div className="launchpadloadContainer">
          <img className="loadLogo" src={LoadLogo} alt="Loading" />
          <p className="loadText">
          Coming Soon
            <span className="animText">
              <span style={{ "--i": 1 }}>.</span>
              <span style={{ "--i": 2 }}>.</span>
              <span style={{ "--i": 3 }}>.</span>
            </span>
          </p>
        </div>
      </div>
     */}
    
    <div >
             {/* style={{filter: "blur(5px)"}} */}
      <Row style={{ paddingTop: 100 }}>
        <Stepper
          style={{ marginTop: 10 , lineHeight: 50}}
          size={55}
          circleFontSize={18}
          // defaultOpacity=".5"
          defaultColor="#2A3740"
          activeBorderColor="#F83472"
          activeColor="#F83472"
          activeTitleColor="#ffffff"
          completeTitleColor="#757575"
          completeColor="#F83472"
          completeBorderColor="#2A3740"
          completeBarColor="#F83472"
          steps={[
            { title: "Generate Image" },
            { title: "NFT Metadata" },
            { title: "Choose Mint Service" },
            { title:  "Minted!" },//mintButtonTitle != "Finished" ? "Mint NFT" :
          ]}
          activeStep={activeStep}
        />
      </Row>
      {!signerAddress ? (
        <>
          <Column className="my-orders">
            <div className="emptyPageContainer">
              <img className="emptyImg" src={emptyLogo} alt="Loading" />
              <p className="emptyText">Please Connect Your Wallet</p>
              <button
                className="connect-button-mynft"
                onClick={() => {
                  if (!signerAddress) {
                    setWalletModal(true);
                  } else {
                    // createNotification(
                    //   "success",
                    //   "Wallet Disconnected Successfully",
                    //   4000
                    // );
                    // deactivate();
                    disconnectUserAccount();
                  }
                }}
                onMouseOver={() => {
                  setConnectBtnIcon(disconnectIcon);
                }}
                onMouseOut={() => {
                  setConnectBtnIcon(connectBtnIcon);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    color: "#fff",
                  }}
                >
                  {signerAddress ? "Disconnect" : "Connect Wallet"}
                </div>
              </button>
            </div>
          </Column>
        </>
      ) : (
        <div className="farms-ai">
          <Row className="farms-ai__menu">
            <Column className="farms-ai__menu-left">
              {/* {windowSize[0] <= 1200 ? ( */}
              <Row className="farms-ai__menu-left__search-collectible">
                <H1
                  text=""
                  className="farms-ai__menu-left__search-collectible__title"
                />
              </Row>
              {/* ) : null} */}

              {windowSize[0] < 900 ? (
                <Button
                  className="detail-button"
                  style={{
                    background: "rgba(255, 255, 255, 0.0001)",
                    border: "1px solid rgba(255, 255, 255, 0.4)",
                  }}
                  //onClick={() => onOpenModal("info")}
                  text={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                      }}
                    >
                      Details
                    </div>
                  }
                />
              ) : null}
              {
                <div className={`input-container ${selectedImages.length > 0 ? "collapse" : ""}`}>
                <textarea
                  placeholder={selectedImages.length > 0 ? "" : "Enter Prompt"}
                  value={selectedImages.length > 0 ? "" : inputValue}
                  onChange={handleInputChange}
                  className={`rounded-input-ai${ selectedImages.length > 0 ? "-collapsed" : ""}`}
                  disabled={selectedImages.length > 0}
                  rows="1" 
                  style={{ resize: 'none', marginRight: 150 }}
                />
                <button onClick={handleButtonClick} className="rounded-button-ai">
                  {selectedImages.length > 0 ? <> <img
                        className="info-section__header__icon"
                        src={ArrowBackIcon}
                        alt="Lock icon"
                        style={{ marginRight: 10, filter: "invert(1)" }}
                      /> Back </>: (
                    <>
                      Generate Image 
                      <img
                        className="info-section__header__icon"
                        src={magicIcon}
                        alt="Lock icon"
                        style={{ color: "white", marginLeft: 10 }}
                      />
                    </>
                  )}
                </button>
              </div>
              }

              {progress !== 100 && startImagination ? (
                <Box
                  className="box-big"
                  style={{
                    backgroundColor: "transparent",
                    marginTop: 30,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img className="loadLogo" src={LoadLogo} alt="Loading" />
                  {progress == 0 ? (
                    <Text
                      text="In Queue, Please Wait"
                      className="info-section-ai__header"
                    />
                  ) : (
                    <CountUpNumber
                      className="liquidity-item__apy"
                      startValue={progress}
                      end={progress}
                      decimals={0}
                      suffix={"%"}
                    />
                  )}
                </Box>
              ) : (
                <div style={{marginRight:50}}>
                { activeStep == 2 ? 
               numberArray( Math.floor(selectedImages.length/4)).map(
                  (selected, selectedIndex) => (
                    <Row
                    style={{
                      marginTop: 10,
                      marginRight: -20,
                      marginLeft: -30,
                    }}
                  >
                    {selectedImages.map(
                      (imageSrc, imageIndex) => (
                      
                      <ImageItem
                        fullWidth={true}
                          handleCheckboxChange={() =>
                            handleCheckboxChange(imageSrc)
                          }
                          isSelected={selectedImages.includes(
                            imageSrc
                          )}
                          isSelectable = {false}
                          imageSrc={selectedImages[(selectedIndex *  4) + imageIndex ]}
                        />
                      )
                    )}
                  </Row>
                  )
                )
                :
                  latestPrompts.length > 0 &&
                    latestPrompts.slice(0, 3).map((promptGroup, groupIndex) => (
                      <Row style={{ marginRight: 50, marginTop: 5 }}>
                        <div key={groupIndex}>
                          <div key={groupIndex}>
                            <Row>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: 16,
                                  fontFamily: "revert",
                                  marginTop: 0,
                                  marginLeft: 15,
                                }}
                              >
                                {promptGroup.prompt}
                              </div>
                            </Row>
                            <Row
                              style={{
                                marginTop: 10,
                                marginRight: -20,
                                marginLeft: -67,
                              }}
                            >
                              {promptGroup.images.map(
                                (imageSrc, imageIndex) => (
                                  <ImageItem
                                    handleCheckboxChange={() =>
                                      handleCheckboxChange(imageSrc)
                                    }
                                    isSelected={selectedImages.includes(
                                      imageSrc
                                    )}
                                    isSelectable = {true}
                                    imageSrc={imageSrc}
                                  />
                                )
                              )}
                            </Row>
                          </div>
                        </div>
                      </Row>
                    ))}
                </div>
              )}
            </Column>

            {windowSize[0] < 900 ? null : (
              <Column
                className="info-section-ai sticky-column"
                style={{ marginTop: "2.5 rem" }}
              >
                {windowSize[0] < 900 ? (
                  <Text
                    text="Details"
                    className="info-section-ai__detail"
                    style={{ fontSize: "30px" }}
                  />
                ) : null}
                <Box
                  className="info-section-ai__info-box-locked"
                  style={{
                    marginBottom: "1rem",
                    paddingTop: 30,
                    display: "table",
                    overflow: "hidden",
                  }}
                  backgroundColor="#2b3740"
                >
                  {selectedImages.length > 0 && activeStep != 2 ? (
                    <>
                    <Column>
                    <Row>
                    <div className="info-section-ai__infoIcon">
                      <img
                        className="info-section-ai__header__icon"
                        src={closeIcon}
                        style={{cursor: "pointer"}}
                        alt="Lock icon"
                        onClick={() => {
                          setIsLoading(false)
                          setMintButtonTitle("Finalize Mint")
                          setSelectedImages([])
                          setActiveStep(0)
                        }}
                      />
                    </div>

                    <Text
                      text="Metadata"
                      className="info-section-ai__header"
                    />
                     {/* <Text
                        text={`${selectedImages.length}/${latestPrompts.length * 4}`}
                        className="info-section-ai__currency"
                        style={{fontSize: 20, marginLeft: 20}}
                      /> */}
                  </Row>

                  <Row className="staking-modal__input__container" style={{marginTop: 10, marginBottom: 0}}> 
                  <Input
              type="text"
              style={{width: "100%"}}
              value={nftDisplayName}
              onChange={(event) => {
                if (event.target.value.length <= 32) {
                  setNftDisplayName(event.target.value);
                }
              }}
              placeholder="Token Name "
              className="staking-modal__input"
            />

                          
            {/* <Row className="staking-modal__input__buttons">
              <div
                className="staking-modal__max-button"
                onClick={maxButtonStaking}
              >
                <Text text="Use Max" style={{ fontSize: "12px" }} />
              </div>
              <div>
                <Text
                  text={earnTokenName}
                  style={{ color: "#a3a8ac" }}
                />
              </div>
            </Row> */}
          </Row>
          <Row>      <Text
                      style={{
                        color: "#A3A8AC",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 13,
                        "font-style": "normal",
                        "font-weight": 500,
                        marginTop: 2,
                        "line-height": "normal",
                      }}
                    >
                    Min. 1 characters, max. 32 characters ({nftDisplayName.length})
                    </Text></Row>

          <Row className="staking-modal__input__container" style={{marginTop: 10,  marginBottom: 0}}> 

            <Input
              type="text"
              style={{width: "100%"}}
              value={nftName}
              onChange={(event) => {
                if (event.target.value.length <= 63) {
                  setNftName(event.target.value);
                }
              }}
              placeholder="Displayname (Optional)"
              className="staking-modal__input"
            />
            {/* <Row className="staking-modal__input__buttons">
              <div
                className="staking-modal__max-button"
                onClick={maxButtonStaking}
              >
                <Text text="Use Max" style={{ fontSize: "12px" }} />
              </div>
              <div>
                <Text
                  text={earnTokenName}
                  style={{ color: "#a3a8ac" }}
                />
              </div>
            </Row> */}
          </Row>
          <Row>      <Text
                      style={{
                        color: "#A3A8AC",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 13,
                        "font-style": "normal",
                        "font-weight": 500,
                        marginTop: 2,
                        "line-height": "normal",
                      }}
                    >
                    Max 63 characters ({nftName.length})
                    </Text></Row>
          <Row className="staking-modal__input__container" style={{marginTop: 12, resize: "none",  marginBottom: 0}}> 
          <textarea name="Text1" rows="5"
           type="text"
           value={nftDes}
           style={{width: "100%", fontSize: 15}}
          //  onChange={(event) => setNftDes(event.target.value)}
           onChange={(event) => {
            if (event.target.value.length <= 63) {
              setNftDes(event.target.value);
            }
          }}
           placeholder="Description/Detaildata (Optional)"
            className={`input staking-modal__input`}
          
          ></textarea>
           
            {/* <Row className="staking-modal__input__buttons">
              <div
                className="staking-modal__max-button"
                onClick={maxButtonStaking}
              >
                <Text text="Use Max" style={{ fontSize: "12px" }} />
              </div>
              <div>
                <Text
                  text={earnTokenName}
                  style={{ color: "#a3a8ac" }}
                />
              </div>
            </Row> */}
          </Row>

          <Row>      <Text
                      style={{
                        color: "#A3A8AC",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 13,
                        "font-style": "normal",
                        "font-weight": 500,
                        marginTop: 2,
                        "line-height": "normal",
                      }}
                    >
                    Max 63 characters ({nftDes.length})
                    </Text></Row>
          <button
                className="connect-button-mynft"
                style={{   
                  marginTop: 12,
                  width: "100%"
                }}
                onClick={() => {
                  if(nftDes.length > 63 || nftName.length > 63 || nftDisplayName === "" || nftDisplayName.length < 1 
                  || nftDisplayName > 32){
                    createNotification("error", "Should fill the required data on the form or meet limitation", 4000);
                    return;
                  }
                    setActiveStep(2)
                }}
                //disabled={nftDes == "" || nftName == ""}
                // onMouseOver={() => {
                //   setConnectBtnIcon(disconnectIcon);
                // }}
                // onMouseOut={() => {
                //   setConnectBtnIcon(connectBtnIcon);
                // }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    color: "#fff",
                  }}
                >
               Continue
                </div>
              </button>

              </Column>
</>
                  ) :  activeStep == 2 ? <Column>
                 <Row>
                    <div className="info-section-ai__infoIcon">
                      <img
                        className="info-section-ai__header__icon"
                        src={closeIcon}
                        style={{cursor: "pointer"}}
                        alt="Lock icon"
                        onClick={() => {
                          setIsLoading(false)
                          setMintButtonTitle("Finalize Mint")
                          setSelectedImages([])
                          setActiveStep(0)
                        }}
                      />
                    </div>

                    <Text
                      text="Mint Service"
                      className="info-section-ai__header"
                    />
                    
                  </Row>
                  
                   <Row>
                                    <button
                                    onClick={() => {
                                      setSelectedProtocol(0);
                                      // createNotification("success", "Comming soon...", 4000);                                      
                                    }}
                                
                                    style={{ width: "100% !important", 
                                    // marginRight: 0,
                                    margin:12,    
                                    // marginTop:30, 
                                       color:" #fff",
                                    background: "rgba(246, 245, 246, 0) !important",
                                    backgroundColor: selectedProtocol == 0 ? "rgba(234, 70, 119, .6)" : "transparent",
                                    width: "100%",
                                    display: "block",
                                    border: "solid 1px rgba(255, 255, 255, 0.6)" }}
                                  >
                                    <div style={{textAlign: "center", padding: 15}}>
                                    <img src={saturn} alt="ssa" style={{width: 80,   marginLeft: 5}}/>
                  
                                    </div>
                                  </button>
                  
                                  <button
                                    onClick={() => {
                                      setSelectedProtocol(1);
                                       // createNotification("success", "Comming soon...", 4000);
                                      
                                    }}
                                
                                    style={{ width: "100% !important", marginRight: 0,
                                     margin:12,   
                                      color:" #fff",
                                    width: "100%",
                                    // marginRight: 30,
                                    display: "block",
                                    background: "rgba(246, 245, 246, 0) !important",
                                    backgroundColor: selectedProtocol == 1 ? "rgba(234, 70, 119, .6)" : "transparent",
                                    border: "solid 1px rgba(255, 255, 255, 0.6)" }}
                                  >
                                    <div style={{textAlign: "center", padding: 15}}>
                                    <img src={nmkr} alt="ssa" style={{width: 80, marginLeft: 5}}/>
                  
                                    </div>
                  
                                   
                                  </button>
       
                  </Row> 

                  {selectedProtocol === 1 ? 
                  <>
                              <Row
                    style={{                      
                      marginTop: "15px",
                      width: "90%",
                    }}
                  >
                    <img
                      src={infoIcon}
                      alt="Info"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: 10,
                      }}
                    />
                    <Text
                      style={{
                        color: "#A3A8AC",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 13,
                        "font-style": "normal",
                        "font-weight": 500,
                        "line-height": "normal",
                      }}
                    >
                    Cost: 3.5 ADA
                    <br/>
                    Deposit: 2 ADA (returned) 
                    </Text>
                  </Row>
                  </>
      
                  
                  : <>
                  
                  <Row
                    style={{                      
                      marginTop: "15px",
                      width: "90%",
                    }}
                  >
                    <img
                      src={infoIcon}
                      alt="Info"
                      className="liquidity-item__image"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: 10,
                      }}
                    />
                    <Text
                      style={{
                        color: "#A3A8AC",
                        "font-feature-settings": "'clig' off, 'liga' off",
                        "font-size": 13,
                        "font-style": "normal",
                        "font-weight": 500,
                        "line-height": "normal",
                      }}
                    >
                      Coming Soon
                    </Text>
                  </Row>
                  </>}

                  {selectedProtocol === 1 ?
                       <div className={payLink ? "connect-button-mynft" :  "button-container"} style={{width: "100%"}}>
                       {payLink ?
                             <a onClick={handlePayClick}
                             href={payLink} target="_blank" rel="noreferrer" className={"custom-button"} style={{color: "white", marginTop: 10}}>
                            Pay Now
                           </a>
                       
                             :
                             
                             <button
                               className={`custom-button ${isLoading ? 'loading' : ''}`}
                               onClick={handleClick}
                               disabled={isLoading}
                               style={{textAlign: mintButtonTitle == "" ? "center": "center"}}
                             >
                               {mintButtonTitle}
                             </button>
                       
                             }
                             
                            
                           </div> 
                           
                 : <></> }
              <div className={"button-container"} style={{width: "100%", marginTop: 10}}>
    
                             <button
                               className={`custom-button`}
                               onClick={handleBackClick}
                               disabled={isLoading}
                               style={{
                                textAlign: mintButtonTitle == "" ? "center": "center",
                                backgroundColor: '#F83472',
                                color: 'white'
                              }}
                             >
                              Back
                             </button>                                                   
                             
                            
                           </div> 
                  </Column> :(
                    <>
                      <Row>
                        <div className="info-section-ai__infoIcon">
                          <img
                            className="info-section-ai__header__icon"
                            src={filterIcon}
                            alt="Lock icon"
                          />
                        </div>

                        <Text
                          text="Filter"
                          className="info-section-ai__header"
                        />
                      </Row>
                      <Row className="info-section-ai__pair"></Row>
                      {/* <Row
                        className="info-section-ai__pair"
                        style={{ marginBottom: 10 }}
                      >
                        <div className="title">Number of Images</div>
                        <Text
                          text="Number of Images"
                          className="info-section-ai__currency"
                        />
                      </Row>

                      <div className="circle-button-group">
                        {renderButtons()}
                      </div> */}

                      <div className="toggle-row" onClick={toggleButtonClick}>
                        <Text
                          text="High Contrast"
                          className="info-section-ai__currency"
                        />
                        {/* <div className="title">High Contrast</div> */}
                        <div
                          className={`toggle-button ${
                            isToggleOn ? "active" : ""
                          }`}
                          style={toggleButtonStyle}
                        >
                          <div className="slider-toggle"></div>
                        </div>
                      </div>
                      <Row
                        className="info-section-ai__pair"
                        style={{ marginTop: 20 }}
                      >
                        {/* <div className="title">Number of Images</div> */}
                        <Text
                          text="Prompt magic strength"
                          className="info-section-ai__currency"
                        />
                      </Row>
                      <Row style={{ marginTop: 15 }}>
                        <div className="slider-container">
                          <input
                            type="range"
                            min="0"
                            max="100"
                            step="1"
                            value={sliderValue}
                            onChange={handleSliderChange}
                            className="slider"
                            style={{ left: `${sliderValue}%` }}
                          />
                          <div
                            className="gradient-line"
                            style={gradientLine}
                          ></div>
                          {/* <div
  className="slider-thumb"
  style={{ left: `${sliderValue}%` }}
></div> */}
                        </div>
                      </Row>

                      <Row
                        className="info-section-ai__pair"
                        style={{ marginTop: 40 }}
                      >
                        {/* <div className="title">Number of Images</div> */}
                        <Text
                          text="Image Dimension"
                          className="info-section-ai__currency"
                        />
                      </Row>
                      <div
                        className="ButtonGroup"
                        style={{
                          marginTop: 10,
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {buttonsData.map((button, index) => (
                          <Button
                            key={index}
                            label={button.label}
                            selected={selectedRatioButton === button.index}
                            onClick={() => handleRatioButtonClick(button.index)}
                          />
                        ))}
                      </div>
                      <Row
                        className="info-section-ai__pair"
                        style={{ marginTop: 20 }}
                      >
                        {/* <div className="title">Number of Images</div> */}
                        <Text
                          text="Image Input"
                          className="info-section-ai__currency"
                        />
                      </Row>
                      <div
                        className="drop-zone"
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                        ref={dropZoneRef}
                        style={{
                          width: "100%",
                          marginTop: 10,
                          border: "1px solid #ffffff69",
                          borderRadius: 20,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center", // Center both horizontally and vertically
                        }}
                      >
                        <input
                          type="file"
                          id="fileInput"
                          accept=".jpg, .jpeg, .png, .gif"
                          onChange={handleFileInputChange}
                          style={{ display: "none" }}
                        />
                        <label htmlFor="fileInput">
                          {file ? (
                            <div className="file-preview">
                              <img
                                src={URL.createObjectURL(file)}
                                alt="Uploaded"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          ) : (
                            <div className="file-upload">
                              <div
                                style={{
                                  //display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  className="info-section-ai__header__icon"
                                  src={dragIcon}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    marginBottom: 20,
                                    marginTop: 30,
                                    display: "inline"
                                  }}
                                  alt="Lock icon"
                                />
                                <div
                                  style={{ color: "white", marginBottom: 10 }}
                                >
                                  Drag & Drop or Click to Upload
                                </div>

                                <div
                                  style={{
                                    color: "white",
                                    fontSize: 15,
                                    fontFamily: "revert",
                                    marginBottom: 20,
                                  }}
                                >
                                  PNG or JPG up to 5MB
                                </div>
                              </div>
                            </div>
                          )}
                        </label>
                      </div>
                    </>
                  )}
                  {/* <Row className="info-section__pair">
                  <CountUpNumber
                    prefix={""}
                    className="info-section__number"
                    startValue={0}
                    end={200}
                    decimals={2}
                  />
                  <Text text="ADA" className="info-section__currency" />
                </Row> */}
                  {/* <div
                  style={{
                    height: "50%",
                    top: "-150px",
                    right: "-110px",
                    position: "relative",
                    borderRadius: 331.65,
                    opacity: 0.4,
                    background: "#F83472",
                    filter: "blur(76.9px)",
                  }}
                ></div>
                <div
                  style={{
                    height: "200px",
                    width: "200px",
                    position: "relative",
                    borderRadius: 331.65,
                    bottom: "10px",
                    left: "-100px",
                    background: "#4BA3CF",
                    filter: "blur(67.95704650878906px)",
                  }}
                ></div> */}
                </Box>
              </Column>
            )}
          </Row>
        </div>
      )}
    </div>
    </>

  );
};

const mapStateToProps = (state) => {
  return {
    adaWallet: state.user.adaWallet,
    signerAddress: state.user.signerAddress,
    network: state.network.network,
  };
};

export default connect(mapStateToProps, {
  setWalletModal,
  startAction,
  stopAction,
  createNotification,
  disconnectUserAccount,
})(AIImageGenerator);
