import React from "react";
import { Row, Text } from "components";

const NftTopics = () => {
  return (
    <Row className="nft-topic">
      <div className="nft-topic__big-space"
                    
                    >
        <Text text="NFT" className="nft-topic__col-title" 
                  style={{ fontSize: "12px"}}
                  />
      </div>
      <div className="nft-topic__small-space"
                    style={{ width: '14%'}}
                    >
        <Text text="Yield" className="nft-topic__col-title" 
                  style={{ fontSize: "12px"}}

        />
      </div>
      <div className="nft-topic__small-space"
                    style={{ width: '17%'}}
                    >
        <Text text="Staked" className="nft-topic__col-title" 
                  style={{ fontSize: "12px"}}
                  />
      </div>
      <div className="nft-topic__small-space"
                    style={{ width: '17%'}}
                    >
        <Text text="Earn" className="nft-topic__col-title" 
                  style={{ fontSize: "12px"}}
                  />
      </div>
      <div className="nft-topic__small-space"
              style={{ width: '15%'}}
      >
        <Text text="" className="nft-topic__col-title" 
        style={{fontSize: '12px'}}
        />
      </div>
    </Row>
  );
};

export default NftTopics;
