import React from "react";
import { Switch } from "react-router-dom";
import { NftLaunchpad, LiquidityFarm, NftFarm, MyNfts, NftDetail, Orders, AIImageGenerator, PoolCreation, SwapPage } from "../views";
import { Route } from "react-router";
import Earn from "views/Earn/Earn";

const Routes = () => {
  return (
    // <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      {/* liquidity-farm */}
      <Route path="/swap" exact component={SwapPage}></Route>
      <Route path="/nft-launchpad" component={NftLaunchpad}></Route>
      <Route path="/ai-generator" component={AIImageGenerator}></Route>
      <Route path="/my-nft" component={MyNfts}></Route>
      <Route path="/earn" component={Earn}></Route>
      {/* <Route path="/orders" component={Orders}></Route> */}
      <Route path="/create-pool" component={PoolCreation}></Route>
      <Route path={`/nft-detail/:fingerPrint`}>
          <NftDetail />
        </Route>
      {/* <Route path="/nft-farm" exact component={NftFarm}></Route> */}
      {/* <Route path="/" exact>
        <LiquidityFarm />
      </Route> */}
      <Route path="/" exact component={SwapPage}></Route>
      
    </Switch>
    // </Suspense>
  );
};

export default Routes;
