/* eslint-disable quotes */
import React from "react";
import { Column, Box, Text, Row, CountUpNumber } from "../index";
import { connect } from "react-redux";
import {
  createNotification,
  startAction,
  stopAction,
} from "../../store/actions/uiActions";
import bigInt from "big-integer";
import { useState, useEffect } from "react";
import lockIcon from "../../assets/infoSection/lock-info-section.png";
import userIcon from "../../assets/infoSection/user-info-section.png";
import discord from "assets/discord.svg";
import twitter from "assets/x.svg";

import { useWindowSize } from "utils/windowSizeHook";
import TreasuryBalance from "./TreasuryBalance";
import TotalReward from "./TotalReward";

const InfoSection = ({
  ethBalance,
  umiBalance,
  signerAddress,
  totalLockedValue,
  adaAmount,
  ethPrice,
  tangentAmount,
  userTokenAmount,
  elvAmount,
  elvADAAmount,
  cPiggyAmount,
  bukeleAmount,
  tangentADAAmount,
  totalStaked,
  stakedTokens,
  tangPrice,
  totalElvRewards,
  cPiggyPrice,
  totalTangRewards,
  totalcPiggyRewards,
  totalBukeleRewards,
  poolsData,
}) => {
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    // Function to calculate and set the container height based on the parent screen size
    const updateContainerHeight = () => {
      const parentHeight = window.innerHeight; // Get the viewport height
      const heightOffset = 80; // Adjust this value as needed for the 'top' offset

      setContainerHeight(parentHeight - heightOffset);
    };

    // Call the function initially and add an event listener for window resize
    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);
  const windowSize = useWindowSize();
  let totalLockVal = 0;
  if (poolsData) {
    totalLockVal = poolsData.reduce((a, b) => {
      return a + b.tvl;
    }, 0);
  }
  console.log("here", totalLockVal);

  const priceInAda = totalLockVal / ethPrice.usd;
  return (
    // <div className="scroll-container" style={{ height: containerHeight }}>
    <Column className="info-section sticky-column">
      {windowSize[0] < 900 ? (
        <Text
          text="Details"
          className="info-section__detail"
          style={{ fontSize: "30px" }}
        />
      ) : null}
      <Box
        className="info-section__info-box-locked"
        style={{ marginBottom: "1rem", paddingTop: 30, overflow: "hidden" }}
        backgroundColor="#2b3740"
      >
        <Row>
          <div className="info-section__infoIcon">
            <img
              className="info-section__header__icon"
              src={lockIcon}
              alt="Lock icon"
            />
          </div>

          <Text text="Total Value Locked" className="info-section__header" />
        </Row>
        <Row className="info-section__pair">
          <CountUpNumber
            prefix={"$"}
            className="info-section__number"
            startValue={0}
            end={totalLockVal}
            decimals={2}
          />
          <Text text="USD" className="info-section__currency" />
        </Row>

        <Row className="info-section__pair">
          <CountUpNumber
            prefix={""}
            className="info-section__number"
            startValue={0}
            end={priceInAda}
            decimals={2}
          />
          <Text text="ADA" className="info-section__currency" />
        </Row>
        <div
          style={{
            height: "50%",
            top: "-150px",
            right: "-110px",
            position: "relative",
            borderRadius: 331.65,
            opacity: 0.4,
            background: "#F83472",
            filter: "blur(76.9px)",
          }}
        ></div>
        <div
          style={{
            height: "200px",
            width: "200px",
            position: "relative",
            borderRadius: 331.65,
            bottom: "10px",
            left: "-100px",
            background: "#4BA3CF",
            filter: "blur(67.95704650878906px)",
          }}
        ></div>
      </Box>
      {/* {signerAddress &&  <Box
        style={{ marginBottom: "1rem", overflow: "hidden" }}
        className="info-section__info-box-rewards-no-height"
        backgroundColor="#2b3740"
      >

        <TotalReward />
      </Box>} */}
     
      <Box
        style={{ marginBottom: "1rem", overflow: "hidden" }}
        className="info-section__info-box-rewards-no-height"
        backgroundColor="#2b3740"
      >
        {/* <Row className="info-section__pair"> */}

        <TreasuryBalance />
      </Box>
      {/* <Box
        style={{ overflow: "hidden" }}
        className="info-section__info-box-user"
        backgroundColor="#2b3740"
      >
        <Row>
          <div className="info-section__infoIcon">
            <img
              className="info-section__header__icon"
              src={userIcon}
              alt="User icon"
            />
          </div>
          <Text text="User Info" className="info-section__header" />
        </Row>
        <Row className="info-section__pair">
          <CountUpNumber
            className="info-section__number"
            startValue={0}
            end={userTokenAmount && userTokenAmount["ADA"]}
            decimals={2}
          />
          <Text text="ADA" className="info-section__currency" />
        </Row>
        <Row
          className="info-section__pair"
          style={{
            borderBottom: "1px solid #b3acbb",
            paddingBottom: "2rem",
            marginBottom: "1rem",
          }}
        >
          <CountUpNumber
            className="info-section__number"
            startValue={0}
            end={
              userTokenAmount &&
              bigInt(userTokenAmount["TANG"]) / bigInt(1000000)
            }
            decimals={6}
          />
          <Text text="TANG" className="info-section__currency" />
        </Row>

        <Text text="ADA Address" className="info-section__account" />
        <a
          className="info-section__account"
          target="_blank"
          rel="noreferrer"
          href={
            signerAddress
              ? `https://cardanoscan.io/address/${signerAddress}`
              : undefined
          }
        >
          {!signerAddress
            ? "Not connected"
            : `#${signerAddress?.slice(0, 6)}...${signerAddress?.slice(
                37,
                signerAddress?.length
              )}`}
        </a>

        <div
          style={{
            height: "40%",
            top: "-230px",
            right: "-130px",
            position: "relative",
            borderRadius: 331.65,
            opacity: 0.4,
            background: "#F83472",
            filter: "blur(76.9px)",
          }}
        ></div>
        <div
          style={{
            height: "200px",
            width: "200px",
            position: "relative",
            borderRadius: 331.65,
            bottom: "10px",
            left: "-100px",
            background: "#4BA3CF",
            filter: "blur(67.95704650878906px)",
          }}
        ></div>
      </Box> */}
      <Row center>
        <a href="https://discord.gg/hRM43Mv3Zt" target="_blank">
        <img
          style={{
            height: "28px",
            width: "28px",
            marginRight: 5,

          }}
          src={discord}
          alt=""
        />
        </a>
        <a href="https://discord.gg/hRM43Mv3Zt" target="_blank">

        <Text style={{color: '#a3a8acb3',marginRight: 20,marginLeft: 5,}}>Discord</Text>


        </a>

        <a href="https://twitter.com/TangentADA" target="_blank">

                <img
          style={{
            height: "20px",
            width: "20px",
            marginRight: 5

          }}
          src={twitter}
          alt=""
        />
        </a>
        <a href="https://twitter.com/TangentADA" target="_blank">
                <Text style={{color: '#a3a8acb3'}}>Twitter</Text>
                </a>

      </Row>
      
    </Column>
    // </div>
  );
};

const mapStateToProps = (state) => {
  return {
    signerAddress: state.user.signerAddress,
    ethBalance: state.user.ethBalance,
    adaAmount: state.user.adaAmount,
    tangentAmount: state.user.tangentAmount,
    tangentADAAmount: state.user.tangentADAAmount,
    totalStaked: state.user.totalStaked,
    ethPrice: state.user.ethPrice,
    tangPrice: state.user.tangPrice,
    totalTangRewards: state.user.totalTangRewards,
    totalElvRewards: state.user.totalElvRewards,
    cPiggyPrice: state.user.cPiggyPrice,
    totalcPiggyRewards: state.user.totalcPiggyRewards,
    totalBukeleRewards: state.user.totalBukeleRewards,
    cPiggyAmount: state.user.cPiggyAmount,
    stakedTokens: state.pool.stakedTokens,
    elvAmount: state.user.elvAmount,
    elvADAAmount: state.user.elvADAAmount,
    bukeleAmount: state.user.bukeleAmount,
    network: state.network.network,
    poolsData: state.pool.poolsData,
    userTokenAmount: state.user.userTokenAmount,
  };
};

export default connect(mapStateToProps, {
  createNotification,
  startAction,
  stopAction,
})(InfoSection);
