import { awaitTx, depositTx, initializeLucid, getUserWallet } from "./lucid";
import { store } from "index";
import { API_URL, API_URL_MAIN } from "../constants";
import { createNotification } from "store/actions/uiActions";

export const deposit = async (
  depositObject,
  stakingWallet,
  isPrepord,
  signedTx = undefined
) => {
  try {
    if (!signedTx) {
      const walletName = store.getState().user.adaWallet;

      const lucid = await initializeLucid(
        await window.cardano[walletName].enable(),
        isPrepord
      );

      const utxos = await lucid.wallet.getUtxos();

      let tx;
      tx = await depositTx(depositObject, stakingWallet, lucid, utxos);

      signedTx = await tx.sign().complete();
    }

    const txHash = await signedTx.submit();

    await awaitTx(txHash, isPrepord);

    store.dispatch(
      createNotification(
        "success",
        `Your deposit was successful! It will be shortly updated in the UI`,
        6000
      )
    );
    return txHash;
  } catch (error) {
    console.log("deposit-error", error, error?.message);
    if (
      error?.info === "user declixned tx" ||
      error?.info === "user declined to sign tx"
    ) {
      store.dispatch(
        createNotification("error", `You need to sign the message`, 6000)
      );
      return;
    }
    store.dispatch(createNotification("error", JSON.stringify(error), 6000));
  }
};

export const depositNFT = async (
  depositObject,
  stakingWallet,
  isLastOne,
  isPrepord,
  signedTx = undefined
) => {
  try {
    if (!signedTx) {
      const walletName = store.getState().user.adaWallet;

      const lucid = await initializeLucid(
        await window.cardano[walletName].enable(),
        isPrepord
      );

      const utxos = await lucid.wallet.getUtxos();

      let tx;
      tx = await depositTx(depositObject, stakingWallet, lucid, utxos);

      signedTx = await tx.sign().complete();
    }

    const txHash = await signedTx.submit();

    if (isLastOne) {
      await awaitTx(txHash);
    }

    store.dispatch(
      createNotification(
        "success",
        `Your deposit was successful! It will be shortly updated in the UI`,
        6000
      )
    );
    return txHash;
  } catch (error) {
    // if(error === "Insufficient input in transaction"){
    //   depositNFT(depositObject,
    //     stakingWallet,
    //     isLastOne)
    //   return;
    // }
    console.log("deposit-error", error, error?.message);
    if (
      error?.info === "user declixned tx" ||
      error?.info === "user declined to sign tx"
    ) {
      store.dispatch(
        createNotification("error", `You need to sign the message`, 6000)
      );
      return;
    }
    store.dispatch(createNotification("error", JSON.stringify(error), 6000));
  }
};
