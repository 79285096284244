import React, { useState } from "react";
import { connect } from "react-redux";
import Row from "../Row/Row";
import Text from "../Text/Text";
import cardanoIcon from "../../assets/network/cardano.svg";
import testnetIcon from "../../assets/network/preprod-network.png";
import { setNetwork } from "../../store/actions/networkActions";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { NETWORK } from "utils/constants";

const NetworkSelector = ({ setNetwork, network }) => {

  const [age, setAge] = React.useState(network);

  const handleChange = (event) => {
    setAge(event.target.value);
    setNetwork(event.target.value);
  };

  const onSelect = (e) => {
    if (e.value === "cardano") {
    } else if (e.value === "ethereum") {
    }
    // setNetwork(e.value);
  };

  return (
    <Box sx={{ minWidth: 120 }} className="network-selector">
      <FormControl fullWidth size="small">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          onChange={handleChange}
          style={{ fontSize: "15px" }}
        >    
          {/* <MenuItem value={"pre"}>
            <Row>
              <img
                style={{
                  borderRadius: "50%",
                  marginRight: "1rem",
                  width: "22px",
                }}
                src={testnetIcon}
                alt="IOHK"
              />
              Pre-Prod
            </Row>
          </MenuItem> */}
          <MenuItem value={NETWORK}>
            <Row className="netselector-text">
              <Row>
                <img
                  style={{ borderRadius: "50%", marginRight: "1rem", width: "22px" }}
                  src={NETWORK == "cardano" ? cardanoIcon : testnetIcon}
                  alt="Cardano"
                />
                {NETWORK == "cardano" ? 'Cardano' : 'PreProd'}
              </Row>
            </Row>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    network: state.network.network,
  };
};

export default connect(mapStateToProps, { setNetwork })(NetworkSelector);
